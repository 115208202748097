import utils from '../../utils';
import { echartSetOption } from './echarts-utils';

/* -------------------------------------------------------------------------- */
/*                             Echarts Line Payment                           */
/* -------------------------------------------------------------------------- */
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

const linePaymentChartInit = (payment_histories) => {
  
  console.log('-- linePaymentChartInit payment_histories', payment_histories)
  // return
  // const $echartsLinePaymentChart = document.querySelector(
  //   '.echart-line-payment'
  // );
  let payment_histories_labels = []
  let payment_histories_dataset = {}
  let payment_histories_status = []
  let next_due_date_ph = {};

  const data = payment_histories

  
  // console.log('-- payment_history',data)
  // build pyemtn history table body
  // let ph_body = document.querySelector("#payment_history_content")
  // localStorage.setItem('payment_histories', JSON.stringify(data))
  
// end load payment history and compute total user 
  let total_number_of_users = 0
  payment_histories_dataset = data.reduce((prev, curr)=>{
    payment_histories_labels.push(window.dayjs(curr.last_payment).format('DD/MM/YYYY'))
    let curr_xaxis = parseFloat(curr.pricing.price) * parseInt(curr.pricing.users)
    total_number_of_users = total_number_of_users + parseInt(curr.pricing.users)

    document.querySelector('#el_number_of_user').innerHTML = `${total_number_of_users}`;

    prev.all.push(parseFloat(curr_xaxis))
    if (!prev[`${curr.status}`]) {
      prev[`${curr.status}`] = []
    }
    prev[`${curr.status}`].push(curr_xaxis)
    // compute next payment date
    let d1 = new Date(next_due_date_ph?.next_due_date || curr.next_due_date);
    let d2 = new Date(curr.next_due_date);
    
    next_due_date_ph = d1.getTime() < d2.getTime() ? curr : next_due_date_ph;
    return prev
  },{all:[]})

  // console.log('--- payment_histories_dataset', payment_histories_dataset)
  payment_histories_status = Object.keys(payment_histories_dataset)
  
  let dueDateEl = document.querySelector('#next_due_date_ph');
  dueDateEl.innerHTML = `Today ${next_due_date_ph.pricing?.price}`;
  let endValue = next_due_date_ph.pricing?.price;
  let options = {
    decimalPlaces: 2,
    prefix: '$',
  }
  console.log('endValue', endValue)
  let countUp = new window.countUp.CountUp(dueDateEl, endValue, _objectSpread({
    duration: 5
  }, options));

  if (!countUp.error) {
    countUp.start();
  } else {
    console.error(countUp.error);
  }
  // next due date = date
  // add total number of user
  let totalNumberOfUsersEl = document.querySelector("#count-up-all-users");
  
  let totalNumberOfUsersCountUp = new window.countUp.CountUp(totalNumberOfUsersEl, total_number_of_users, _objectSpread({
    duration: 5
  }));

  console.log('total_number_of_users', total_number_of_users)
  if (!totalNumberOfUsersCountUp.error) {
    totalNumberOfUsersCountUp.start();
  } else {
    console.error(totalNumberOfUsersCountUp.error);
  }

  let nextDueDatePhdateEl = document.querySelector('#next_due_date_ph_date');
  if (!nextDueDatePhdateEl){
    return;
  }
  nextDueDatePhdateEl.innerHTML = `Date <span className="opacity-50">${window.dayjs(next_due_date_ph.next_due_date).format('MMM, YYYY')}</span>`

  // let nextDueDatePhdateEl2 = document.querySelector('#next_due_date_ph_date_2');
  // if (!nextDueDatePhdateEl2){
  //   return;
  // }

  // nextDueDatePhdateEl2.innerHTML = `${window.dayjs(next_due_date_ph.next_due_date).format('dddd, MMMM YYYY')}`

  // let next_due_date_ph_date_diff = document.querySelector('#next_due_date_ph_date_diff');
  let today_date = new Date()
  let diff_in_time = next_due_date_ph.next_due_date //parseInt(new Date(next_due_date_ph.next_due_date).getTime())  - parseInt(today_date.getTime())
  console.log('--- diff_in_time', diff_in_time)

  let diff_in_day = diff_in_time / (1000 * 3600 * 24);
  // next_due_date_ph_date_diff.innerHTML = `A payout for <strong>$921.42 </strong>was deposited ${diff_in_day} days ago`;
  // console.log('dueDateEl', dueDateEl)

  const dataset = payment_histories_dataset
  const labels = payment_histories_labels

  console.log('in then dataset, labels', dataset, labels)
    var $echartsLinePaymentChart = document.querySelector('.echart-line-payment');
    // console.log('-- $echartsLinePaymentChart', $echartsLinePaymentChart)
    // var dataset = {
    //   all: [4, 1, 6, 2, 7, 12, 4, 6, 5, 4, 5, 10],
    //   successful: [3, 1, 4, 1, 5, 9, 2, 6, 5, 3, 5, 8],
    //   failed: [1, 0, 2, 1, 2, 1, 1, 0, 0, 1, 0, 2]
    // };
    // dataset = payment_histories_dataset;
  
    // var labels = ['9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM', '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM', '7:00 PM', '8:00 PM'];
    // labels = payment_histories_labels;
    if ($echartsLinePaymentChart) {
      var userOptions = utils.getData($echartsLinePaymentChart, 'options');
      var chart = window.echarts.init($echartsLinePaymentChart);
  
      var getDefaultOptions = function getDefaultOptions() {
        return {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'none'
            },
            padding: [7, 10],
            backgroundColor: utils.getGrays()['100'],
            borderColor: utils.getGrays()['300'],
            borderWidth: 1,
            transitionDuration: 0,
            formatter: function formatter(params) {
              return "".concat(params[0].axisValue, " - ").concat(params[0].value, " USD");
            },
            textStyle: {
              fontWeight: 500,
              fontSize: 12,
              color: utils.getColors().dark
            }
          },
          xAxis: {
            type: 'category',
            data: labels,
            splitLine: {
              show: true,
              lineStyle: {
                color: utils.rgbaColor('#fff', 0.1)
              },
              interval: 0
            },
            axisLine: {
              lineStyle: {
                color: utils.rgbaColor('#fff', 0.1)
              }
            },
            axisTick: {
              show: true,
              length: 10,
              lineStyle: {
                color: utils.rgbaColor('#fff', 0.1)
              }
            },
            axisLabel: {
              color: utils.getGrays()['400'],
              fontWeight: 600,
              formatter: function formatter(value) {
                return value.substring(0, value.length - 3);
              },
              fontSize: 12,
              interval: window.innerWidth < 768 ? 'auto' : 0,
              margin: 15
            },
            boundaryGap: false
          },
          yAxis: {
            type: 'value',
            axisPointer: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            }
          },
          series: [{
            type: 'line',
            smooth: true,
            data: dataset.all.map(function (d) {
              return (d).toFixed(2);
            }),
            symbol: 'emptyCircle',
            itemStyle: {
              color: localStorage.getItem('theme') === 'light' ? utils.getGrays().white : utils.getColors().primary
            },
            lineStyle: {
              color: localStorage.getItem('theme') === 'light' ? utils.rgbaColor(utils.getGrays().white, 0.8) : utils.getColors().primary
            },
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                  offset: 0,
                  color: localStorage.getItem('theme') === 'light' ? 'rgba(255, 255, 255, 0.5)' : utils.rgbaColor(utils.getColors().primary, 0.5)
                }, {
                  offset: 1,
                  color: localStorage.getItem('theme') === 'light' ? 'rgba(255, 255, 255, 0)' : utils.rgbaColor(utils.getColors().primary, 0)
                }]
              }
            },
            emphasis: {
              lineStyle: {
                width: 2
              }
            }
          }],
          grid: {
            right: 15,
            left: 15,
            bottom: '15%',
            top: 0
          }
        };
      };
  
      echartSetOption(chart, userOptions, getDefaultOptions);
      utils.resize(function () {
        if (window.innerWidth < 768) {
          chart.setOption({
            xAxis: {
              axisLabel: {
                interval: 'auto'
              }
            }
          });
        }
      });
      var selectMenu = document.querySelector('#dashboard-chart-select');
      // greg added
      selectMenu.innerHTML = [];
      //Create and append the options
      for (var i = 0; i < payment_histories_status.length; i++) {
          var option = document.createElement("option");
          option.value = payment_histories_status[i];
          option.text = payment_histories_status[i];
          selectMenu.appendChild(option);
      }
  
      if (selectMenu) {
        selectMenu.addEventListener('change', function (e) {
          var value = e.currentTarget.value;
          chart.setOption({
            series: [{
              data: dataset[value].map(function (d) {
                return (d).toFixed(2);
              })
            }]
          });
        });
      }
    } 
  
  else {
    console.log('echart line hasn\'t loaded', $echartsLinePaymentChart)
  }


};

export default linePaymentChartInit;
