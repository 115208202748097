import React from 'react'
import { CardCvcElement,CardExpiryElement,CardNumberElement, ElementsConsumer, PaymentElement} from '@stripe/react-stripe-js';
import { BACKEND_DOMAIN } from '../../env';

class CheckoutForm extends React.Component {
    state = {
        body: {},
        message: false
    }
    componentDidMount(){
        console.log('this.props', this.props)
        // const user_profile = localStorage.getItem('profile')
        const { computeOrderSummary, company, payable_total, numberOfusers, paymentCycle} = this.props
        this.setState(prevState=> {
            let _company_id = localStorage.getItem('company_id')
            return {
                ...prevState,
                body: {
                    company_id: _company_id,
                    paymentCycle: paymentCycle,
                    numberOfusers: numberOfusers,
                    payable_total, 
                    computeOrderSummary               
                }
            }
            
        })
        this.setState({email: company.email })
        
    }
  handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    this.props.setDisableSubmitButton(true)
    event.preventDefault();
    // console.log('this.state.body', this.state.body)
    // return;
    const {stripe, elements} = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    let result;
    const { user, _id, confirmData } = this.props.company
    if (Object.keys(confirmData || {}).length <= 3) {
      const result1 = await stripe.createPaymentMethod({
        card: elements.getElement("cardNumber"),
        type: "card",
        billing_details: {
          email: user.email,
          name: `${user?.last_name}, ${user?.first_name}`,
        },
      });
      let _user_token = _id;
      
      result = await fetch(`${BACKEND_DOMAIN}/pricing/recurring_sub_in_admin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...this.state.body,
          paymentCycle: this.props.paymentCycle,
          user_token: _user_token,
          name: `${user?.last_name}, ${user?.first_name}`,
          email: user.email,
          paymentMethod: result1.paymentMethod?.id,
        }),
      });
      const res = await result.json();
      console.log('res', res)
  
      console.log('this.props.company', this.props.company)
    }
    else {
      result = await this.props.addSubscribedModules(this.state.body);
    }
    
    if (!result) {
      this.props.setDisableSubmitButton(false)
      this.setState((prevState)=>{
        return {...prevState,
        message: 'We incountered an internal error, please contact us for a quick fix'
      }
    })
    return;
    }
    
    console.log('-- result', result)
    if (result && result.error) {
      this.props.setDisableSubmitButton(false)
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
      this.setState((prevState)=>{
          return {...prevState,
          message: result.error
        }
      })
      setTimeout(() => {
        // Most recent value
        this.setState((prevState)=>{
            return {...prevState,
            message: false
          }
        })   
      }, 8000);
      return
    } else if(result.status === 409 || result.status === 403) {
      this.props.setDisableSubmitButton(false)
        this.setState((prevState)=>{
          return {...prevState,
          message: result.statusText
        }
      })
    }else if (result.status === 201) {
      this.props.setDisableSubmitButton(false)
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
              localStorage.removeItem('checkedState')
              this.setState((prevState)=>{
                return {...prevState,
                message: {code:200, message: "Company Package successfully Updated !!"}
              }
            })   
              setTimeout(() => {
                // Most recent value
                localStorage.removeItem('checkedState')
                localStorage.removeItem('checkout_data')
                  this.setState((prevState)=>{
                    return {...prevState,
                    message: false
                  }
                })   
                this.props.history.push(`/company/${localStorage.getItem('company_id')}`)

              }, 3000);
    }
  };

  render() {
      // console.log('this.props', this.props)
    return (
        <>
        
		{this.state.message && this.state.message.code ? 
			this.state.message.code  == "200" ? (
			<div className="alert alert-success mb-5" role="alert">
				{this.state.message.message}
			</div>
			) : (
				<div className="alert alert-danger mb-5" role="alert">
				{this.state.message.message}
				</div>
			)          
		:null}
        <form className="form" id='strip_form_overxls' onSubmit={this.handleSubmit}>
        
        {Object.keys(this.props.company.confirmData || {}).length > 3 ? (
            
           <PaymentElement /> 
        ):(
          <div style={{marginBottom: '1rem'}} className="row">
              <div className='col-16 pr-1'>
                <div className="form-group">
                    <label htmlFor="email" style={{ fontSize: '1.1em',fontWeight:'400', color: 'rgba(48, 49, 61,0.8)', marginBottom: '0px !important'}} >Email</label>
                    {this.props.company.email &&<input  style={{height: '33px', borderRadius: '5px', boxShadow: '0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%)'}}
                      value={this.state.email}
                      onChange={(e) => this.setState({email: e.target.value})}
                      type="email" className="form-control" id="email" placeholder="email"/>}
                </div>                
              </div>
              {/* <div className='col-6' style={{alignSelf: 'flex-end'}}>
                <LinkAuthenticationElement disabled={true}/>                
              </div> */}
              <div className='col-12 mt-3'>
                <div className="form-group">
                  <CardNumberElement className='form-control' id="card-element" options={cardStyle} onChange={this.handleChange} />
                </div>
              </div>
              <div className='col-6 mt-3 form-group'>
              <CardExpiryElement className='form-control' id="card-element" options={cardStyle} onChange={this.handleChange} />
              </div>
              <div className='col-6 mt-3 form-group'>
              <CardCvcElement id="card-element" className='form-control' options={cardStyle} onChange={this.handleChange} />
              </div>
          </div>

        )}

          <button style={{display: 'none'}} className="my-4 bizwheel-btn theme-2 btn-lg btn-block" >Submit</button>
        </form>

        </>
    );
  }
}

export default function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({stripe, elements}) => (
        <CheckoutForm {...props} stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  )
}



const cardStyle = {
  style: {

    base: {
      borderRadius: '5px', boxShadow: '0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%)',
      color: "#32325d",
      // fontFamily: 'Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#ccc"
      },
      fontSize: '1.1em',fontWeight:'400', color: 'rgba(48, 49, 61,0.8)',
      height: '45px', 
      paddingTop: '15px'
    },
    invalid: {
      fontFamily: 'Arial, sans-serif',
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};