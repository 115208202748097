
import * as actionType from '../constants/marketPlaceActionType';

localStorage.setItem('paymentCycle', 'monthly')
const marketPlaceReducer = (state = { checkedState:{},paymentCycle:'monthly',numberOfusers:1 }, action) => {
  switch (action.type) {
    case actionType.SET_CHECKED_STATE:
      return {...state, checkedState: action.payload}
    case actionType.SET_PAYMENT_CYCLE:
    return {...state, paymentCycle: action.payload}
    case actionType.SET_NUMBER_OF_USERS:
        return {...state, numberOfusers: action.payload}
    default:
      return state;
  }
};

export default marketPlaceReducer;