import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { API, env_config } from '../../env';
import { getMappedModuleLineList } from '../../redux/actions/ErpSaaSBackendActions';

const ModuleLineForm = (props) => {
    const [message, setMessage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('profile')))
      
    useEffect(() => {
      console.log('In ModuleForm props', props)  
      setUserDetails(JSON.parse(localStorage.getItem('profile')));   
           
      const fetchData = async ()=> {
        await props.getMappedModuleLineList(setLoading)
      }
      fetchData()

      // props.
      return () => {
        localStorage.removeItem('module_item')
        props.setIsUpdate(false)
      }
      return null;
    }, [])    
    
    const formik = useFormik({
        initialValues: {
          name: props.updateModuleLine? props.updateModuleLine?.name : '' ,
          description: props.updateModuleLine ? props.updateModuleLine?.description : '' ,
          monthly_price: props.updateModuleLine ? props.updateModuleLine?.price?.monthly : 0 ,
          quarterly_price: props.updateModuleLine ? props.updateModuleLine?.price?.quarterly : 0 ,
          yearly_price: props.updateModuleLine ? props.updateModuleLine?.price?.yearly : 0 ,
          mapped_module_id: props.updateModuleLine ? props.updateModuleLine?.updateModuleLine?.id : null ,
        },
        validationSchema: Yup.object({
                name: Yup.string()
                .max(30, "Must be 30 characters or less")
                .required("Required"),
            description: Yup.string()
                .max(125, "Must be 125 characters or less")
                ,//.required("Required")
             total_price: Yup.number(),
        }),
        onSubmit: async (values, { resetForm }) => {
        let mapped_moduleline = props.mappedModuleLines.find(x=>x.id === values.mapped_module_id)
        // console.log('mapped_module', mapped_module)
        // return
        let _params = {
            ...props.updateModuleLine,
            name: values.name,
            mapped_moduleline: mapped_moduleline,
            description: values.description,
            price: {
                monthly: values.monthly_price,
                yearly: values.yearly_price,
                quarterly: values.quarterly_price
            }
        }
        // return
          // console.log('_params', _params)
        
        const config = env_config;        
          try {

            {!props.isUpdate ? (async ()=> {                    
              _params.createdBy = userDetails?.result;
              _params.parent = props.module?._id
              console.log('_params', _params)
              await API
              .post("/modulelines", {
                  ..._params
            }, config)
              .then((res) => {
                const { data } = res
                // console.log('in ModulesForm responser, res', res)
                props.createModuleLineSuccess(data)
                setMessage({code: res.status, message: "Module successfully created"});                
                // props.setCompanies((prev)=>[...prev, data])
                resetForm({ values: "" }) 
                setTimeout(() => {
                  props.handleClose()
                  }, 2000);

              }).catch((err)=>{
                // console.error("-- err", err.response);
                setMessage({code: err.response?.status || 500, message: err.response ? err.response.data : err})                

              }).finally(()=> {setTimeout(() => {
                // Most recent value
                setMessage(false);                   
              }, 5000);
              resetForm({ values: "" });});})() : 
              // Update logic
              (async ()=> {
                // console.log('-- update values', values)          
                _params.updatedBy = userDetails?.result;
                _params.updated_at = new Date();
                let tmp_moduleline = JSON.parse(localStorage.getItem('moduleline_item'))
                await API
                  .put(`/modulelines/${tmp_moduleline._id}`, {
                    ..._params
                  }, config)
                    .then(res=>{
                      const { data } = res
                      console.log('in ModulesForm responser, res', res)
                      props.updateModuleLineSuccess(data)
                      setMessage({code: res.status, message: "Module successfully updated"});                
                      // props.setCompanies((prev)=>[...prev, data])
                      resetForm({ values: "" }) 
                      setTimeout(() => {
                          props.handleClose()
                          localStorage.removeItem('moduleline_item')
                          props.setIsUpdate(false)
                        }, 2000);
                    })
              })()
            }

          } catch (error) {
            // console.error("-- error", error);
            setMessage({code: 404, message: error})
          } finally {
          }
        },
      });

      const someFunctionWithLogic = (e, setFieldValue) => {
        // Set the value of the number field here...
        console.log('someFunctionWithLogic', e.target.value,)
        let selected_moduleline = props.mappedModuleLines.find(x=>x.id === e.target.value)
        setFieldValue('name', selected_moduleline.name)
        setFieldValue('description', selected_moduleline.description)
        setFieldValue('monthly_price', selected_moduleline.price.monthly)
        setFieldValue('quarterly_price', selected_moduleline.price.quaterly)
        setFieldValue('yearly_price', selected_moduleline.price.yearly)
    }
    
  return (     
    <form 
        id="module-form" 
        onSubmit={formik.handleSubmit}
        method="post" action=""
        className="row g-3" style={{marginRight: '-2rem'}}>
        <div className='row'>
            {message && message.code ? 
                message.code.toString().substr(0,2)  === "20" ? (   
                <div className="alert alert-success" role="alert">
                    {message.message}
                </div>
                ) : (
                    <div className="alert alert-danger" role="alert">
                    {JSON.stringify(message?.message?.message)}
                    </div>
                )          
            :null}
            
            <div className="my-1 col-12">
                <label className="col-form-label" htmlFor="module-mapped-module">Mapped Module :</label>
                
                
                <select className="form-select" 
                  defaultValue={props.updateUser?.mapped_module_id } 
                  id="mapped_module_id" 
                  name="mapped_module_id" 
                  values={formik.values.mapped_module_id } 
                  // onChange={formik.handleChange} >
                  onChange={(e) => { formik.handleChange(e); someFunctionWithLogic(e, formik.setFieldValue ); }}>

                    {props.mappedModuleLines.map((_mapped_modulelines, i) =>(
                        <option key={i} value={_mapped_modulelines.id}>{_mapped_modulelines.name}</option>
                    ))}
                </select>

                {formik.touched.mapped_module && formik.errors.mapped_module ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.mapped_module}</div>
                ) : null}

            </div>

            <div className="my-1 col-12">
                <label className="col-form-label" htmlFor="module-name">Name:</label>
                
                <input
                    className="form-control"
                    id="module-name"
                    name="name"
                    type="text"
                    placeholder="Module  Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name }
                />
                {formik.touched.name && formik.errors.name ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.name}</div>
                ) : null}

            </div>
            
            <div className="mb-4">            
                <label className="col-form-label" htmlFor="module-description">Description:</label>
                
                <textarea
                    className="form-control"
                    id="module-description"
                    name="description"
                    type="text"
                    placeholder="Description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description }
                />
                {formik.touched.description && formik.errors.description ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.description}</div>
                ) : null}

            </div>

            <hr/>
            <div className="col-6">
                <label className="col-form-label" htmlFor="monthly-price">Monthly Price:</label>
                
                <input
                    className="form-control"
                    id="monthly-price"
                    name="monthly_price"
                    type="number"
                    placeholder="Monthly Price"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.monthly_price }
                />
                {formik.touched.monthly_price && formik.errors.monthly_price ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.monthly_price}</div>
                ) : null}

            </div>

            
            <div className="my-1 col-6">
                <label className="col-form-label" htmlFor="quarterly-price">Quarterly Price:</label>
                
                <input
                    className="form-control"
                    id="quarterly-price"
                    name="quarterly_price"
                    type="number"
                    placeholder="Quarterly Price"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.quarterly_price }
                />
                {formik.touched.quarterly_price && formik.errors.quarterly_price ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.quarterly_price}</div>
                ) : null}

            </div>

            
            <div className="my-1 col-6">
                <label className="col-form-label" htmlFor="yearly-price">Yearly Price:</label>
                
                <input
                    className="form-control"
                    id="yearly-price"
                    name="yearly_price"
                    type="number"
                    placeholder="Yearly Price"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.yearly_price }
                />
                {formik.touched.yearly_price && formik.errors.yearly_price ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.yearly_price}</div>
                ) : null}

            </div>
        </div>
        
  </form>
  )
}

const mapStateToProps = (state) => {
    return {
      mappedModuleLines: state.erpBackend.mappedModuleLines
    }
  }


const mapDispatchToProps = dispatch=>{
  return {    
    getMappedModuleLineList: (setLoading) => dispatch(getMappedModuleLineList(setLoading)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleLineForm)
