import * as actionType from '../constants/actionTypes';

const moduleReducer = (state = { moduleList: []  }, action) => {
  switch (action.type) {
    case actionType.FETCH_MODULES:
      // localStorage.setItem('profile', JSON.stringify({ ...action?.data }));

      return { ...state, moduleList: action.payload };
      
    case "CREATE_MODULE":
      console.log('In CREATE_MODULE', action.payload)
      return {
        ...state,
        loading: false,
        moduleList: [...state.moduleList, action.payload],
        error: '',
      };
    case "UPDATE_MODULE":
      return {
        ...state,
        moduleList: [...state.moduleList].map((module) => {
          if (module._id !== action.payload._id) {
            return module
          }
          return action.payload
        })
      }
      case "DELETE_MODULE":
        return {
          ...state,
          moduleList: [...state.moduleList.filter((module) => module._id !== action.payload)]
        }
    default:
      return state;
  }
};

export default moduleReducer;