import { fetchClientKeyApi, fetchPricingsApi, fetchStripePublicKeyApi, getUserDetailsByPaymentIntentApi, sendSuccessCheckoutApi } from "../../api";
import { FECTH_PRICING, FETCH_CLIENT_KEY, FETCH_STRIPE_PUBLIC_KEY, SEND_SUCCESS_PAYMENT_MAIL } from "../constants/actionTypes";

  
export const fetchPricings = (formData, router) => async (dispatch) => {

    try {
      const { data } = await fetchPricingsApi();
  
      dispatch({ type: FECTH_PRICING, payload: data });
      return data[0]
    } catch (error) {
      console.log('In fetchPricings',error);
    }
  };
  
  export const getUserDetailsByPaymentIntent = (payment_intent, payment_intent_client_secret) => async (dispatch) => {
    console.log('payment_intent, payment_intent_client_secret', payment_intent, payment_intent_client_secret)
    try {
      const {data} = await getUserDetailsByPaymentIntentApi(payment_intent, payment_intent_client_secret);
      dispatch({type: "GET_USER_BY_PAYMENT_INTENT", payload: data})
      return data
    } catch (error) {
      console.error("-- In getUserDetailsByPaymentIntent:>>",error)
      return {code: 500, message: "something went erong !"}
    }
  }

  
export const fetchStripePublicKey = (formData, router) => async (dispatch) => {

  try {
    const { data } = await fetchStripePublicKeyApi();

    dispatch({ type: FETCH_STRIPE_PUBLIC_KEY, payload: data });
    return data
  } catch (error) {
    console.log('In fetchStripePublicKey',error);
  }
};

  
export const fetchClientKey = (formData) => async (dispatch) => {
  console.log('-- In fetchClientKey:>>', formData)
  try {
    const { data } = await fetchClientKeyApi(formData);

    dispatch({ type: FETCH_CLIENT_KEY, payload: data });
    return data
  } catch (error) {
    console.log('In fetchClientKey',error);
  }
};

export const sendSuccessCheckout = (formData) => async (dispatch) => {
  // alert(JSON.stringify(formData))
  console.log('-- In sendSuccessCheckout:>>', formData)
  try {
    const { data } = await sendSuccessCheckoutApi(formData);

    dispatch({ type: SEND_SUCCESS_PAYMENT_MAIL, payload: data });
    return data
  } catch (error) {
    console.log('In sendSuccessCheckout',error);
  }
};