import { API, env_config } from '../env';

const config = env_config

API.interceptors.request.use((req) => {
  if (localStorage.getItem('profile')) {
    req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
  }
  req.headers['Content-Type'] = 'application/json'
  return req;
});

export const fetchModulesApi = () => API.get('/modules', config);

export const fetchModuleLinesApi = () => API.get('/modulelines', config);
export const fetchUserProductApi = (_token) => API.get('/user_product', {
  ...config,
  params:{
    ...config.params,
    token:_token
  }
});

export const fetchUserProductListApi = () => API.get('/user_product/list', {
  ...config,
  params:{
    ...config.params,
  }
});

export const getSubscribedUserModulineByCompanyIdApi = (company_id) => API.get(`/modulelines/by_company_id/${company_id}`, {
  ...config,
  params:{
    ...config.params,
    company_id:company_id
  }
});
export const changeActiveSubscribedUserModulelinesApi = (moduleline) => API.put(`/modulelines/${moduleline._id}/activate_moduleline`, moduleline, config)
export const createUserProductApi = (user_product) => API.post('/user_product', user_product, config);

export const addModulineApi = (data) => API.post('/user_product/add', data, config);
export const removeModuleLineApi = (data) => API.post('/user_product/remove', data, config);

export const fetchPricingsApi = () => API.get('/pricing', config);

export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);
export const sendPasswordRecoveryMailAPi = (formData) => API.post('/user/request-password-reset', formData);

export const addPricingToUserProductApi = (data) => API.post('/user_product/add_pricing', data, config)

export const fetchStripePublicKeyApi = () => API.get('/pricing/stripe_public_key', config)

export const fetchClientKeyApi = (body) => API.post('/pricing/client_key', body, config)

export const sendSuccessCheckoutApi = (body) => API.post('/send_success_payment_mail', body, config)

export const getUserDetailsByPaymentIntentApi = (payment_intent, payment_intent_client_secret) => API.get('/user/by_payment_intent', {
  ...config,
  params: {
    ...config.params, 
    payment_intent,
    payment_intent_client_secret
  }
})

export const getCompanyByIdApi = (company_id) => API.get(`/company/${company_id}`, {
  ...config,
  params: {
    ...config.params, 
    company_id
  }
})
export const getSubscribedUserCompanyApi = (user_id) => API.get(`/company/by_user_id/${user_id}`,{
  ...config,
  params: {
    ...config.params, 
    user_id
  }
})


export const addSubscribedModulesApi = (body) => API.post('/company/add_subscribed_modules', body, config)
// export const signInApi = (body) => API.post('/user/signin', body, config)
export const unsubscribeSubmoduleApi = (submodule) => API.post('/company/unsubscribe_submodule', submodule, config)
// export const signInApi = (body) => API.post('/user/signin', body, config)
export const deleteCompanyApi = (company_id) => API.delete(`/company/unsubscribe/${company_id}`, {...config,
params: {
  ...config.params, 
  company_id
}
})

export const getPaymentHistoryByIntentApi = (payment_intent) => API.get(`/payment_history/${payment_intent}`, {
  ...config,
  params: {
    ...config.params, 
    payment_intent
  }
})
export const createPaymentHistoryApi = (payment_history) => API.post('/payment_history', payment_history, config)

export const getPaymentHistoryByUserIdApi = (user_id) => API.get(`/payment_history/user_id/${user_id}`, {
  ...config,
  params: {
    ...config.params, 
    user_id
  }
})

export const getModuleByIdApi = (module_id) => API.get(`/modules/${module_id}`, {
  ...config,
  params: {
    ...config.params, 
    module_id
  }
})

export const fetchPricingsCrudApi = () => API.get('/pricing/v1', config)

export const deletePricingsCrudApi = (number_of_user) => API.delete(`pricing/v1/${number_of_user}`, )
// permission
export const getPermissionByRoleIdApi = (role_id) => API.get(`permissions/${role_id}`)

// export const fetchUserPermissionsApi = (user_id) => API.get(`/permissions/${user_id}`, {
//   ...config,
//   params: {
//     ...config.params, 
//     user_id
//   }
// })

// User Crud
export const fetchUsersApi = () => API.get('/users', config)
//role
export const fetchRolesApi = () => API.get('/roles', config)
export const getRoleByIdApi = (role_id) => API.get(`/roles/${role_id}`, config)
export const handleResetPasswordApi = (user_id, connected_user) => API.post(`/users/reset_user_password/${user_id}`, {user_id,connected_user}, config)
