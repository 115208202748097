import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { API, env_config } from '../../env';

const CompanyForm = (props) => {
    const [message, setMessage] = useState(false)
    const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('profile')))
    useEffect(() => {
      setUserDetails(JSON.parse(localStorage.getItem('profile')));
      
    }, [])
    
    const formik = useFormik({
        initialValues: {
            user_id: userDetails?.result?._id,
            name: "TakaPrint",
            email: "gregory.goufan@takaprint.com",
            company_size: 4,
            phoneNumber: "677428154",
            location: "Douala Cameroun",
            website: 'www.takaprint.com'
        },
        validationSchema: Yup.object({
    
            name: Yup.string()
      .max(30, "Must be 30 characters or less")
      .required("Required"),
          location: Yup.string()
            .max(125, "Must be 125 characters or less")
            .required("Required"),
          email: Yup.string().email("Invalid email address").required("Required"),
          phoneNumber: Yup.number()
            .typeError("That doesn't look like a phone number")
            .positive("A phone number can't start with a minus")
            .integer("A phone number can't include a decimal point")
            .required("A phone number is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
        values.user = userDetails?.result
        // console.log('-- values', values)
        // return
        
        const config = env_config;
        
          try {
            await API
              .post("/company", {
                  ...values
            }, config)
              .then((res) => {
                const { data } = res
                setMessage({code: res.status, message: "Company successfully created"});
                props.setCompanies((prev)=>[...prev, data])
                resetForm({ values: "" }) 
                setTimeout(() => {
                    document.querySelector('#close-create-company-modal').click();
                  }, 2000);

              }).catch((err)=>{
                // console.error("-- err", err.response);
                setMessage({code: err.response?.status || 500, message: err.response ? err.response.data : err})                

              }).finally(()=> {setTimeout(() => {
                // Most recent value
                setMessage(false);                   
              }, 5000);
              resetForm({ values: "" });});
          } catch (error) {
            // console.error("-- error", error);
            setMessage({code: 404, message: error})
          } finally {
          }
        },
      });

  return (      
    <form 
        id="login-form" 
        onSubmit={formik.handleSubmit}
        method="post" action=""
        className="row g-3">
        <div className='row'>
            {message && message.code ? 
                message.code.toString().substr(0,2)  === "20" ? (   
                <div className="alert alert-success" role="alert">
                    {message.message}
                </div>
                ) : (
                    <div className="alert alert-danger" role="alert">
                    {JSON.stringify(message?.message?.message)}
                    </div>
                )          
            :null}
            <div className="my-1 col-6">
                <label className="col-form-label" htmlFor="company-name">Name:</label>
                
                <input
                    className="form-control"
                    id="company-name"
                    name="name"
                    type="text"
                    placeholder="Company Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name }
                />
                {formik.touched.name && formik.errors.name ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.name}</div>
                ) : null}

            </div>
            <div className="my-1 col-6">
                <label className="col-form-label" htmlFor="email">Email:</label>
                
                <input
                    className="form-control"
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email }
                />
                {formik.touched.email && formik.errors.email ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.email}</div>
                ) : null}

            </div>
            <div className="my-1 col-6">
                <label className="col-form-label" htmlFor="company-size">Size:</label>
                
                <input
                    className="form-control"
                    id="company-size"
                    name="company_size"
                    type="number"
                    placeholder="Size"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.company_size }
                />
                {formik.touched.company_size && formik.errors.company_size ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.company_size}</div>
                ) : null}

            </div>
            <div className="my-1 col-6">
                <label className="col-form-label" htmlFor="phone-number">Number:</label>
                
                <input
                    className="form-control"
                    id="phone-number"
                    name="phoneNumber"
                    type="tel"
                    placeholder="Numéro de téléphone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber }
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.phoneNumber}</div>
                ) : null}

            </div>
            
            <div className="mb-4">            
                <label className="col-form-label" htmlFor="company-website">Website:</label>
                
                <input
                    className="form-control"
                    id="company-website"
                    name="website"
                    type="text"
                    placeholder="Website"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.website }
                />
                {formik.touched.website && formik.errors.website ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.website}</div>
                ) : null}

            </div>

            <div className="mb-4">            
                <label className="col-form-label" htmlFor="company-location">Location:</label>
                
                <input
                    className="form-control"
                    id="company-location"
                    name="location"
                    type="text"
                    placeholder="Location"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.location }
                />
                {formik.touched.location && formik.errors.location ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.location}</div>
                ) : null}

            </div>
        </div>
        
  </form>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyForm)