import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { createRoleSuccess, updateRoleSuccess } from '../../redux/actions/roleActions';
import { env_config, API } from '../../env';

const RoleForm = (props) => {
    const [message, setMessage] = useState(false)
    const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('profile')))
    useEffect(() => {
      setUserDetails(JSON.parse(localStorage.getItem('profile')));
      console.log('props.updateRole', props.updateRole)
    }, [])
    
    const formik = useFormik({
        initialValues: {
            name: props.updateRole?.name ? props.updateRole?.name : "",
        },
        validationSchema: Yup.object({  
            name: Yup.string()
            .max(30, "Must be 30 characters or less")
            .required("Required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log('values', values)
            
            const config = env_config;        
          try {

            if (!props.isUpdate) {
                values.createdBy = userDetails?.result
                values.is_staff = true
                await API
                .post("/roles", {
                  ...values
                }, config)
                .then((res) => {
                    const { data } = res
                    props.createRoleSuccess(data);
                    setMessage({code: res.status, message: "Role successfully updated"}); 
                    resetForm({ values: "" }) 
                }).catch((err)=>{
                    // console.error("-- err", err.response);
                    setMessage({code: err.response?.status || 500, message: err.response ? err.response.data : err})
                }).finally(()=> {setTimeout(() => {
                    // Most recent value
                    setMessage(false);       
                    props.handleClose()            
                }, 5000);
                resetForm({ values: "" });})
            } else {
                console.log('values', props.updateRole)
                values.updatedBy = userDetails?.result
                values.is_staff = true
                await API
                .put(`/roles/${props.updateRole._id}`, {
                  ...values
                }, config)
                .then((res) => {
                    const { data } = res
                    props.updateRoleSuccess(data)
                    setMessage({code: res.status, message: "Role successfully updated"}); 
                    resetForm({ values: "" }) 
                }).catch((err)=>{
                    // console.error("-- err", err.response);
                    setMessage({code: err.response?.status || 500, message: err.response ? err.response.data : err})
                }).finally(()=> {setTimeout(() => {
                    // Most recent value
                    setMessage(false);        
                    props.handleClose()                
                }, 3000);
                resetForm({ values: "" });})
            }
          } catch (error) {
            // console.error("-- error", error);
            setMessage({code: 404, message: error})
          } finally {
          }
        },
      });

  return (      
    <form 
        id="roles-crud-form" 
        onSubmit={formik.handleSubmit}
        method="post" action=""
        className="row g-3">
        <div className='row'>
            {message && message.code ? 
                message.code.toString().substr(0,2)  === "20" ? (   
                <div className="alert alert-success" role="alert">
                    {message.message}
                </div>
                ) : (
                    <div className="alert alert-danger" role="alert">
                    {JSON.stringify(message?.message?.message)}
                    </div>
                )          
            :null}
            <div className="my-1 col-12">
                <label className="col-form-label" htmlFor="name">Name:</label>                
                <input
                    className="form-control"
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name }
                />
                {formik.touched.name && formik.errors.name ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.name}</div>
                ) : null}

            </div>          
            
        </div>
  </form>
  )
}

const mapStateToProps = (state) => {
    return {
    }
  }

  const mapDispatchToProps = dispatch =>{
    return {
      createRoleSuccess:(role) => dispatch(createRoleSuccess(role)),
      updateRoleSuccess:(role)=>dispatch(updateRoleSuccess(role)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(RoleForm)