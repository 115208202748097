import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { API, env_config } from '../../env';

const ModuleForm = (props) => {
    const [message, setMessage] = useState(false)
    const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('profile')))
      
    useEffect(() => {
      console.log('In ModuleForm props', props)  
      setUserDetails(JSON.parse(localStorage.getItem('profile')));   
      // props.
      return () => {
        localStorage.removeItem('module_item')
        props.setIsUpdate(false)
      }
    }, [])    
    
    const formik = useFormik({
        initialValues: {
          name: props.updateModule? props.updateModule?.name  : '' ,
          description: props.updateModule ? props.updateModule?.description  : '' ,
          total_price: props.updateModule ? props.updateModule?.total_price  : 0 ,
        },
        validationSchema: Yup.object({
                name: Yup.string()
                .max(30, "Must be 30 characters or less")
                .required("Required"),
            description: Yup.string()
                .max(125, "Must be 125 characters or less")
                .required("Required"),
             total_price: Yup.number(),
        }),
        onSubmit: async (values, { resetForm }) => {
          console.log('values', values)
        values.createdBy = userDetails?.result

        // return
        
        const config = env_config;      
          try {

            {!props.isUpdate ? (async ()=> {await API
              .post("/modules", {
                  ...values
            }, config)
              .then((res) => {
                const { data } = res
                // console.log('in ModulesForm responser, res', res)
                props.createModuleSuccess(data)
                setMessage({code: res.status, message: "Module successfully created"});                
                // props.setCompanies((prev)=>[...prev, data])
                resetForm({ values: "" }) 
                setTimeout(() => {
                  props.handleClose();
                  }, 2000);

              }).catch((err)=>{
                // console.error("-- err", err.response);
                setMessage({code: err.response?.status || 500, message: err.response ? err.response.data : err})                

              }).finally(()=> {setTimeout(() => {
                // Most recent value
                setMessage(false);                   
              }, 5000);
              resetForm({ values: "" });});})() : 
              // Update logic
              (async ()=> {
                // console.log('-- update values', values)
                let tmp_module = JSON.parse(localStorage.getItem('module_item'))
                await API
                  .put(`/modules/${tmp_module._id}`, {
                    ...values
                  }, config)
                    .then(res=>{
                      const { data } = res
                      console.log('in ModulesForm responser, res', res)
                      props.updateModuleSuccess(data)
                      setMessage({code: res.status, message: "Module successfully updated"});                
                      // props.setCompanies((prev)=>[...prev, data])
                      resetForm({ values: "" }) 
                      setTimeout(() => {
                        props.handleClose();
                          localStorage.removeItem('module_item')
                          props.setIsUpdate(false)
                        }, 2000);
                    })
              })()
            }


          } catch (error) {
            // console.error("-- error", error);
            setMessage({code: 404, message: error})
          } finally {
          }
        },
      });

  return (     
    <form 
        id="module-form" 
        onSubmit={formik.handleSubmit}
        method="post" action=""
        className="row g-3" style={{marginRight: '-2rem'}}>
        <div className='row'>
            {message && message.code ? 
                message.code.toString().substr(0,2)  === "20" ? (   
                <div className="alert alert-success" role="alert">
                    {message.message}
                </div>
                ) : (
                    <div className="alert alert-danger" role="alert">
                    {JSON.stringify(message?.message?.message)}
                    </div>
                )          
            :null}
            <div className="my-1 col-6">
                <label className="col-form-label" htmlFor="module-name">Name:</label>
                
                <input
                    className="form-control"
                    id="module-name"
                    name="name"
                    type="text"
                    placeholder="Module  Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name }
                />
                {formik.touched.name && formik.errors.name ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.name}</div>
                ) : null}

            </div>

            <div className="my-1 col-6">
                <label className="col-form-label" htmlFor="total-price">Price:</label>
                
                <input
                    className="form-control"
                    id="total-price"
                    name="total_price"
                    type="number"
                    placeholder="Price"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.total_price }
                />
                {formik.touched.total_price && formik.errors.total_price ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.total_price}</div>
                ) : null}

            </div>
            
            <div className="mb-4">            
                <label className="col-form-label" htmlFor="module-description">Description:</label>
                
                <textarea
                    className="form-control"
                    id="module-description"
                    name="description"
                    type="text"
                    placeholder="Price"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description }
                />
                {formik.touched.description && formik.errors.description ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.description}</div>
                ) : null}

            </div>
        </div>
        
  </form>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleForm)