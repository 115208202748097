import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getPaymentHistoryByUserId } from '../../redux/actions/paymentHistoryAction'

const PaymentHistory = (props) => {
  const [paymentHistories, setPaymentHistories] = useState([])
  useEffect(() => {
    let { result: user } = JSON.parse(localStorage.getItem("profile"))
    console.log('user', user)
    const fetchData = async()=>{
      let fetch_payment_history = await props.getPaymentHistoryByUserId(user._id)
      return {fetch_payment_history}
    }
    fetchData().then(res=>{
      setPaymentHistories(res.fetch_payment_history)
    })
  }, [])
  
  return (
    <>        
        <div className="row g-3 mb-3 d-none">
          {/* <span>{JSON.stringify(paymentHistories)}</span> */}
            <div className="col-md-6 col-xxl-3">
              <div className="card h-md-100 ecommerce-card-min-width">
                <div className="card-header pb-0">
                  <h6 className="mb-0 mt-2 d-flex align-items-center">Weekly Sales<span className="ms-1 text-400" data-bs-toggle="tooltip" data-bs-placement="top" title="Calculated according to last week's sales"><span className="far fa-question-circle" data-fa-transform="shrink-1"></span></span></h6>
                </div>
                <div className="card-body d-flex flex-column justify-content-end">
                  <div className="row">
                    <div className="col">
                      <p className="font-sans-serif lh-1 mb-1 fs-4">$47K</p><span className="badge badge-soft-success rounded-pill fs--2">+3.5%</span>
                    </div>
                    <div className="col-auto ps-0">
                      <div className="echart-bar-weekly-sales h-100"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xxl-3">
              <div className="card h-md-100">
                <div className="card-header pb-0">
                  <h6 className="mb-0 mt-2">Total Order</h6>
                </div>
                <div className="card-body d-flex flex-column justify-content-end">
                  <div className="row justify-content-between">
                    <div className="col-auto align-self-end">
                      <div className="fs-4 fw-normal font-sans-serif text-700 lh-1 mb-1">58.4K</div><span className="badge rounded-pill fs--2 bg-200 text-primary"><span className="fas fa-caret-up me-1"></span>13.6%</span>
                    </div>
                    <div className="col-auto ps-0 mt-n4">
                      <div className="echart-default-total-order" data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","week 6","week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}' data-echart-responsive="true"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xxl-3">
              <div className="card h-md-100">
                <div className="card-body">
                  <div className="row h-100 justify-content-between g-0">
                    <div className="col-5 col-sm-6 col-xxl pe-2">
                      <h6 className="mt-1">Market Share</h6>
                      <div className="fs--2 mt-3">
                        <div className="d-flex flex-between-center mb-1">
                          <div className="d-flex align-items-center"><span className="dot bg-primary"></span><span className="fw-semi-bold">samsung</span></div>
                          <div className="d-xxl-none">33%</div>
                        </div>
                        <div className="d-flex flex-between-center mb-1">
                          <div className="d-flex align-items-center"><span className="dot bg-info"></span><span className="fw-semi-bold">Huawei</span></div>
                          <div className="d-xxl-none">29%</div>
                        </div>
                        <div className="d-flex flex-between-center mb-1">
                          <div className="d-flex align-items-center"><span className="dot bg-300"></span><span className="fw-semi-bold">Huawei</span></div>
                          <div className="d-xxl-none">20%</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-auto position-relative">
                      <div className="echart-market-share"></div>
                      <div className="position-absolute top-50 start-50 translate-middle text-dark fs-2">26M</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-xxl-3">
              <div className="card h-md-100">
                <div className="card-header d-flex flex-between-center pb-0">
                  <h6 className="mb-0">Weather</h6>
                  <div className="dropdown font-sans-serif btn-reveal-trigger"><button className="btn btn-link text-600 btn-sm dropdown-toggle dropdown-caret-none btn-reveal" type="button" id="dropdown-weather-update" data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true" aria-expanded="false"><span className="fas fa-ellipsis-h fs--2"></span></button>
                    <div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="dropdown-weather-update"><a className="dropdown-item" href="#!">View</a><a className="dropdown-item" href="#!">Export</a>
                      <div className="dropdown-divider"></div><a className="dropdown-item text-danger" href="#!">Remove</a>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-2">
                  <div className="row g-0 h-100 align-items-center">
                    <div className="col">
                      <div className="d-flex align-items-center"><img className="me-3" src="assets/img/icons/weather-icon.png" alt="" height="60" />
                        <div>
                          <h6 className="mb-2">New York City</h6>
                          <div className="fs--2 fw-semi-bold">
                            <div className="text-warning">Sunny</div>Precipitation: 50%
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-auto text-center ps-2">
                      <div className="fs-4 fw-normal font-sans-serif text-primary mb-1 lh-1">31&deg;</div>
                      <div className="fs--1 text-800">32&deg; / 25&deg;</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
          
          <div className="card mb-3">
            <div className="card-header border-bottom">
              <div className="row flex-between-end">
                <div className="col-auto align-self-center">
                  <h5 className="mb-0" data-anchor="data-anchor">Payment History</h5>
                  <p className="mb-0 mt-2 mb-0">You will find her all your recent payments history</p>
                </div>
                <div className="col-auto ms-auto">
                  {/* <div className="nav nav-pills nav-pills-falcon flex-grow-1 mt-2" role="tablist"><button className="btn btn-sm active" data-bs-toggle="pill" data-bs-target="#dom-03566b12-f030-4979-9e36-0ec0a16a8cdc" type="button" role="tab" aria-controls="dom-03566b12-f030-4979-9e36-0ec0a16a8cdc" aria-selected="true" id="tab-dom-03566b12-f030-4979-9e36-0ec0a16a8cdc">Preview</button><button className="btn btn-sm" data-bs-toggle="pill" data-bs-target="#dom-0d7a0694-186b-4f01-839b-782effc19e71" type="button" role="tab" aria-controls="dom-0d7a0694-186b-4f01-839b-782effc19e71" aria-selected="false" id="tab-dom-0d7a0694-186b-4f01-839b-782effc19e71">Code</button></div> */}
                </div>
              </div>
            </div>
            <div className="card-body pt-0">
              <div className="tab-content">
                <div className="tab-pane preview-tab-pane active" role="tabpanel" aria-labelledby="tab-dom-03566b12-f030-4979-9e36-0ec0a16a8cdc" id="dom-03566b12-f030-4979-9e36-0ec0a16a8cdc">
                  <div className="table-responsive scrollbar">
                    <table className="table table-hover table-striped overflow-hidden">
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">User</th>
                          <th scope="col">Email</th>
                          <th scope="col">Last Payment</th>
                          <th scope="col">Next Due date </th>
                          <th scope='col'>Payment cycle</th>
                          <th scope="col">Status</th>
                          <th className="text-end" scope="col">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                      
                      {paymentHistories.map((payment_history, payment_history_index)=>(
                        <tr key={payment_history_index} className="align-middle">
                        <td className="text-nowrap">{payment_history?.name || 'N/A'}</td>
                          <td className="text-nowrap">
                            <div className="d-flex align-items-center">
                              <div className="avatar avatar-xl">
                                <div className="avatar-name rounded-circle"><span>{`${payment_history.user.last_name[0].toString().toUpperCase()}${payment_history.user.first_name[0].toString().toUpperCase()}`}</span></div>
                              </div>
                              <div className="ms-2">{payment_history.user.last_name}{" "}{payment_history.user.first_name}</div>
                            </div>
                          </td>
                          <td className="text-nowrap">{payment_history.user.email}</td>
                          <td className="text-nowrap">{moment(payment_history.last_payment).format("MMMM Do YYYY")}</td>
                          <td className="text-nowrap">{moment(payment_history.next_due_date).format("MMMM Do YYYY")}</td>
                          <td className="text-nowrap">{payment_history.pricing.paiement_cycle}</td>
                          <td><span className="badge badge rounded-pill d-block p-2 badge-soft-success">{payment_history.status}<span className="ms-1 fas ms-1 fas fa-check" data-fa-transform="shrink-2"></span></span></td>
                          <td className="text-end">{computeAmountToPay(payment_history)}</td>
                        </tr>

                      ))}

                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="tab-pane code-tab-pane" role="tabpanel" aria-labelledby="tab-dom-0d7a0694-186b-4f01-839b-782effc19e71" id="dom-0d7a0694-186b-4f01-839b-782effc19e71"><pre className="scrollbar rounded-1" style={{maxHeight:'420px'}}><code className="language-html"></code></pre>
                </div>
              </div>
            </div>
          </div>

    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch=>{
  return {
    getPaymentHistoryByUserId: (_payment_intent)=>dispatch(getPaymentHistoryByUserId(_payment_intent)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory)


export const computeAmountToPay = (payment_history) =>{
  let _amount = 0 
  console.log('payment_history', payment_history)

  Object.keys(payment_history.modules).forEach(module_key=>{
    console.log('-- payment_history', payment_history.modules[`${module_key}`])
    let tmp_paymentCycle = payment_history.pricing.paiement_cycle;
    let tmp_numberOfUser = payment_history.pricing.users;

    Object.keys(payment_history.modules[`${module_key}`]).forEach(moduleline_key=>{
      console.log('-- payment_history', payment_history.modules[`${module_key}`][`${moduleline_key}`])
      let tmp = payment_history.modules[`${module_key}`][`${moduleline_key}`];

      let paymentCycleNbrOfMonth = 1
      switch (tmp_paymentCycle) {
        case 'quarterly':
          paymentCycleNbrOfMonth = 3
          break;
        case 'yearly':
          paymentCycleNbrOfMonth = 12
          break;      
        default:
          break;
      }

      _amount +=  parseFloat(tmp.price[`${tmp_paymentCycle}`])*tmp_numberOfUser*paymentCycleNbrOfMonth
    })
  })

  
  return `$ ${_amount}`
}