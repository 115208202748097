import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { moment } from 'moment'
import { getSubscribedUserCompanyByUserId } from '../../redux/actions/companyAction'
import { Button } from 'react-bootstrap'

const SubscribedUserCompany = (props) => {
    const { user_id } = useParams()
    const [message, setMessage] = useState(false)
    
    useEffect(() => {
        localStorage.setItem('user_id',user_id)
        const fetchData = async ()=>{
            const _user = JSON.parse(localStorage.getItem('profile')).result
            
            await props.getSubscribedUserCompanyByUserId(user_id)
            return;
          }
          fetchData().then(res=>{
            console.log('res', res)            
          })
    
    }, [])

  return (
    <>  
        {message && message.code ? 
            message.code.toString().substr(0,2)  === "20" ? (   
            <div className="alert alert-success" role="alert">
                {message.message}
            </div>
            ) : (
                <div className="alert alert-danger" role="alert">
                {JSON.stringify(message?.message?.message)}
                </div>
            )          
        :null}
        
      <div className="card mb-3">
        <div className="bg-holder d-none d-lg-block bg-card" style={{backgroundImage:'url(/assets/img/icons/spot-illustrations/corner-4.png)',opacity: '0.7'}}></div>
        
        <div className="card-body position-relative">
          <div className='d-flex'>
            <div className=''>
            <Link className='f--1' to='/subscribed_user'><span className="fas fa-arrow-left me-2"></span></Link>
            
            </div>
              <div className=''>
              
              <h5>{props.role?.name}</h5>
              <p className="mb-0">{'Descriptions'}</p>
              {props.role?.created_at && <p className="fs--1">{props.role?.createdAt && moment(Date('06-06-2022')).format('MMMM Do YYYY, h:mm:ss a')}</p>}
              <a className="btn btn-link btn-sm ps-0 mt-2" href="#" >Select on Bootstrap<span className="fas fa-chevron-right ms-1 fs--2"></span></a>
            </div>
          </div>
          {props.role?.status && <div><strong className="me-2">Status: </strong>
            <div className={`badge rounded-pill badge-soft-${props.role && props.role.status === 'disabled' ? 'warning' : 'success'} fs--2`}>{props.subscribedUserCompanyList?.status}<span className="fas fa-check ms-1" data-fa-transform="shrink-2"></span></div>
          </div>}
        </div>
        
      </div>

          {/* table */}
          <div className="card z-index-1 mb-3" id="recentPurchaseTable" data-list='{"valueNames":["name","createdBy","product","is_staff","amount"],"page":8,"pagination":true}'>
            <div className="card-header">
              <div className="row flex-between-center">
                <div className="col-6 col-sm-auto d-flex align-items-center pe-0">
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Companies </h5>
                </div>
                <div className="col-6 col-sm-auto ms-auto text-end ps-0">
                  <div className="d-none" id="table-modules-actions">
                    <div className="d-flex"><select defaultValue="bulk" className="form-select form-select-sm" aria-label="Bulk actions">
                        <option value="bulk">Bulk actions</option>
                        <option value="Refund">Refund</option>
                        <option value="Delete">Delete</option>
                        <option value="Archive">Archive</option>
                      </select><button className="btn btn-falcon-default btn-sm ms-2" type="button">Apply</button></div>
                  </div>
                  {/* data-bs-toggle="modal" data-bs-target="#module-modal" */}

                </div>
              </div>
            </div>
            <div className="card-body px-0 py-0">
              <div className="table-responsive scrollbar">
                
              {props.user_permissions?.user?.can_view ? (
                <table className="table table-sm fs--1 mb-0 overflow-hidden">
                  <thead className="bg-200 text-900">
                    <tr>
                      <th className="white-space-nowrap">
                        <div className="form-check mb-0 d-flex align-items-center"><input className="form-check-input" id="checkbox-bulk-modules-select" type="checkbox" data-bulk-select='{"body":"table-purchase-body","actions":"table-modules-actions","replacedElement":"table-modules-replace-element"}' /></div>
                      </th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="name">Name</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="CreatedBy" >Created By</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="CreateddBy" >Created At</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="modifiedAt" >Updated By</th>
                      <th className="no-sort pe-1 align-middle data-table-row-action"></th>
                    </tr>
                  </thead>
                   <tbody className="list" id="table-purchase-body">
                    {props.subscribedUserCompanyList.map((company, index)=>(
                      <tr key={index} className="btn-reveal-trigger">
                        <td className="align-middle" style={{width: "28px"}}>
                          <div className="form-check mb-0"><input className="form-check-input" type="checkbox" id="recent-purchase-0" data-bulk-select-row="data-bulk-select-row" /></div>
                        </td>
                        <td className="align-middle white-space-nowrap name"><Link to={`/subscribed_user_modulelines/${company._id}`}>{company.name}</Link></td>
                        
                        <td className="sort pe-1 align-middle white-space-nowrap" >{company.createdBy?.last_name || 'n/a'}</td>
                        <td className="sort pe-1 align-middle white-space-nowrap" >{company.createdAt || 'n/a'}</td>
                        <td className="sort pe-1 align-middle white-space-nowrap" >{company.updatedBy?.last_name || 'n/a'}</td>
                        
                        <td className="align-middle white-space-nowrap text-end">
                          <div className="dropstart font-sans-serif position-static d-inline-block"><button className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal float-end" type="button" id="dropdown0" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span className="fas fa-ellipsis-h fs--1"></span></button>
                            <div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="dropdown0">                              
                              <a className="dropdown-item" href="#!">View</a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}      
                    <tr></tr>              
                  </tbody>
                </table>
              ): <h6 className='ml-5' style={{marginLeft: '1.7em'}}>{"Your are not allowed to view this"}</h6>}
              </div>
            </div>
            <div className="card-footer">
              <div className="row align-items-center">
                <div className="pagination d-none"></div>
                <div className="col">
                  <p className="mb-0 fs--1"><span className="d-none d-sm-inline-block me-2" data-list-info="data-list-info"> </span><span className="d-none d-sm-inline-block me-2">&mdash;  </span><a className="fw-semi-bold" href="#!" data-list-view="*">View all<span className="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a><a className="fw-semi-bold d-none" href="#!" data-list-view="less">View less<span className="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a></p>
                </div>
                <div className="col-auto d-flex"><button className="btn btn-sm btn-primary" type="button" data-list-pagination="prev"><span>Previous</span></button><button className="btn btn-sm btn-primary px-4 ms-2" type="button" data-list-pagination="next"><span>Next</span></button></div>
              </div>
            </div>
          </div>
    </>
  )
}

const mapStateToProps = (state) => ({
    user_permissions: state.permission.user_permissions,
    subscribedUserCompanyList: state.companyReducer.subscribedUserCompanyList,
  })

const mapDispatchToProps = dispatch => {
    return {        
      getSubscribedUserCompanyByUserId:(user_id) => dispatch(getSubscribedUserCompanyByUserId(user_id)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscribedUserCompany)