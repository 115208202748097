
import * as actionType from '../constants/actionTypes';

const paymentHistoryReducer = (state = { companyList: [], company: {}  }, action) => {
  switch (action.type) {

    case actionType.GET_PAYMENT_HISTORY_BY_USER_ID:
      return {...state, payment_histories: action.payload}
      case actionType.GET_PAYMENT_HISTORY_BY_PAYMENT_INTENT:
        return {...state, payment_histories: action.payload}
    case actionType.CREATE_PAYMENT_HISTORY:
      return {...state, payment_history: action.payload}
    default:
      return state;
  }
};

export default paymentHistoryReducer;