import * as actionType from '../constants/actionTypes';

const initialState = {
    selected_modules : []
}

const userProductReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionType.ADD_PRICING_TO_USER_PRODUCT:
      return {
        ...state,
        pricing: payload
      }
    case actionType.INITIATE_USER_PRODUCT_REQUEST:
      return { ...state, loading: true };
      case actionType.INITIATE_USER_PRODUCT_FAILURE:
        return {
          ...state,
          loading: false,
          error: payload,
        };
    case actionType.CREATE_OR_UPDATE_USER_PRODUCT:
      return {...state, selected_modules: [payload, ...state.selected_modules]}
      case actionType.FETCH_USER_PRODUCT:
        return {...state, selected_modules: payload}
  
    case actionType.REMOVE_USER_PRODUCT:
      // console.log('-- inpayload REMOVE_USER_PRODUCT :>>', state.selected_modules[payload.module_index].module.submodules[payload.submodules_index])

      if (payload.module.submodules.length === 0) {

        return {
          ...state,
          loading: false,
          selected_modules: [
            ...state.selected_modules.filter(_selected_module => _selected_module.module_id !== payload.module_id )
          ]
        }
      }

      return {
        ...state,
        loading: false,
        selected_modules: [
          ...state.selected_modules.map(_selected_module => {
            console.log('in REMOVE_USER_PRODUCT', _selected_module, _selected_module.module_id === payload.module_id);
            if (_selected_module.module_id !== payload.module_id) { return _selected_module};
            return payload;
          }),
        ]
      }
      case actionType.ADD_USER_PRODUCT:
        console.log('payload', [payload])
        return {
          ...state,
          loading: false,
          selected_modules: (()=> {
            console.log('In selected_modules',  )
            let isEqual = false
            let result = [...state.selected_modules]
            if ([...state.selected_modules].length === 0){
              return state.selected_modules.length >= 0 ? [payload, ...state.selected_modules] : []

            } else if ([...state.selected_modules].length >=1) {

              result =[
                ...state.selected_modules.map(_selected_module => {
                  console.log('in ADD_USER_PRODUCT', _selected_module, _selected_module.module_id === payload.module_id);
                  if (_selected_module.module_id !== payload.module_id) {console.log('isEqual', isEqual); return _selected_module};
                  isEqual = true;
                  return payload;
                }),
              ]
              if (isEqual === false) {
                console.log('In isEqual', isEqual )
                return [...state.selected_modules, payload] 
              }     
          } 
          
          console.log('result', result)
          return result          
        })()
      }
  default:
    return state
  }
}

export default userProductReducers;
    