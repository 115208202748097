import { ACTIVATE_MODULEINE_IN_COMPANY, CREATE_MODULELINES, DELETE_MODULELINE, FETCH_MODULELINES,GET_MODULELINE_BY_COMPANY_ID, INITIATE_MODULE_LINE_FAILURE, INITIATE_MODULE_LINE_REQUEST, UPDATE_MODULELINE } from '../constants/actionTypes';
import { BACKEND_DOMAIN } from '../../env';
import axios from 'axios';
import { changeActiveSubscribedUserModulelinesApi, fetchModuleLinesApi, getSubscribedUserModulineByCompanyIdApi } from '../../api';


// module Actions Creator
export const initiateModuleLineRequest = value => {
    return {
      type: INITIATE_MODULE_LINE_REQUEST,
      payload: value,
    };
  };
  export const initiateModuleLineFailure = error => {
    return {
      type: INITIATE_MODULE_LINE_FAILURE,
      payload: error,
    };
  };
  
  export const getSubscribedUserModulineByCompanyId = (company_id)  => async (dispatch) => {

    try {
      const { data } = await getSubscribedUserModulineByCompanyIdApi(company_id);
  
      dispatch({ type: GET_MODULELINE_BY_COMPANY_ID, payload: data, company_id: company_id });
      return data
    } catch (error) {
      console.log('In getSubscribedUserModulineByCompanyId',error);
    }
  }
  
  export const changeActiveSubscribedUserModulelines =(moduleline) => async (dispatch) => {
    try {
      const { data } = await changeActiveSubscribedUserModulelinesApi(moduleline);
  
      dispatch({ type: ACTIVATE_MODULEINE_IN_COMPANY, payload: data, moduleline: data });
      return data
    } catch (error) {
      console.log('In changeActiveSubscribedUserModulelinesApi',error);
    }
    
  }

export const fetchModuleLines = (formData, router) => async (dispatch) => {

  try {
    const { data } = await fetchModuleLinesApi();

    dispatch({ type: FETCH_MODULELINES, payload: data });
    return data
  } catch (error) {
    console.log('In fetchModuleLines',error);
  }
};

  
export const createModuleLine = modulelines => {
    // console.log('---- createModuleLine', modulelines);
  
    return dispatch => {
        dispatch(initiateModuleLineRequest);
        let stored_token = localStorage.getItem('token');
        axios
          .post(BACKEND_DOMAIN + '/modulelines/', modulelines)
          .then(response => {
            const moduleline = response.data;
            dispatch(createModuleLineSuccess(moduleline));
            // message.success({ content: 'ModuleLine added successfully!', key, duration: 2 });
            return moduleline;
          })
          .catch(error => {
            console.error('---- createModuleLine error', error);
            const errorMsg = error.message;
            dispatch(initiateModuleLineFailure(errorMsg));
          })
          .finally(() => console.log('first', ));
    };
  };
  
  

// updateModuleLineSuccess
export const updateModuleLineSuccess = moduleline => {
  console.log('moduleline', moduleline)
  return {
    type: UPDATE_MODULELINE,
    payload: moduleline,
  };
};

// createModuleLineSuccess
export const createModuleLineSuccess = moduleline => {
  console.log('moduleline', moduleline)
  return {
    type: CREATE_MODULELINES,
    payload: moduleline,
  };
};

export const deleteModuleLineSuccess = moduleline_id => {
  console.log('-- deleteModuleLineSuccess', moduleline_id)
  return {
    type: DELETE_MODULELINE,
    payload: moduleline_id,
  };
};