import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reportWebVitals from './reportWebVitals';
import thunk from 'redux-thunk';
import App from './App';
import { createBrowserHistory } from 'history';
import './index.css';
import { reducers } from './redux/reducers';
import { Router } from 'react-router-dom';
const history = createBrowserHistory();

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, {}, composeEnhances(applyMiddleware(thunk)));

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();