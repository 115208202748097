
import * as actionType from '../constants/actionTypes';

const companyReducer = (state = { companyList: [], company: {}, subscribedUserCompanyList: []  }, action) => {
  switch (action.type) {

    case actionType.GET_COMPANY_BY_ID:
      return {...state, company: action.payload}
    case actionType.UNSUBSCRIBE_SUBMODULE:
      console.log('-- action.payload:>', action.payload)
      return {...state, company: action.payload}
    case actionType.GET_COMPANY_BY_SUBSCRIBE_USER_ID:
      return {...state, subscribedUserCompanyList: action.payload}
    default:
      return state;
  }
};

export default companyReducer;