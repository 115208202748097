import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { ButtonLoader } from '../../components'
import { BACKEND_DOMAIN } from '../../env'
import axios from 'axios'
import { getPermissionByRoleId } from '../../redux/actions/permissionActions'
import FontProcessModal from './FontProcessModal'
import BackendProcessModal from './BackendProcessModal'
import {Modal} from 'react-bootstrap'

const UserProductDetails = (props) => {
const { user_token } = useParams()
const [company, setCompany] = useState({})
const [numberOfModules, setNumberOfModules] = useState(0)
const [message, setMessage] = useState({message: ''})
const [submoduleDetails, setSubmoduleDetails] = useState({})
const [loading, setLoading] = useState(false)
const [stripeSubscriptionDetailsData, setStripeSubscriptionDetailsData] = useState({})
const [show, setShow] = useState(false);
const handleClose = () => setShow(false);
const handleShow = () => setShow(true);

const user_product_details_source = axios.CancelToken.source()

    useEffect(() => {
      (async ()=>{
        // console.log('In UserProductDetails:>>',props)
        let _user = JSON.parse(localStorage.getItem('profile'));
        await props.getPermissionByRoleId(_user.result.role_id);
      })()
        axios.get(`${BACKEND_DOMAIN}/user_product/list/${user_token}`, {cancelToken: user_product_details_source.token})
        .then( res=>{
          let {data} = res;
          setCompany(data)
          setNumberOfModules(data.modules.reduce((acc, curr)=>{ acc = curr.submodules.length; return acc}, 0))
          
          data.hasOwnProperty('stripe') && data.stripe.hasOwnProperty('subscription') && setStripeSubscriptionDetailsData(data.stripe.subscription)
          // console.log('res', res)
        })
        .catch(error => {
            console.log('--- UserProductDetails error :>>',error);
          })

        return () => {
          user_product_details_source.cancel()
        }
    }, [])

const handleOnClickModal =(submodules)=>{
  // e.preventDefault()
  setSubmoduleDetails(submodules)
  console.log('first', )

}
// deleteCompany
const handleUnsubscribe = async(submodules) =>{
  
}
const HandleDeleteCompany = async() => {
  let company_id = localStorage.getItem('company_id');
  let _res = await props.deleteCompany(company_id, setLoading);
  
  console.log('HandleDeleteCompany', await _res.data)
  let {data, status} = _res

  if (_res.status == 200) {
    document.querySelector('#close-create-company-modal').click()
  } else {
    if (!data) {
      
      setMessage({code: 403,message: 'An unexpected error occured. Please refresh the page'})
      return; 
    }
    setMessage({code: status,message: data?.message})
  }
  setTimeout(() => {
    setMessage({})  
    document.querySelector('#close-create-company-modal').click()
    props.history.push(`/account`)
  }, 4000);
}
  return (
    <>
          <div className="card mb-3">
            <div className="bg-holder d-none d-lg-block bg-card" style={{backgroundImage:'url(/assets/img/icons/spot-illustrations/corner-4.png)',opacity: '0.7'}}></div>
            
            <div className="card-body position-relative">
              <div className='d-flex'>
                <div className=''>
                <a className='f--1' href='/user_product'>{" "}<span className="fas fa-arrow-left me-2"></span></a>
                
                </div>
                {company?.company && <div className=''>
                  
                  <h5>{company?.company}</h5>
                  <p className="fs--1">{stripeSubscriptionDetailsData && moment(Date(stripeSubscriptionDetailsData.created)).format('MMMM Do YYYY, h:mm:ss a')}</p>
                </div>}
              </div>
              {stripeSubscriptionDetailsData?.status && <div><strong className="me-2">Status: </strong>
                <div className={`badge rounded-pill badge-soft-${stripeSubscriptionDetailsData?.status === 'succeeded' ? 'success' : 'pending'} fs--2`}>{stripeSubscriptionDetailsData.status}<span className="fas fa-check ms-1" data-fa-transform="shrink-2"></span></div>
              </div>}
            </div>
          </div>

          <div className="card mb-3">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col">
                <h5 className="mb-0">Company</h5>
              </div>
              <div className="col-auto"><button disabled={company && company.hasOwnProperty('stripe') ? true : false || true} data-bs-toggle="modal" data-bs-target="#company-unsubscribe-modal" className="btn btn-falcon-danger btn-sm" href="#!"><span className="fas fa-trash fs--2 me-1"></span>Delete</button></div>
            </div>
          </div>

            {Object.keys(stripeSubscriptionDetailsData).length > 0 && (<div className="card-body">
              <div className="row">
                <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <h5 className="mb-3 fs-0">{company.last_name}</h5>
                  <h6 className="mb-2">{company.last_name}</h6>
                  <p className="mb-1 fs--1">plan : {stripeSubscriptionDetailsData && `${stripeSubscriptionDetailsData.plan.amount/100} ${stripeSubscriptionDetailsData.currency} / ${stripeSubscriptionDetailsData.plan.interval === 'month' && stripeSubscriptionDetailsData.plan.interval >= 1 ? 'Quarterly': stripeSubscriptionDetailsData.plan.interval}`}</p>
                  <p className="mb-0 fs--1"> <strong>Email: </strong><a href={`mailto:${company?.email}`}>{company?.email}</a></p>
                  <p className="mb-0 fs--1"> <strong>Phone: </strong><a href="tel:7897987987">{company?.phoneNumber}</a></p>
                </div>
                <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <h5 className="mb-3 fs-0">Current period</h5>
                  <h6 className="mb-0 fs--1 mb-2">Start : {moment(Date(stripeSubscriptionDetailsData.current_period_start)).format('MMMM Do YYYY, h:mm:ss a') }</h6>
                  <h6 className="mb-0 fs--1  mb-2">End : {moment(Date(stripeSubscriptionDetailsData.current_period_end)).format('MMMM Do YYYY, h:mm:ss a') }</h6>
                  
                </div>
                <div className="col-md-6 col-lg-4">
                  <h5 className="mb-3 fs-0">Payment Method</h5>
                  <div className="d-flex"><img className="me-3" src={`/assets/img/icons/${stripeSubscriptionDetailsData.payment_method?.card?.brand || 'visa'}.png`} width="40" height="30" alt="" />
                    <div className="flex-1">
                      <h6 className="mb-0">{company.last_name}</h6>
                      <p className="mb-0 fs--1">**** **** **** {stripeSubscriptionDetailsData.payment_method && stripeSubscriptionDetailsData.payment_method?.card?.last4}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>)}
            
            <div className="card-footer d-flex justify-content-between align-items-center"><small>
              {numberOfModules}
                    <span className="d-none d-sm-inline-block"> </span> ModuleLines</small>

            
              <div>
                <button  data-bs-toggle="modal" data-bs-target="#user-product-details-modal-frontend-process"  className="btn btn-falcon-default btn-sm ms-1 ms-sm-2" type="button"><span className="fas fa-hdd me-2"></span>
                  FrontEnd Process
                </button>
                <button  data-bs-toggle="modal" data-bs-target="#user-product-details-modal-backend-process" className="btn btn-falcon-default btn-sm ms-1 ms-sm-2" type="button"><span className="fas fa-coins me-2"></span>
                  BackEnd Process
                </button>
              </div>
            </div>          

          </div>
          
           <div className='row g-3 mb-3'>
           {/* style={{justifyContent: 'center', alignItems: 'cener'}}> */}
              
              {company?.modules && company?.modules.map((module)=>{
                return module.submodules.map((submodule, i)=>{
                  return (      
                      <div key={i} data-bs-toggle="modal" data-bs-target="#submodules-details-modal" onClick={()=>handleOnClickModal(submodule)} className="col-sm-6 col-md-4">
                        <div className="btn card" style={{backgroundImage:'url(/assets/img/icons/spot-illustrations/corner-4.png)'}}>
                          {/* <!--/.bg-holder--> */}
                          <div className="card-body position-relative">
                            <h5>{submodule.name}</h5>
                            <p className="fs--1">{moment(Date(stripeSubscriptionDetailsData.current_period_start)).format('MMMM Do YYYY, h:mm:ss a')}</p>
                              {/* <div><strong className="me-2">Status: </strong>
                                
                              </div> */}
                          </div>
                        </div>
                      </div>
                  )
                })
              })}
          </div>

          {submoduleDetails && 
          <>
          <div className="modal fade" id="submodules-details-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth: '500px'}}>
              <div className="modal-content position-relative">
                <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                  <button className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body p-0">
                  
                  {message && message.code ? 
                    message.code  == "200" ? (
                    <div className="alert alert-success mb-0 p-1" role="alert">
                      {message.message}
                    </div>
                    ) : (
                      <div className="alert alert-danger mb-0 p-1" role="alert">
                      {message.message}
                      </div>
                    )          
                  :null}
                  <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h4 className="mb-1" id="submodules-details-modal-label">{submoduleDetails.name}</h4>
                  </div>
                  <div className="p-4 pb-0">
                    
                  <table className="table table-borderless fs--1 mb-0">
                      <tbody>
                        <tr className="border-bottom">
                          <th className="ps-0 pt-0 p-1">{'Submodule Name :'}<div className="text-400 fs-xx-small fs--2">{''}</div>
                          </th>
                          <th className="pe-0 text-end pt-0">{submoduleDetails.name}</th>
                        </tr> 
                        <tr className="border-bottom">
                          <th className="ps-0 py-2 p-1">{'Last payment :'}<div className="text-400 fs-xx-small fs--2">{''}</div> 
                          </th>
                          <th className="pe-0 text-end pt-0">{company?.confirmData &&  moment(new Date(company?.confirmData.current_period_end*1000)).format("MMM Do YY")}</th>
                        </tr> 
                        <tr className="border-bottom">
                          <th className="ps-0 py-2 p-1">{'next paymeny :'}<div className="text-400 fs-xx-small fs--2">{''}</div>
                          </th>
                          <th className="pe-0 text-end pt-0">{company?.confirmData && moment(new Date(company?.confirmData.current_period_start*1000)).format("MMM Do YY")  }</th>
                        </tr> 
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-secondary" id="close-create-company-modal"  type="button" data-bs-dismiss="modal">Close</button>
                  <button className="btn btn-danger" onClick={()=>handleUnsubscribe(submoduleDetails)}  form="login-form" type="submit">Unsubscribe<ButtonLoader show={loading}/></button>
                </div>
              </div>
            </div>
          </div>
          {/* // Compony unSubscribe */}
          <div className="modal fade" id="company-unsubscribe-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth: '500px'}}>
              <div className="modal-content position-relative">
                <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                  <button className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body p-0">
                  
                  {message && message.code ? 
                    message.code  === "200" ? (
                    <div className="alert alert-success mb-1" role="alert">
                      {message.message}
                    </div>
                    ) : (
                      <div className="alert alert-danger mb-1" role="alert">
                      {message.message}
                      </div>
                    )          
                  :null}
                  <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h4 className="mb-1" id="submodules-details-modal-label">{company.name}</h4>
                  </div>
                  <div className="p-4 pb-0">
                    
                  <table className="table table-borderless fs--1 mb-0">
                      <tbody>
                        <tr className="border-bottom">
                          <th className="ps-0 pb-2 p-1">
                            Are you sure you want to delete <strong>{company.name}</strong> ? <br/><br/>
                            The company  will be removed, and you will unsubscribe to all its product.
                          </th>
                          {/* <th className="pe-0 text-end pt-0">{submoduleDetails.name}</th> */}
                        </tr> 
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-secondary" id="close-create-company-modal"  type="button" data-bs-dismiss="modal">Close</button>
                  <button className="btn btn-danger" onClick={()=>HandleDeleteCompany()}  form="login-form" type="submit">Unsubscribe<ButtonLoader show={loading}/></button>
                </div>
              </div>
            </div>
          </div>
          <FontProcessModal subscription={stripeSubscriptionDetailsData} email={company?.email}/>
          <BackendProcessModal subscription={stripeSubscriptionDetailsData} email={company?.email} company={company}/>
              
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>{`Delete Prospects `} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p>Are you sure you want to delete this prospect ?</p>
          </Modal.Body>
            <div className="modal-footer">
              <button className="btn btn-secondary" id="close-delete-user-product-modal"  type="button" data-bs-dismiss="modal">Close</button>
              <button className="btn btn-danger" type="submit">Delete <ButtonLoader show={loading}/></button>
            </div>
        </Modal>

          </>
          }
    </>
  )
}

const mapStateToProps = (state) => {
  // console.log('state', state)
  return {
    userList: state.user.userList,
    roleList: state.role.roleList,
    user_permissions: state.permission.user_permissions
  }
}
const mapDispatchToProps = dispatch =>{
  return {
      getPermissionByRoleId:(role_id)=> dispatch(getPermissionByRoleId(role_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProductDetails)
