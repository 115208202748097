import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { updateUserSuccess,  } from '../../redux/actions/userCrudAction';
import { BACKEND_DOMAIN, API, env_config } from '../../env';
           

const AssignRoleForm = (props) => {
    const [message, setMessage] = useState(false)
    const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('profile')))
    useEffect(() => {
      setUserDetails(JSON.parse(localStorage.getItem('profile')));
      console.log('props.updateUser', props.updateUser)
    }, [])
    
    const formik = useFormik({
        initialValues: {
            first_name: props.updateUser?.first_name ? props.updateUser?.first_name : "",
            last_name: props.updateUser?.last_name ? props.updateUser?.last_name : "",
            email: props.updateUser?.email ? props.updateUser?.email : "",
            phoneNumber: props.updateUser?.phoneNumber ? props.updateUser?.phoneNumber : "",
            department: props.updateUser?.department ? props.updateUser?.department : "",
            company: props.updateUser?.company ? props.updateUser?.company : "",
            role_id: props.updateUser?.role_id ? props.updateUser?.role_id : "",
        },
        validationSchema: Yup.object({
    
            first_name: Yup.string()
            .max(30, "Must be 30 characters or less")
            .required("Required"),
            last_name: Yup.string()
            .max(30, "Must be 30 characters or less")
            .required("Required"),
          email: Yup.string().email("Invalid email address").required("Required"),
          phoneNumber: Yup.number()
            .typeError("That doesn't look like a phone number")
            .positive("A phone number can't start with a minus")
            .integer("A phone number can't include a decimal point")
            .required("A phone number is required"),
            role_id: Yup.string().required("Required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log('values', values)
          try {

            console.log('values', props.updateUser)
            values.updatedBy = userDetails?.result
            values.is_staff = true
            await API
            .put(`/users/${props.updateUser._id}`, {
                ...values
            }, env_config)
            .then((res) => {
                const { data } = res
                props.updateUserSuccess(data)
                setMessage({code: res.status, message: "User successfully updated"}); 
                resetForm({ values: "" }) 
            }).catch((err)=>{
                // console.error("-- err", err.response);
                setMessage({code: err.response?.status || 500, message: err.response ? err.response.data : err})
            }).finally(()=> {setTimeout(() => {
                // Most recent value
                setMessage(false);        
                props.handleClose()                
            }, 3000);
            resetForm({ values: "" });})

          } catch (error) {
            // console.error("-- error", error);
            setMessage({code: 404, message: error})
          } finally {
          }
        },
      });

  return (      
    <form 
        id="assign-user-role-form" 
        onSubmit={formik.handleSubmit}
        method="post" action=""
        className="row g-3">
        <div className='row'>
            {message && message.code ? 
                message.code.toString().substr(0,2)  === "20" ? (   
                <div className="alert alert-success" role="alert">
                    {message.message}
                </div>
                ) : (
                    <div className="alert alert-danger" role="alert">
                    {JSON.stringify(message?.message?.message)}
                    </div>
                )          
            :null}
            <div className="my-1 col-6">
                <label className="col-form-label" htmlFor="first-name">First Name:</label>
                
                <input
                    disabled
                    className="form-control"
                    id="first-name"
                    name="first_name"
                    type="text"
                    placeholder="Prénom"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.first_name }
                />
                {formik.touched.first_name && formik.errors.first_name ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.first_name}</div>
                ) : null}

            </div>
            
            <div className="my-1 col-6">
                <label className="col-form-label" htmlFor="last-name">Last Name:</label>
                
                <input
                    disabled
                    className="form-control"
                    id="last-name"
                    name="last_name"
                    type="text"
                    placeholder="Nom"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.last_name }
                />
                {formik.touched.last_name && formik.errors.last_name ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.last_name}</div>
                ) : null}

            </div>

            <div className="my-1 col-6">
                <label className="col-form-label" htmlFor="email">Email:</label>
                
                <input
                    disabled
                    className="form-control"
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email }
                />
                {formik.touched.email && formik.errors.email ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.email}</div>
                ) : null}

            </div>
            
            <div className="my-1 col-6">
                <label className="col-form-label" htmlFor="phone-number">Number:</label>
                
                <input
                    disabled
                    className="form-control"
                    id="phone-number"
                    name="phoneNumber"
                    type="tel"
                    placeholder="Numéro de téléphone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber }
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.phoneNumber}</div>
                ) : null}

            </div>

            <div className="my-1 col-12">
                <label className="col-form-label" htmlFor="department">Department:</label>
                
                <input
                    disabled
                    className="form-control"
                    id="department"
                    name="department"
                    type="text"
                    placeholder="Department"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.department }
                />
                {formik.touched.department && formik.errors.department ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.department}</div>
                ) : null}

            </div>

            
            <div className="my-1 col-12">
                <label className="col-form-label" htmlFor="company">Company:</label>
                
                <input
                disabled
                    className="form-control"
                    id="company"
                    name="company"
                    type="text"
                    placeholder="Entreprise"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.company }
                />
                {formik.touched.company && formik.errors.company ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.company}</div>
                ) : null}

            </div>
            
            <div className="my-1 col-12">
                <label className="col-form-label" htmlFor="role_id">Role:</label>
                
                <select className="form-select" defaultValue={props.updateUser?.role_id } id="role_id" name="role_id" values={formik.values.role_id } onChange={formik.handleChange} >
                    {props.roleList.map((_role, i) =>(
                        <option key={i} value={_role._id}>{_role.name}</option>
                    ))}
                </select>
                {formik.touched.role_id && formik.errors.role_id ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.role_id}</div>
                ) : null}

            </div>
            
        </div>
  </form>
  )
}

const mapStateToProps = (state) => {
    return {
    }
  }

  const mapDispatchToProps = dispatch =>{
    return {
      updateUserSuccess:(user)=>dispatch(updateUserSuccess(user)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(AssignRoleForm)