import * as actionType from '../constants/actionTypes';

const moduleLineReducer = (state = { moduleLineList: [], subscribedModulelineList: [], subscribedUserCompany:{}  }, action) => {
  switch (action.type) {

    case actionType.INITIATE_MODULE_LINE_REQUEST:
      return { ...state, loading: true };
    case actionType.FETCH_MODULELINES:
      return {...state, moduleLineList: action.payload}
    case actionType.CREATE_MODULELINES || actionType.CREATE_MODULELINE:
      return {
        ...state,
        loading: false,
        moduleLineList: [...state.moduleLineList, action.payload],
        error: '',
      };    
      case actionType.UPDATE_MODULELINE:
        console.log('-- reducer UPDATE_MODULELINE:>>', action.payload)
        return {
          ...state,
          moduleLineList: [...state.moduleLineList.map((moduleline) => {
            if (moduleline._id !== action.payload._id) {
              return moduleline
            }
            console.log('in condition true', moduleline)
            return action.payload
          })]
        }
      case actionType.INITIATE_MODULE_LINE_FAILURE: 
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
        case actionType.DELETE_MODULELINE:
          return {
            ...state,
            moduleLineList: [...state.moduleLineList.filter((moduleline) => moduleline._id != action.payload)]
          }
        case actionType.GET_MODULELINE_BY_COMPANY_ID:
          let array = [];
          
          Object.keys(action.payload.modules).forEach(first_key=> {
            
              action.payload.modules && Object.keys(action.payload.modules[`${first_key}`]).forEach(curr=>{
                array.push(action.payload.modules[`${first_key}`][`${curr}`])
              })
                        
          })

          return {
            ...state,
            loading: false,
            subscribedModulelineList: array,
            subscribedUserCompany: action.payload
          }
          case actionType.ACTIVATE_MODULEINE_IN_COMPANY:
            console.log('-- reducer ACTIVATE_MODULEINE_IN_COMPANY action:>>', action)
            return {
              ...state,
              subscribedModulelineList: [...state.subscribedModulelineList.map((moduleline) => {
                if (moduleline._id !== action.moduleline._id) {
                  return moduleline
                }
                console.log('in condition true', moduleline)
                return action.moduleline
              })]
            }

      default:
        return state;
  }
};

export default moduleLineReducer;