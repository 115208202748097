import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { env_config, API } from '../../env';
    
    const config = env_config; 

const PricingForm = (props) => {
  const [message, setMessage] = useState(false)
  const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem('profile')))

  useEffect(() => {
    console.log('props', props)
  }, [])
  const formik = useFormik({
    initialValues: {
      number: props.updatePricing?.number ? props.updatePricing.number?.value : '' ,
      number_reduction: props.updatePricing?.number? props.updatePricing?.number.promotion : 0 ,

      monthly_price: props.updatePricing.monthly ? props.updatePricing.monthly.value : '' ,
      monthly_price_reduction: props.updatePricing.monthly ? props.updatePricing?.monthly.promotion : 0 ,

      quarterly_price: props.updatePricing.quarterly ? props.updatePricing.quarterly.value : '' ,
      quarterly_price_reduction: props.updatePricing.quarterly ? props.updatePricing?.quarterly.promotion : 0 ,

      yearly_price: props.updatePricing.yearly ? props.updatePricing.yearly.value : '' ,
      yearly_price_reduction: props.updatePricing.yearly ? props.updatePricing?.yearly.promotion : 0 ,
    },
    validationSchema: Yup.object({
      number: Yup.number()
        .required("Required"),
      monthly_price: Yup.number().required("Required"),
      quarterly_price: Yup.number().required("Required"),
      yearly_price: Yup.number().required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
    let _params = {
        ...props.updatePricing,
          "number": {
            "value": values.number,
            "promotion": values.number_reduction
          },
          "monthly": {
            "value": values.monthly_price,
            "promotion": values.monthly_price_reduction
          },
          "quarterly": {
            "value": values.quarterly_price,
            "promotion": values.quarterly_price_reduction
          },
          "yearly": {
            "value": values.yearly_price,
            "promotion": values.yearly_price_reduction
          }
    }
    // return
      // console.log('_params', _params)
           
      try {

        {!props.isUpdate ? (async ()=> {                    
          _params.createdBy = userDetails?.result;
          console.log('_params', _params)
          await API
          .post("/pricing/v1", {
              ..._params
        }, config)
          .then((res) => {
            const { data } = res
            // console.log('in PricingsForm responser, res', res)
            props.createPricingSuccess(data)
            setMessage({code: res.status, message: "Pricing successfully created"});                
            // props.setCompanies((prev)=>[...prev, data])
            resetForm({ values: "" }) 
            setTimeout(() => {
              props.handleClose()
              }, 2000);

          }).catch((err)=>{
            // console.error("-- err", err.response);
            setMessage({code: err.response?.status || 500, message: err.response ? err.response.data : err})                

          }).finally(()=> {setTimeout(() => {
            // Most recent value
            setMessage(false);                   
          }, 5000);
          resetForm({ values: "" });});})() : 
          // Update logic
          (async ()=> {
            // console.log('-- update values', values)          
            _params.updatedBy = userDetails?.result;
            _params.updated_at = new Date();
            await API
              .put(`/pricing/v1/${props.updatePricing.number?.value}`, {
                ..._params
              }, config)
                .then(res=>{
                  const { data } = res
                  console.log('in PricingsForm responser, res', res)
                  props.updatePricingSuccess({number_of_user: props.updatePricing.number?.value, updated_pricing: data})
                  setMessage({code: res.status, message: "Pricing successfully updated"});                
                  // props.setCompanies((prev)=>[...prev, data])
                  resetForm({ values: "" }) 
                  setTimeout(() => {
                      props.handleClose()
                      localStorage.removeItem('pricing_item')
                      props.setIsUpdate(false)
                    }, 2000);
                })
          })()
        }

      } catch (error) {
        // console.error("-- error", error);
        setMessage({code: 404, message: error})
      } finally {
      }
    },
  });

  return (  
    <form 
        id="pricing-form" 
        onSubmit={formik.handleSubmit}
        method="post" action=""
        className="row g-3" style={{marginRight: '-2rem'}}>
        <div className='row'>
            {message && message.code ? 
                message.code.toString().substr(0,2)  === "20" ? (   
                <div className="alert alert-success" role="alert">
                    {message.message}
                </div>
                ) : (
                    <div className="alert alert-danger" role="alert">
                    {JSON.stringify(message?.message?.message)}
                    </div>
                )          
            :null}
            <div className="my-1 col-7">
                <label className="col-form-label" htmlFor="pricing-number">Nbr of User(s):</label>                
                <input
                    className="form-control"
                    id="pricing-number"
                    name="number"
                    type="text"
                    placeholder="Number of User(s)"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.number }
                />
                {formik.touched.number && formik.errors.number ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.number}</div>
                ) : null}            
            </div>
            
            <div className="my-1 col-5">
                <label className="col-form-label" htmlFor="pricing-number_reduction">Reduction:</label>                
                <input
                    className="form-control"
                    id="pricing-number_reduction"
                    name="number_reduction"
                    type="text"
                    placeholder="Number of User(s)"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.number_reduction }
                />
                {formik.touched.number_reduction && formik.errors.number_reduction ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.number_reduction}</div>
                ) : null}            
            </div>

            
            <div className="my-1 col-7">
                <label className="col-form-label" htmlFor="pricing-monthly_price">Monthly Price:</label>                
                <input
                    className="form-control"
                    id="pricing-monthly_price"
                    name="monthly_price"
                    type="text"
                    placeholder="Monthly Price"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.monthly_price }
                />
                {formik.touched.monthly_price && formik.errors.monthly_price ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.monthly_price}</div>
                ) : null}            
            </div>
            
            <div className="my-1 col-5">
                <label className="col-form-label" htmlFor="pricing-monthly_price_reduction">Reduction:</label>                
                <input
                    className="form-control"
                    id="pricing-monthly_price_reduction"
                    name="monthly_price_reduction"
                    type="text"
                    placeholder="Monthly Price Reduction"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.monthly_price_reduction }
                />
                {formik.touched.monthly_price_reduction && formik.errors.monthly_price_reduction ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.monthly_price_reduction}</div>
                ) : null}            
            </div>

            <div className="my-1 col-7">
                <label className="col-form-label" htmlFor="pricing-quarterly_price">Quarterly Price:</label>                
                <input
                    className="form-control"
                    id="pricing-quarterly_price"
                    name="quarterly_price"
                    type="text"
                    placeholder="Quarterly Price"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.quarterly_price }
                />
                {formik.touched.quarterly_price && formik.errors.quarterly_price ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.quarterly_price}</div>
                ) : null}            
            </div>
            
            <div className="my-1 col-5">
                <label className="col-form-label" htmlFor="pricing-quarterly_price_reduction">Reduction:</label>                
                <input
                    className="form-control"
                    id="pricing-quarterly_price_reduction"
                    name="quarterly_price_reduction"
                    type="text"
                    placeholder="Monthly Price Reduction"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.quarterly_price_reduction }
                />
                {formik.touched.quarterly_price_reduction && formik.errors.quarterly_price_reduction ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.quarterly_price_reduction}</div>
                ) : null}            
            </div>
            
            <div className="my-1 col-7">
                <label className="col-form-label" htmlFor="pricing-yearly_price">Quarterly Price:</label>                
                <input
                    className="form-control"
                    id="pricing-yearly_price"
                    name="yearly_price"
                    type="text"
                    placeholder="Quarterly Price"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.yearly_price }
                />
                {formik.touched.yearly_price && formik.errors.yearly_price ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.yearly_price}</div>
                ) : null}            
            </div>
            
            <div className="my-1 col-5">
                <label className="col-form-label" htmlFor="pricing-yearly_price_reduction">Reduction:</label>                
                <input
                    className="form-control"
                    id="pricing-yearly_price_reduction"
                    name="yearly_price_reduction"
                    type="text"
                    placeholder="Monthly Price Reduction"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.yearly_price_reduction }
                />
                {formik.touched.yearly_price_reduction && formik.errors.yearly_price_reduction ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.yearly_price_reduction}</div>
                ) : null}            
            </div>

        </div>
        
    </form>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PricingForm)