
import {SET_CHECKED_STATE, SET_PAYMENT_CYCLE, SET_NUMBER_OF_USERS} from '../constants/marketPlaceActionType'

export const setCheckedState = (value)=> {
    return { type: SET_CHECKED_STATE, payload: value };
}   

export const setPaymentCycle = (value)=> {
    localStorage.setItem('paymentCycle',value)
    return { type: SET_PAYMENT_CYCLE, payload: value };
}   

export const setNumberOfusers = (value)=> {
    localStorage.setItem('numberOfusers',value)
    return { type: SET_NUMBER_OF_USERS, payload: value };
}   