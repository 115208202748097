import * as actionType from '../constants/actionTypes';

const initialState = {
    userList: [],
    connectedUser: {}
}

const userReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionType.EDIT_CONNECTED_USER:
      return {
        ...state,
        connectedUser: payload
      }
  case actionType.FETCH_USER_CRUD:
    return { 
        ...state, 
        userList: payload
     }
     case actionType.CREATE_USER_CRUD:
       return {
         ...state,
         loading: false,
         userList: [...state.userList, payload],
         error: '',
       };    

     case actionType.UPDATE_USER_CRUD:
      console.log('-- reducer UPDATE_USER_CRUD:>>', payload)
      return {
        ...state,
        userList: [...state.userList.map((user) => {
          if (user._id !== payload._id) {
            return user
          }
          console.log('in condition true', payload)
          return payload
        })]
      }
      case actionType.DELETE_USER_CRUD:
        return {
          ...state,
          userList: [...state.userList.filter((user) => user._id != payload)]
        }
      case actionType.RESET_PASSWORD:
        console.log('-- reducer RESET_PASSWORD:>>', payload)
        return {
          ...state,
          userList: [...state.userList.map((user) => {
            if (user._id !== payload?._doc?._id) {
              return user
            }
            console.log('in condition true', payload._doc)
            return payload._doc
          })]
        }
  default:
    return state
  }
}

export default userReducers