import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { HeadMenu, SideMenu } from '../../components'


const UserAdminLayout = (props) => {
  const [user, setfirst] = useState(null)
  useEffect(() => {
    const tmp_user = JSON.parse(localStorage.getItem('profile'));
    setfirst(tmp_user)
    // console.log('In UserAdminLayout', props)
  }, [])
  
  return (    
    user === null ?(
      React.cloneElement(props.children)
    ) :(
      <main className="main" id="top">
        <div className='container' data-layout="container">          
          <SideMenu permissions={props.permissions} />
          <div className="content">
            <HeadMenu user={props.props}/>
            {React.cloneElement(props.children)}
          </div>
        </div>
      </main>
    )    
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(UserAdminLayout)