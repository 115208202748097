import { sendPasswordRecoveryMailAPi, signIn, signUp } from '../../api';
import { AUTH, EDIT_CONNECTED_USER } from '../constants/actionTypes';

export const signin = (formData, router) => async (dispatch) => {
  try {
    const { data } = await signIn(formData);

    dispatch({ type: AUTH, data });
    dispatch({ type: EDIT_CONNECTED_USER, data });
    

    return data
  } catch (error) {
    console.log(error);
    return error
  }
};

export const signup = (formData, router) => async (dispatch) => {
  console.log('in aciton signup formData', formData)
  console.log('router', router)
  try {
    const { data } = await signUp(formData);

    dispatch({ type: AUTH, data });
    dispatch({ type: EDIT_CONNECTED_USER, data });

    router.push('/dashboard');
    return data
  } catch (error) {
    console.log(error);
    return {code: error.code, message: error.message}
  }
};


export const sendPasswordRecoveryMail = (formData, router) => async (dispatch) => {
  console.log('in aciton sendPasswordRecoveryMail formData', formData)
  console.log('router', router)
  try {
    const { data } = await sendPasswordRecoveryMailAPi(formData);

    dispatch({ type: "SEND_PASSWORD_RECOVERY_MAIL", data });

    router.push(`/confirm-mail?email=${formData.email}`);
    return data
  } catch (error) {
    console.log(error);
    return {code: error.code, message: error.message}
  }
};

