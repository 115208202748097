import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {Modal} from 'react-bootstrap'
import axios from 'axios'
import { deleteRoleSuccess, fetchRoles } from '../../redux/actions/roleActions';
import { RoleForm } from '../../components';
import { Link } from 'react-router-dom'
import { env_config, API } from '../../env';
import { getPermissionByRoleId } from '../../redux/actions/permissionActions';
        
const config = env_config;  

export const RoleAdmin = (props) => {
    const [message, setMessage] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isUpdate, setIsUpdate] = useState(false)
    const [updateRole, setUpdateRole] = useState({})  

    
    useEffect(() => {
      (async ()=>{
        let fetchedRoles = await props.fetchRoles();
        let _user = JSON.parse(localStorage.getItem('profile'));
        await props.getPermissionByRoleId(_user.result.role_id);
        console.log('fetchedRoles', fetchedRoles)
      })()
    }, [])
    
    const handleAdd = () =>{
        handleShow()
        setIsUpdate(false)
    }
    const handleEdit = (role) =>{
        handleShow()
        setUpdateRole(role)
        setIsUpdate(true)
    }
    
  
  const handleChangeActive = (e, role)=> {
    e.preventDefault()
    let _checked = e.target.checked;
    role.is_active = _checked;
    let _user = JSON.parse(localStorage.getItem('profile'));
    console.log('_user:>>', _user.result);
    role.updatedBy = _user.result;
    (async ()=> {
      // console.log('-- update values', values)
      await API
        .put(`/roles/${role._id}`, {
          ...role
        }, config)
          .then(res=>{
            const { data } = res
            console.log('in handleChangeActive responser, res', res)
            props.updateRole(data)
            setMessage({code: res.status, message: "role successfully updated"});
            setTimeout(() => {
                // document.querySelector('#close-create-role-modal').click();
                localStorage.removeItem('user_item')
              }, 2000);
          })
    })()      
  }
  
  const handleDelete = async(id) =>{    
    console.log('id', id)  
    try {
      await API
        .delete(`/roles/${id}`, {}, config)
          .then(res=>{
            const { data } = res
            console.log('in deleteForm responser, res', res)
            props.deleteRoleSuccess(id)
            setMessage({code: res.status, message: "role successfully deleted"});
            setTimeout(() => {
              setMessage(false)
              }, 4000);
          })
    } catch (error) {
      
    }
  }

  return (
    <>
        
        {message && message.code ? 
            message.code.toString().substr(0,2)  === "20" ? (   
            <div className="alert alert-success" role="alert">
                {message.message}
            </div>
            ) : (
                <div className="alert alert-danger" role="alert">
                {JSON.stringify(message?.message?.message)}
                </div>
            )          
        :null}
        <div className="d-flex mb-4 mt-4"><span className="fa-stack me-2 ms-n1"><i className="fa-inverse fa-stack-1x text-primary fas fa-list" data-fa-transform="shrink-2"></i></span>
            <div className="col">
        {/* <h6>{JSON.stringify(props.user_permissions && props.user_permissions.user_role)}</h6> */}
              <h5 className="mb-0 text-primary position-relative"><span className="bg-200 dark__bg-1100 pe-3">User's Role List</span><span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span></h5>
              <p className="mb-0">We offer specialized services to large companies that need to sell their products online, with powerful tools and analytics for informed decisions, secure payment gateways, and 24/7 customer support.ts.</p>
            </div>
          </div>
          
          
          <div className="card z-index-1 mb-3" id="recentPurchaseTable" data-list='{"valueNames":["name","createdBy","product","payment","amount"],"page":8,"pagination":true}'>
            <div className="card-header">
              <div className="row flex-between-center">
                <div className="col-6 col-sm-auto d-flex align-items-center pe-0">
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Roles </h5>
                </div>
                <div className="col-6 col-sm-auto ms-auto text-end ps-0">
                  <div className="d-none" id="table-roles-actions">
                    <div className="d-flex"><select defaultValue="bulk" className="form-select form-select-sm" aria-label="Bulk actions">
                        <option value="bulk">Bulk actions</option>
                        <option value="Refund">Refund</option>
                        <option value="Delete">Delete</option>
                        <option value="Archive">Archive</option>
                      </select><button className="btn btn-falcon-default btn-sm ms-2" type="button">Apply</button></div>
                  </div>
                  {/* data-bs-toggle="modal" data-bs-target="#role-modal" */}
                  <div id="table-roles-replace-element">
                    
                  {props.user_permissions && props.user_permissions.user_role.can_create ? (
                    <button onClick={()=>handleAdd()} className="btn btn-falcon-default btn-sm" type="button"><span className="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span className="d-none d-sm-inline-block ms-1">New</span></button>
                  ):null}
                    </div>
                </div>
              </div>
            </div>
            <div className="card-body px-0 py-0">
              <div className="table-responsive scrollbar">
                {props.user_permissions && props.user_permissions.user_role.can_view ? (
              <table className="table table-sm fs--1 mb-0 overflow-hidden">
                  <thead className="bg-200 text-900">
                    <tr>
                      <th className="white-space-nowrap">
                        <div className="form-check mb-0 d-flex align-items-center"><input className="form-check-input" id="checkbox-bulk-roles-select" type="checkbox" data-bulk-select='{"body":"table-purchase-body","actions":"table-roles-actions","replacedElement":"table-roles-replace-element"}' /></div>
                      </th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="name">Name</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="date">Date</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="created_by">created By</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="modified_by">Modify By</th>
                      <th className="no-sort pe-1 align-middle data-table-row-action"></th>
                    </tr>
                  </thead>
                   <tbody className="list" id="table-purchase-body">
                    {props.roleList.filter(x=>x._id !== JSON.parse(localStorage.getItem('profile'))?.result?.role_id).map((role, i)=>(
                      <tr key={i} className="btn-reveal-trigger">
                        <td className="align-middle" style={{width: "28px"}}>
                          <div className="form-check mb-0"><input className="form-check-input" type="checkbox" id="recent-purchase-0" data-bulk-select-row="data-bulk-select-row" /></div>
                        </td>

                        <td className="white-space-nowrap name"><strong><Link to={`/user_roles/${role._id}`}>{role.name}</Link></strong></td>
                        <td className="white-space-nowrap created_at"><strong>{role.created_at}</strong></td>
                        
                        {/* // common field action */}
                        <td className="white-space-nowrap created_by">{role.createdBy?.last_name || 'n/a'}</td>
                        <td className="white-space-nowrap modified_by">{role.updatedBy?.last_name || 'n/a'}</td>
                        <td className="white-space-nowrap text-end">
                          <div className="dropstart font-sans-serif position-static d-inline-block"><button className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal float-end" type="button" id="dropdown0" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span className="fas fa-ellipsis-h fs--1"></span></button>
                            <div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="dropdown0">
                              {/* <a className="dropdown-item" href="#!">View</a> */}
                            {props.user_permissions.user_role.can_edit ? (
                            <a className="dropdown-item" href="#edit" onClick={()=>handleEdit(role)}>Edit</a>
                            ):null}
                            {/* <a className="dropdown-item" href="#!">Refund</a> */}
                              {/* <div className="dropdown-divider"></div><a className="dropdown-item text-warning" href="#!">Archive</a> */}
                              {props.user_permissions.user_role.can_delete ? (
                              <a className="dropdown-item text-danger" href="#delete" onClick={()=>handleDelete(role._id)}>Delete</a>
                              ):null}

                            </div>
                          </div>
                        </td>
                      </tr>    
                    ))}          
                    {props.roleList.length > 0 ? null : <tr></tr>}
                  </tbody>
                </table>
              ): <h6 className='ml-5' style={{marginLeft: '1.7em'}}>{"Your are not allowed to view this"}</h6>}
              </div>
            </div>
            <div className="card-footer">
              <div className="row align-items-center">
                <div className="pagination d-none"></div>
                <div className="col">
                  <p className="mb-0 fs--1"><span className="d-none d-sm-inline-block me-2" data-list-info="data-list-info"> </span><span className="d-none d-sm-inline-block me-2">&mdash;  </span><a className="fw-semi-bold" href="#!" data-list-view="*">View all<span className="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a><a className="fw-semi-bold d-none" href="#!" data-list-view="less">View less<span className="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a></p>
                </div>
                <div className="col-auto d-flex"><button className="btn btn-sm btn-primary" type="button" data-list-pagination="prev"><span>Previous</span></button><button className="btn btn-sm btn-primary px-4 ms-2" type="button" data-list-pagination="next"><span>Next</span></button></div>
              </div>
            </div>
          </div>
          <>            
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{`${isUpdate ? 'Update':'Create'}`} role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
              {isUpdate ? 
                ((Object.keys(updateRole).length > 0 && <RoleForm handleClose={handleClose} updateRole={updateRole} setIsUpdate={setIsUpdate} isUpdate={isUpdate} />))
                :
                  (<RoleForm handleClose={handleClose} setIsUpdate={setIsUpdate} isUpdate={isUpdate} />)
              }  
          </Modal.Body>
            <div className="modal-footer">
              <button className="btn btn-secondary" id="close-create-user-modal"  type="button" data-bs-dismiss="modal">Close</button>
              <button className="btn btn-primary"  form="roles-crud-form" type="submit">Submit </button>
            </div>
        </Modal>
          </>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    roleList: state.role.roleList,
    user_permissions: state.permission.user_permissions,
  }
}

const mapDispatchToProps = dispatch =>{
  return {
      fetchRoles: () => dispatch(fetchRoles()),
      deleteRoleSuccess:(user_id)=>dispatch(deleteRoleSuccess(user_id)),
      getPermissionByRoleId:(role_id)=> dispatch(getPermissionByRoleId(role_id)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleAdmin)