

const erpBackendReducer = (state = { StripeSubscriptionDetails: {},mappedModuleLines: [], companyUserStatus: [], userConnectionTime: []}, action) => {
  switch (action.type) {

    case "GET_MAPPED_MODULELINES":
      return {...state, mappedModuleLines: action.payload}
    case "GET_BACKEND_USER_LIST_STATUS":
      return {...state, companyUserStatus: action.payload}
    case "GET_USER_CONNECTION_TIME_LIST":
      return {...state, userConnectionTime: action.payload}
    case "GET_STRIPE_SUBSCRIPTION_BY_ID":
      return {...state, StripeSubscriptionDetails: action.payload}
    default:
      return state;
  }
};

export default erpBackendReducer;