import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
// import { SideMenu } from '../../components'
import grossRevenueChartInit from '../../js/charts/echarts/gross-revenue'
import linePaymentChartInit from '../../js/charts/echarts/line-payment'
import { erpBackendUserListStatus, getUserConnectionTimeList } from '../../redux/actions/ErpSaaSBackendActions'
import { setCheckedState, setNumberOfusers, setPaymentCycle } from '../../redux/actions/marketPlaceActions'
import { fetchModuleLines } from '../../redux/actions/modulelines'
import { fetchModules } from '../../redux/actions/modules'
import { fetchPricings } from '../../redux/actions/pricingActions'
import { BACKEND_DOMAIN } from '../../env'

const headers = {
  'Content-Type': 'application/json',
};

const Dashboard = (props) => {
  const [navbarStyle, setNavbarStyle] = useState('transparent')
  const [paymentHistory, setPaymentHistory] = useState([])
  const [loading, setLoading] = useState(false)
  const [user_status_data, setuser_status_data] = useState({
    "status": true,
    "data": [
      {
        "id": "6",
        "company": "DGE company",
        "users": [
          {
            "staffid": "6",
            "firstname": "John",
            "lastname": "Doe",
            "email": "john.doe@kelFf.com",
            "active": "1"
          }
        ]
      }
    ]
  })

  const { setNumberOfusers} = props
  const {checkedState, paymentCycle, numberOfusers} =props.marketPlaceData
  
  const computeOrderSummary = (result=false, total=false) => {
    result = result === false ? checkedState : result;
    let computedSubmodule = {}
    let _totatPriceSubmodule = 0
    for (const key in result) {
        // console.log('key', key)
        if (key){
          for (const sub_key in result[key]) {
            // console.log('   - sub_key: ', sub_key)
            if (key && key.length > 10 && sub_key.length > 10) {

              // console.log('   - sub_key: ', key,sub_key)
              if (computedSubmodule[key] === undefined) computedSubmodule[key] = {}
              computedSubmodule[key][sub_key] = {...props.moduleLineList.filter(moduleline=> moduleline._id === sub_key )[0]}
              if(computedSubmodule[key][sub_key]?.price) 
              _totatPriceSubmodule = parseFloat(_totatPriceSubmodule) + parseFloat(computedSubmodule[key][sub_key]?.price[paymentCycle])
            }
          }
        }
        // console.log(`In computeOrderSummary - ${key}: ${JSON.stringify(result[key])}`);
    }
    // console.log('end computedSubmodule', computedSubmodule, _totatPriceSubmodule)
    if (total){
      let tmp_payment_cycle = 1
      switch (paymentCycle) {
        case 'quarterly':
          tmp_payment_cycle = 3
          break;      
        case 'yearly':
          tmp_payment_cycle = 12
          break;     
        default:
          break;
      }
      return (_totatPriceSubmodule - parseFloat(props.price_ranges[numberOfusers]?.number?.promotion))*numberOfusers*tmp_payment_cycle
    }
    // setTotatPriceSubmodule(_totatPriceSubmodule)
    return computedSubmodule
  }
  useEffect(() => {
    const { result: user } = JSON.parse(localStorage.getItem("profile"))  
    if (navbarStyle && navbarStyle !== 'transparent') {
      document.querySelector('.navbar-vertical').classList.add(`navbar-${navbarStyle}`);
    }
    if (localStorage.getItem('company_id')===null){
      document.querySelector('#open-select-company-modal') && document.querySelector('#open-select-company-modal').click();
    }
    const fetchData = async () => {
      const fetched_modules =  await props.fetchModules()
      const fetched_modulelines =  await props.fetchModuleLines()  
      const fetched_pricing =  await props.fetchPricings()
      const payment_histories_res =  await fetch(`${BACKEND_DOMAIN}/payment_history/user_id/${user._id}`, { headers })
      // console.log('--- payment_histories_res', payment_histories_res)
      const payment_histories = await payment_histories_res.json()
      // console.log('--- fetchData payment_histories', payment_histories)
      let id_company = 1
      // await props.erpBackendUserListStatus(id_company, setLoading)
      const user_connection_time_list = {}// await props.getUserConnectionTimeList(id_company, setLoading)
      return {fetched_modules, fetched_modulelines,fetched_pricing, user_connection_time_list, payment_histories};
    }
    fetchData().then(result=>{
      let _initValue = {}
      console.log('--- fetchData', result.payment_histories)
      linePaymentChartInit(result.payment_histories);
      grossRevenueChartInit(result.user_connection_time_list);

      const loadedCheckedState = localStorage.getItem('checkedState');
      if (loadedCheckedState !== null){
        _initValue = loadedCheckedState
      } else {
        new Array(...result.fetched_modules).forEach(_module => {
            _initValue[_module._id] = new Array([...result.fetched_modulelines.filter(moduleline=> moduleline.parent === _module._id )].length).fill(false)
        });
      }

      // console.log('_initValue', _initValue)
      // computeOrderSummary(_initValue) //[I don't know what this does here] 
      try {
        _initValue = _initValue != "[object Object]" && JSON.parse(_initValue)        
      } catch (error) {
        console.log('error', error)
      }
      

      props.setCheckedState(_initValue)
      // console.log('-- In then:>>', result)
      // console.log('-- In then:>>',Object.values(result.fetched_pricing.price_ranges)[0]?.number?.value)
      setNumberOfusers(Object.values(result.fetched_pricing.price_ranges)[0]?.number?.value)
      // let temp_payment_histories = localStorage.getItem('payment_histories') ? JSON.parse(localStorage.getItem('payment_histories')) : [];
      setPaymentHistory(result.payment_histories);
      // console.log('--- temp_payment_histories:>>', temp_payment_histories)
    }).catch(err=> console.error('err', err))
    
    return () => {
    } 
  }, [])
  
  
  return (
        
    <div>
              
        <div className="content">
          
          <div className="row g-3">
            <div className="col-xxl-9">
              <div className="card rounded-3 overflow-hidden h-100">
                <div className="card-body bg-line-chart-gradient d-flex flex-column justify-content-between">
                  <div className="row align-items-center g-0">
                    <div className="col light">
                      <h4 className="text-white mb-0">Today <span id="next_due_date_ph">0</span></h4>
                      <p className="fs--1 fw-semi-bold text-white" id="next_due_date_ph_date">Loading <span className="opacity-50">...</span></p>
                    </div>
                    <div className="col-auto d-none d-sm-block">
                      <select defaultValue={'failed'} className="form-select form-select-sm mb-3" id="dashboard-chart-select">
                        <option value="all">All Payments</option>
                        <option value="successful">Successful Payments</option>
                        <option value="failed">Failed Payments</option>
                      </select>
                    </div>
                  </div>
                  {/* Find the JS file for the following calendar at: src/js/charts/echarts/line-payment.js*/}
                  {/* If you are not using gulp based workflow, you can find the transpiled code at: public/assets/js/theme.js*/}
                  <div className="echart-line-payment" style={{height: 200}} data-echart-responsive="true" />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="row g-3">
                <div className="col-md-6 col-xxl-12">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="row flex-between-center g-0">
                        <div className="col-6 d-lg-block flex-between-center">
                          <h6 className="mb-2 text-900">All Users</h6>
                          <h4 className="fs-3 fw-normal text-700 mb-0" id="count-up-all-users">...</h4>
                        </div>
                        <div className="col-auto h-100">
                          <div style={{height: 50, minWidth: 80}} data-echarts="{&quot;xAxis&quot;:{&quot;show&quot;:false,&quot;boundaryGap&quot;:false},&quot;series&quot;:[{&quot;data&quot;:[3,7,6,8,5,12,11],&quot;type&quot;:&quot;line&quot;,&quot;symbol&quot;:&quot;none&quot;}],&quot;grid&quot;:{&quot;right&quot;:&quot;0px&quot;,&quot;left&quot;:&quot;0px&quot;,&quot;bottom&quot;:&quot;0px&quot;,&quot;top&quot;:&quot;0px&quot;}}" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xxl-12">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="row flex-between-center">
                        <div className="col d-md-flex d-lg-block flex-between-center">
                          <h6 className="mb-md-0 mb-lg-2">All Modules</h6>
                          <span className="badge rounded-pill badge-soft-primary"><a href='/account'>See More <span className="fas fa-caret-up" data-fa-transform="rotate-90"  /></a></span>
                        </div>
                        <div className="col-auto">
                          <h4 className="fs-3 fw-normal text-700" id="number-of-all-submodules">0</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-4 col-xxl-12">
                  <div className="card h-100">
                    <div className="card-body">
                      <div className="row flex-between-center">
                        <div className="col d-md-flex d-lg-block flex-between-center">
                          <h6 className="mb-md-0 mb-lg-2">Conversion</h6><span className="badge rounded-pill badge-soft-primary"><span className="fas fa-caret-up" /> 29.4%</span>
                        </div>
                        <div className="col-auto">
                          <h4 className="fs-3 fw-normal text-primary" data-countup="{&quot;endValue&quot;:28.5,&quot;decimalPlaces&quot;:2,&quot;suffix&quot;:&quot;%&quot;}">0</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3">
            <div className="col-xxl-9">
              <div className="card bg-light my-3">
                {/* <div className="card-body p-3">
                  <p className="fs--1 mb-0"><a href="#!"><span className="fas fa-exchange-alt me-2" data-fa-transform="rotate-90" /><span id="next_due_date_ph_date_diff">...</span></a>. Your next deposit is expected on <strong><span id="next_due_date_ph_date_2">...</span>.</strong></p>
                </div> */}
              </div>
              <div className="row g-3">
                <div className="col-12">

                  
              <div className="card overflow-hidden">
                <div className="card-header d-flex flex-between-center bg-light py-2">
                  <h6 className="mb-0">Transaction Summary</h6>
                </div>
                <div className="card-body py-0">
                  <div className="table-responsive scrollbar">
                    <table className="table table-dashboard mb-0 fs--1">
                      <tbody id="payment_history_content">
                        
                      {paymentHistory && paymentHistory.slice(0, 4).map((payment_history, i)=>{
                        return (
                        <tr key={i}>
                          <td className="align-middle ps-0 text-nowrap">
                            <div className="d-flex position-relative align-items-center"><img className="d-flex align-self-center me-2" src="../assets/img/logos/hubstaff.png" width={30} />
                              <div className="flex-1"><a className="stretched-link" href="#!">
                                  <h6 className="mb-0">{payment_history.name}</h6>
                                </a>
                                <p className="mb-0">{payment_history.pricing.paiement_cycle}</p>
                              </div>
                            </div>
                          </td>
                          {payment_history.status === 'paid' ? (
                            <td className="align-middle px-4" style={{width: '1%'}}><span className="badge fs--1 w-100 badge-soft-success">Paid</span></td>
                          ) : (
                            <td className="align-middle px-4" style={{width: '1%'}}><span className="badge fs--1 w-100 badge-soft-warning">Pending</span></td>
                          )}
                          
                          <td className="align-middle px-4 text-end text-nowrap" style={{width: '1%'}}>
                            <h6 className="mb-0">$ {parseFloat(payment_history.pricing.price).toFixed(2)}</h6>
                            <p className="fs--2 mb-0">{window.dayjs(payment_history.last_payment).format('dddd, MMMM YYYY')}</p>
                          </td>
                        </tr>)
                      })}
                      
                      </tbody></table>
                  </div>
                </div>
                <div className="card-footer bg-light py-2">
                  <div className="row flex-between-center">
                    <div className="col-auto">
                    </div>
                    <div className="col-auto"><a className="btn btn-link btn-sm px-0 fw-medium" href="/payment_history">View All<span className="fas fa-chevron-right ms-1 fs--2" /></a></div>
                  </div>
                </div>
              </div>

                </div>
                <div className="col-lg-4">
                  <div className="card">
                    <div className="card-header d-flex flex-between-center bg-light py-2">
                      <h6 className="mb-0">Active Users</h6>
                    </div>
                    <div className="card-body py-2">
                        {user_status_data.data[0].users.map(user=>
                          <div className="d-flex align-items-center position-relative mb-3" key={user.staffid}>
                          <div className="avatar avatar-2xl status-online">
                            <div className='rounded-circle d-flex' style={{fontWeight: '600', backgroundColor: '#1f95ff', height: '100%', width: '100%', justifyContent: 'center', alignItems:'center', color: 'white'}}>
                                <span>{(()=>{
                                    let name = `${user.firstname}, ${user.lastname}`;
                                    let initials = name[0] + name.split(" ")[1][0];
                                    return initials
                                  })()}</span>
                            </div>                        
                          </div>
                          
                          <div className="flex-1 ms-3" >
                            <h6 className="mb-0 fw-semi-bold"><a className="stretched-link text-900" href="#">{`${user.firstname}, ${user.lastname}`}</a></h6>
                            <p className="text-500 fs--2 mb-0">{user.email}</p>
                          </div>
                          </div>
                        )}
                      {/* <div className="d-flex align-items-center position-relative mb-3">
                        <div className="avatar avatar-2xl status-online">
                          <img className="rounded-circle" src="../assets/img/team/2.jpg" />
                        </div>
                        <div className="flex-1 ms-3">
                          <h6 className="mb-0 fw-semi-bold"><a className="stretched-link text-900" href="../pages/user/profile.html">Antony Hopkins</a></h6>
                          <p className="text-500 fs--2 mb-0">Moderator</p>
                        </div>
                      </div> */}
                      {/* <div className="d-flex align-items-center position-relative mb-3">
                        <div className="avatar avatar-2xl status-away">
                          <img className="rounded-circle" src="../assets/img/team/3.jpg" />
                        </div>
                        <div className="flex-1 ms-3">
                          <h6 className="mb-0 fw-semi-bold"><a className="stretched-link text-900" href="../pages/user/profile.html">Anna Karinina</a></h6>
                          <p className="text-500 fs--2 mb-0">Editor</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center position-relative mb-3">
                        <div className="avatar avatar-2xl status-offline">
                          <img className="rounded-circle" src="../assets/img/team/4.jpg" />
                        </div>
                        <div className="flex-1 ms-3">
                          <h6 className="mb-0 fw-semi-bold"><a className="stretched-link text-900" href="../pages/user/profile.html">John Lee</a></h6>
                          <p className="text-500 fs--2 mb-0">Admin</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center position-relative mb-3">
                        <div className="avatar avatar-2xl status-offline">
                          <img className="rounded-circle" src="../assets/img/team/5.jpg" />
                        </div>
                        <div className="flex-1 ms-3">
                          <h6 className="mb-0 fw-semi-bold"><a className="stretched-link text-900" href="../pages/user/profile.html">Rowen Atkinson</a></h6>
                          <p className="text-500 fs--2 mb-0">Editor</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center position-relative mb-3">
                        <div className="avatar avatar-2xl status-offline">
                          <img className="rounded-circle" src="../assets/img/team/6.jpg" />
                        </div>
                        <div className="flex-1 ms-3">
                          <h6 className="mb-0 fw-semi-bold"><a className="stretched-link text-900" href="../pages/user/profile.html">Bucky Robert</a></h6>
                          <p className="text-500 fs--2 mb-0">Editor</p>
                        </div>
                      </div>
                      <div className="d-flex align-items-center position-relative false">
                        <div className="avatar avatar-2xl status-offline">
                          <img className="rounded-circle" src="../assets/img/team/7.jpg" />
                        </div>
                        <div className="flex-1 ms-3">
                          <h6 className="mb-0 fw-semi-bold"><a className="stretched-link text-900" href="../pages/user/profile.html">Tom Hanks</a></h6>
                          <p className="text-500 fs--2 mb-0">Editor</p>
                        </div>
                      </div> */}
                    </div>
                    <div className="card-footer bg-light p-0"><a className="btn btn-sm btn-link d-block w-100 py-2" href="#"></a></div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="card h-100">

                    <div className="card-header">
                      <div className="row justify-content-between gx-0">
                        <div className="col-auto">
                          <h1 className="fs-0 text-900">Staff connection time</h1>
                          <div className="d-flex">
                            {/* <h4 className="text-primary mb-0">$165.50</h4> */}
                            {/* <div className="ms-3"><span className="badge rounded-pill badge-soft-primary"><span className="fas fa-caret-up" /> 5%</span></div> */}
                          </div>
                        </div>
                        <div className="col-auto">
                          {/* <select className="form-select form-select-sm pe-4" id="select-gross-revenue-month">
                            <option value={0}>Jan</option>
                            <option value={1}>Feb</option>
                            <option value={2}>Mar</option>
                            <option value={3}>Apr</option>
                            <option value={4}>May</option>
                            <option value={5}>Jun</option>
                            <option value={6}>Jul</option>
                            <option value={7}>Aug</option>
                            <option value={8}>Sep</option>
                            <option value={9}>Oct</option>
                            <option value={10}>Nov</option>
                            <option value={11}>Dec</option>
                          </select> */}
                        </div>
                      </div>
                    </div>
                    
                    <div className="card-body pt-0 pb-3 h-100">
                      <div className="mx-ncard">
                        <table className="table table-borderless font-sans-serif fw-medium fs--1">
                          <tbody>
                            <tr>
                              <td className="pb-2 pt-0">Number of user:</td>
                              <td className="pb-2 pt-0 text-end" style={{width: '20%'}} id='el_number_of_user'>...</td>
                              {/* <td className="pb-2 pt-0 text-end text-700" style={{maxWidth: '20%'}}><span className="me-1 fas fa-long-arrow-alt-down text-danger" />13%</td> */}
                            </tr>
                            {/* <tr>
                              <td className="pb-2 pt-0">Total hours :</td>
                              <td className="pb-2 pt-0 text-end" style={{width: '20%'}}>{JSON.stringify(props.companyUserConnectionTime)}</td>
                            </tr> */}
                          </tbody>
                        </table>
                        {/* Find the JS file for the following calendar at: src/js/charts/echarts/gross-revenue.js*/}
                        {/* If you are not using gulp based workflow, you can find the transpiled code at: public/assets/js/theme.js*/}
                        <div className="echart-gross-revenue-chart px-3 h-100" data-echart-responsive="true" data-options="{&quot;target&quot;:&quot;gross-revenue-footer&quot;,&quot;monthSelect&quot;:&quot;select-gross-revenue-month&quot;,&quot;optionOne&quot;:&quot;currentMonth&quot;,&quot;optionTwo&quot;:&quot;prevMonth&quot;}" />
                      </div>
                    </div>

                    <div className="card-footer border-top py-2 d-flex flex-between-center">
                      <div className="d-flex" id="gross-revenue-footer">
                        {/* <div className="btn btn-sm btn-text d-flex align-items-center p-0 shadow-none" id="currentMonth" data-month="current"><span className="fas fa-circle text-primary fs--2 me-1" /><span className="text">Jan</span></div>
                        <div className="btn btn-sm btn-text d-flex align-items-center p-0 shadow-none ms-2" id="prevMonth" data-month="prev"><span className="fas fa-circle text-300 fs--2 me-1" /><span className="text">Dec</span></div> */}
                      </div>
                      {/* <a className="btn btn-link btn-sm px-0" href="#!">View report<span className="fas fa-chevron-right ms-1 fs--2" /></a> */}
                    </div>

                  </div>

                </div>
              </div>
            </div>
            {Object.keys(computeOrderSummary(checkedState)).length > 0 && <div className="col">
              <div className="row g-3">
                <div className="col-xxl-12">
                  <div className="card h-100 h-xxl-auto mt-xxl-3">
                    <div className="card-header d-flex flex-between-center bg-light py-2">
                      <h6 className="mb-0">My Cart</h6><a className="py-1 fs--1 font-sans-serif" href="#">View All</a>
                    </div>
                    <div className="card-body pb-0">
                  {
                    Object.keys(computeOrderSummary(checkedState)).map((oneKey,i)=>{
                      return Object.keys(computeOrderSummary(checkedState)[oneKey]).slice(0,4).map((subKey,_i)=>{
                        return (
                          <Fragment key={_i}>
                          <div className="d-flex mb-3 hover-actions-trigger align-items-center">
                            <div className="file-thumbnail"><img className="border h-100 w-100 fit-cover rounded-2" src="../assets/img/products/5-thumb.png" />
                            </div>
                            <div className="ms-3 flex-shrink-1 flex-grow-1">
                              <h6 className="mb-1"><a className="stretched-link text-900 fw-semi-bold" href="#!">{computeOrderSummary(checkedState)[oneKey][subKey].name}</a></h6>
                              <div className="fs--1"><span className="fw-semi-bold">$ {computeOrderSummary(checkedState)[oneKey][subKey].price && computeOrderSummary(checkedState)[oneKey][subKey].price[paymentCycle]}</span><span className="fw-medium text-600 ms-2">Users: {numberOfusers}/Users - {paymentCycle}</span></div>
                              <div className="hover-actions end-0 top-50 translate-middle-y"><a className="btn btn-light border-300 btn-sm me-1 text-600" data-bs-toggle="tooltip" data-bs-placement="top" title="Download" href="../assets/img/icons/cloud-download.svg" download="download"><img src="../assets/img/icons/cloud-download.svg" width={15} /></a>
                                <button className="btn btn-light border-300 btn-sm me-1 text-600 shadow-none" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"><img src="../assets/img/icons/edit-alt.svg" width={15} /></button>
                              </div>
                            </div>
                          </div>
                          <hr className="bg-200" />
                        </Fragment>
                        )
                      })  
                    })      
                  }            

                    </div>
                  </div>
                </div>
              </div>
            </div>}
          </div>
          <footer className="footer">
            <div className="row g-0 justify-content-between fs--1 mt-4 mb-3">
              <div className="col-12 col-sm-auto text-center">
                <p className="mb-0 text-600">Equerre SaaS Admin <span className="d-none d-sm-inline-block">| </span><br className="d-sm-none" /> 2022 © <a href="https://www.equerre.com">Equerre.com</a></p>
              </div>
              <div className="col-12 col-sm-auto text-center">
                <p className="mb-0 text-600">v1.0.0</p>
              </div>
            </div>
          </footer>
        </div>
        <div className="modal fade" id="authentication-modal" tabIndex={-1} role="dialog" aria-labelledby="authentication-modal-label" aria-hidden="true">
          <div className="modal-dialog mt-6" role="document">
            <div className="modal-content border-0">
              <div className="modal-header px-5 position-relative modal-shape-header bg-shape">
                <div className="position-relative z-index-1 light">
                  <h4 className="mb-0 text-white" id="authentication-modal-label">Register</h4>
                  <p className="fs--1 mb-0 text-white">Please create your free Falcon account</p>
                </div>
                <button className="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body py-4 px-5">
                <form>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="modal-auth-name">Name</label>
                    <input className="form-control" type="text" autoComplete="on" id="modal-auth-name" />
                  </div>
                  <div className="mb-3">
                    <label className="form-label" htmlFor="modal-auth-email">Email address</label>
                    <input className="form-control" type="email" autoComplete="on" id="modal-auth-email" />
                  </div>
                  <div className="row gx-2">
                    <div className="mb-3 col-sm-6">
                      <label className="form-label" htmlFor="modal-auth-password">Password</label>
                      <input className="form-control" type="password" autoComplete="on" id="modal-auth-password" />
                    </div>
                    <div className="mb-3 col-sm-6">
                      <label className="form-label" htmlFor="modal-auth-confirm-password">Confirm Password</label>
                      <input className="form-control" type="password" autoComplete="on" id="modal-auth-confirm-password" />
                    </div>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="checkbox" id="modal-auth-register-checkbox" />
                    <label className="form-label" htmlFor="modal-auth-register-checkbox">I accept the <a href="#!">terms </a>and <a href="#!">privacy policy</a></label>
                  </div>
                  <div className="mb-3">
                    <button className="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">Register</button>
                  </div>
                </form>
                <div className="position-relative mt-5">
                  <hr className="bg-300" />
                  <div className="divider-content-center">or register with</div>
                </div>
                <div className="row g-2 mt-2">
                  <div className="col-sm-6"><a className="btn btn-outline-google-plus btn-sm d-block w-100" href="#"><span className="fab fa-google-plus-g me-2" data-fa-transform="grow-8" /> google</a></div>
                  <div className="col-sm-6"><a className="btn btn-outline-facebook btn-sm d-block w-100" href="#"><span className="fab fa-facebook-square me-2" data-fa-transform="grow-8" /> facebook</a></div>
                </div>
              </div>
            </div>
          </div>
        </div>

    </div>
  )
}


const mapStateToProps = (state) => {
  // console.log('state', state)
  return {
    moduleLineList: state.moduleline.moduleLineList,
    moduleList: state.module.moduleList,
    price_ranges: state.pricing.pricingData.price_ranges,
    marketPlaceData: state.market_place,
    companyUserStatus: state.erpBackend.companyUserStatus,
    companyUserConnectionTime: state.erpBackend.companyUserConnectionTime,
  }
}

const mapDispatchToProps = dispatch =>{
  return {
    fetchPricings: ()=> dispatch(fetchPricings()),
	  fetchModules: () => dispatch(fetchModules()),
	  fetchModuleLines: () => dispatch(fetchModuleLines()),
    setNumberOfusers: (val) => dispatch(setNumberOfusers(val)),
    setCheckedState: (val) => dispatch(setCheckedState(val)),
    setPaymentCycle: (val) => dispatch(setPaymentCycle(val)),
    erpBackendUserListStatus: (id_company, setLoading) => dispatch(erpBackendUserListStatus(id_company, setLoading)),
    getUserConnectionTimeList: (id_company, setLoading) => dispatch(getUserConnectionTimeList(id_company, setLoading)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
