import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './App.css';
import { BrowserRouter, Switch, Route, Redirect,useLocation } from 'react-router-dom';
import UserAdminLayout from './hoc/UserAdminLayout/UserAdminLayout';
import { ConfirmMail, Dashboard, ForgotPassword, ResetForgotPassword, SignIn } from './containers';
import mainRoutes from './route/mainRoute';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/solid.min.css';
import '@fortawesome/fontawesome-free/css/regular.min.css';
import PrivateRoute from './hoc/PrivateRoute/PrivateRoute';
import { updateUserSuccess } from './redux/actions/userCrudAction';
import { getPermissionByRoleId } from './redux/actions/permissionActions';

function App(props) {
  const user = JSON.parse(localStorage.getItem('profile'));
  const search = useLocation().search;
  const email=new URLSearchParams(search).get("email");
  const jwt_secret=new URLSearchParams(search).get("jwt_secret");
  useEffect(() => {
    // console.log('App', props, 'user', user)
    user !== null && props.getPermissionByRoleId(user.result.role_id);
  }, [])
  
  const { pathname } = window.location
  // console.log('pathname', pathname)
  // console.log('window.location', window.location)
  // console.log('pathname.includes', pathname.includes('/confirm-mail'))
  // console.log('email', email)
  return (
    
    <>
        {<UserAdminLayout user={user} permissions={props.user_permissions || {}}>
                <BrowserRouter>
              {(user === null || pathname.includes('/forgot-password') || pathname.includes('/confirm-mail'))  &&                 
                    <Switch>
                    <Route path="/signin" exact>
                      <SignIn />
                    </Route>
                    
                    <Route path="/forgot-password" exact>
                      <ForgotPassword />
                    </Route>
                    <Route path="/reset-forgot-password" exact>
                      <ResetForgotPassword />
                    </Route>
                    <Route path="/confirm-mail">
                      <ConfirmMail />
                    </Route>
                    <Route path='' render={() => { return <Redirect to='/signin' />;}}/>
                    </Switch>
                
               }  
              
              {user ?  (
              <Switch>
              <Route path='/signin' render={() => { return <Redirect to='/dashboard' />;}}/>
                <Route path="/dashboard" exact component={Dashboard}/>
                <Route path="/" exact component={Dashboard}/>
                {mainRoutes.map((route, i) => (<PrivateRoute exact {...route} key={i}/>))}     
              </Switch>        
          ) : pathname==='/reset-forgot-password' ? (<Redirect to={`/reset-forgot-password${jwt_secret ? `?jwt_secret=${jwt_secret}`:''}`}/>) : pathname===`/confirm-mail` ? (<Redirect to={`/confirm-mail${email ? `?email=${email}`:''}`}/>) : pathname==='/forgot-password' ? (<Redirect to='/forgot-password'/>) : <Redirect to='/signin'/>  }
          </BrowserRouter>
        </UserAdminLayout>}
            
    </>
  )}

  const mapStateToProps = (state) => {
    return {
      user_permissions: state.permission.user_permissions
    }
  }

  const mapDispatchToProps = dispatch =>{
    return {
      updateUserSuccess:(user)=>dispatch(updateUserSuccess(user)),
      getPermissionByRoleId:(role_id)=> dispatch(getPermissionByRoleId(role_id)),
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(App)
