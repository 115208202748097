import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useFormik } from "formik";
import * as Yup from "yup";
import { sendPasswordRecoveryMail } from '../../redux/actions/auth';
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { ClipLoader } from "react-spinners";
import { GOOGLE_RECAPTCHA_KEY } from '../../env';

const ForgotPassword = (props) => {
  let history = useHistory();
  const [message, setMessage] = useState(false)
  const [disableSubmitButton, setDisableSubmitButton] = useState(false)

  useEffect(() => {
    console.log('/forgot-password', )
  }, )
  
  const formik = useFormik({
    initialValues: {
      email: "",
      recaptcha: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address"),
      recaptcha: Yup.string().required(),
    }),
    onSubmit: async (values, { resetForm }) => {
      
      setDisableSubmitButton(true)
      console.log('-- values', values)
    props.sendPasswordRecoveryMail({
      email: values.email,
     },history)
          .then((res) => {
            if (res && res.name === "Error" ) {
              setMessage({code: 404, message: "Please make sure your credential are ok"}); 
              return;
            } 
            setMessage({code: 200, message: "Please check your e-mail and confirm your request"}); 
            resetForm({ values: "" });
            
          // window.location.href = '/signin'
          }).catch ((error)=> {
            console.error("-- error", error);
            setMessage({code: 404, message: error})
              
          }).finally(()=> {
            setTimeout(() => {
              setMessage(false);    
            }, 8000);setDisableSubmitButton(false)})
    },
  });

  return (
    <>
    <main className="main" id="top">
  <div className="container" data-layout="container">
     <div className="row flex-center min-vh-100 py-6 text-center">
  <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4"><a className="d-flex flex-center mb-4" href="#"><img className="me-2" src="/assets/img/equerre-logo.png" width={268} /><span className="font-sans-serif fw-bolder fs-5 d-inline-block"></span></a>
    <div className="card">
      <div className="card-body p-4 p-sm-5">

        
        {message && message.code ? 
            message.code  == "200" ? (
            <div className="alert alert-success mb-3" role="alert">
                {message.message}
            </div>
            ) : (
                <div className="alert alert-danger mb-3" role="alert">
                {JSON.stringify(message?.message?.message || message?.message)}
                </div>
            )          
        :null}

        <h5 className="mb-0">Forgot your password?</h5><small>Enter your email and we'll send you a mail, to reset your password.</small>
        <form 
                  onSubmit={formik.handleSubmit}
                    id="signin-form"
                  className="form mt-4" method="post" action=""> 

          <input	
              className="form-control"
              value={formik.values.email}                          									
              id="email"
              name="email"
              type="email"
              placeholder="Email address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              />
              {formik.touched.email && formik.errors.email ? (
                  <div style={{ color: "#dc3545" }}>{formik.errors.email}</div>
              ) : null}

          <div className="mb-3" />
          
          <div className="form-group">
            <ReCAPTCHA
            sitekey={GOOGLE_RECAPTCHA_KEY}
            render="explicit"
            onChange={(response) => {formik.setFieldValue("recaptcha", response); }}
            onExpired={() => { formik.setFieldValue("recaptcha", ""); }}
            />
            
            {formik.touched.recaptcha && formik.errors.recaptcha ? (
              <div style={{ color: "#dc3545" }}>{formik.errors.recaptcha}</div>
            ) : null}
          </div>   
          <div className="mb-3" />

          <button disabled={disableSubmitButton} className="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">
                      
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{marginRight: '.4em'}}>Reset your Password</div> <ClipLoader color={"#fff"} loading={disableSubmitButton} size={20} />
              </div>
            </button>
          </form>
        
        <a className="fs--1 text-600" href="#!">I can't recover my account using this page<span className="d-inline-block ms-1">→</span></a>
      </div>
    </div>
  </div>
</div>
      </div>
    </main>
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => {
  return {
      sendPasswordRecoveryMail: (formData, history)=> dispatch(sendPasswordRecoveryMail(formData, history)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)