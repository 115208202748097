import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
import { env_config, API } from '../../env';
    
const config = env_config;

const validate = values => {
    const errors = {};
    const passwordRegex = /(?=.*[0-9])/;
    
    if (!values.password ) {
        errors.password = 'Required';
    } else if (values.password.length < 8) {
        errors.password = "*Password must be 8 characters long.";
    } else if (values.password.length > 15) {
        errors.password = 'Must be 15 characters or less';
    } else if (!values.password) {
        errors.password = "*Required";
    }  else if (!passwordRegex.test(values.password)) {
        errors.password = "*Invalid password. Must contain one number.";
    } 
  
    if (values.password && values.confirm_password) {
        if (values.password !== values.confirm_password) {
            errors.confirm_password = "Password not matched";
        }
    }
    return errors;
  };

const UpdatePasswordWidget = (props) => {
    const { userDetails } = props
    const [message, setMessage] = useState(false)
    
  const formik = useFormik({
    initialValues: {
        old_password: "",
        password: "",
        confirm_password: "",
        hashed_old_password: userDetails?.result?.password,
        email: userDetails?.result?.email,
    },
    validate,
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address")
    }),
    onSubmit: async (values, { resetForm }) => {      
    console.log('-- values', values)
    
    await API
    .put("/user/update_password", {
        email: userDetails?.result?.email,
        old_password: values.old_password,
        password: values.confirm_password,
        hashed_old_password: values.hashed_old_password,

        }, config)
            .then((res) => {
            // console.log('--- res', res)
            // return
            const {data} = res
            let new_value = {
                ...userDetails,
                result: data 
            }            
      localStorage.setItem('profile', JSON.stringify(new_value))
      setMessage({code: res.status, message: "Password successfully updated"});    
      setTimeout(() => {
        // Most recent value
        setMessage(false);    
      }, 8000);

      resetForm({ values: "" });
    }).catch((err)=>{
    //   console.error("-- err", err.response);
      setMessage({code: err.response?.status || 500, message: err.response ? err.response.data : err})
    });
    },
  });

  return (
        
    <div className="card mb-3">
        <div className="card-header">

        {message && message.code ? 
            message.code.toString().substr(0,2)  === "20" ? (
            <div className="alert alert-success" role="alert">
                {message.message}
            </div>
            ) : (
                <div className="alert alert-danger" role="alert">
                {JSON.stringify(message?.message?.message)}
                </div>
            )          
        :null}

        <h5 className="mb-0">Change Password</h5>
        </div>
        <div className="card-body bg-light">
        <form 
            onSubmit={formik.handleSubmit}
            id="signup-form"
            className="form" method="post" action="">
            <div className="mb-3"><label className="form-label" htmlFor="old-password">Old Password</label>
            <input
                    className="form-control"												
                    id="old-password"
                    name="old_password"
                    type="password"
                    placeholder="Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.old_password}
                    // validate={validatePassword}
                />
                {formik.touched.old_password && formik.errors.old_password ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.old_password}</div>
                ) : null}

                {/* <input className="form-control" id="old-password" type="password" /> */}
            </div>
            <div className="mb-3"><label className="form-label" htmlFor="new-password">New Password</label>
                {/* <input className="form-control" id="new-password" type="password" /> */}
                <input
                    className="form-control"												
                    id="new-password"
                    name="password"
                    type="password"
                    placeholder="Password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    // validate={validatePassword}
                />
                {formik.touched.password && formik.errors.password ? (
                    <div style={{ color: "#dc3545" }}>{formik.errors.password}</div>
                ) : null}

            </div>
            <div className="mb-3"><label className="form-label" htmlFor="confirm-password">Confirm Password</label>
                {/* <input className="form-control" id="confirm-password" type="password" /> */}
                
                    <input
                            className="form-control"													
                            id="confirm-password"
                            name="confirm_password"
                            type="password"
                            placeholder="Confirm Password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirm_password}
                            // validate={validateConfirmPassword}
                        />
                        {formik.touched.confirm_password && formik.errors.confirm_password ? (
                            <div style={{ color: "#dc3545" }}>{formik.errors.confirm_password}</div>
                        ) : null}

            </div>
            <button className="btn btn-primary d-block w-100" type="submit">Update Password </button>
        </form>
        </div>
    </div> 
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordWidget)