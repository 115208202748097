import React from 'react'
import { connect } from 'react-redux'

const Cart = (props) => {
  return (
    <>
        
        <div className="card">
            <div className="card-header">
              <div className="row justify-content-between">
                <div className="col-md-auto">
                  <h5 className="mb-3 mb-md-0">Shopping Cart (7 Items)</h5>
                </div>
                <div className="col-md-auto"><a className="btn btn-sm btn-outline-secondary border-300 me-2" href="/product-list"> <span className="fas fa-chevron-left me-1" data-fa-transform="shrink-4"></span>Continue Shopping</a><a className="btn btn-sm btn-primary" href="/checkout">Checkout</a></div>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="row gx-card mx-0 bg-200 text-900 fs--1 fw-semi-bold">
                <div className="col-9 col-md-8 py-2">Name</div>
                <div className="col-3 col-md-4">
                  <div className="row">
                    <div className="col-md-8 py-2 d-none d-md-block text-center">Quantity</div>
                    <div className="col-12 col-md-4 text-end py-2">Price</div>
                  </div>
                </div>
              </div>
              <div className="row gx-card mx-0 align-items-center border-bottom border-200">
                <div className="col-8 py-3">
                  <div className="d-flex align-items-center"><a href="/product-details"><img className="img-fluid rounded-1 me-3 d-none d-md-block" src="../../assets/img/products/1.jpg" alt="" width="60" /></a>
                    <div className="flex-1">
                      <h5 className="fs-0"><a className="text-900" href="/product-details">Apple MacBook Pro 15&quot; Z0V20008N: 2.9GHz 6-core 8th-Gen Intel Core i9, 32GB RAM</a></h5>
                      <div className="fs--2 fs-md--1"><a className="text-danger" href="#!">Remove</a></div>
                    </div>
                  </div>
                </div>
                <div className="col-4 py-3">
                  <div className="row align-items-center">
                    <div className="col-md-8 d-flex justify-content-end justify-content-md-center order-1 order-md-0">
                      <div>
                        <div className="input-group input-group-sm flex-nowrap" data-quantity="data-quantity"><button className="btn btn-sm btn-outline-secondary border-300 px-2" data-type="minus">-</button><input className="form-control text-center px-2 input-spin-none" type="number" min="1" value="1" aria-label="Amount (to the nearest dollar)" style={{width: '50px'}} /><button className="btn btn-sm btn-outline-secondary border-300 px-2" data-type="plus">+</button></div>
                      </div>
                    </div>
                    <div className="col-md-4 text-end ps-0 order-0 order-md-1 mb-2 mb-md-0 text-600">$1292</div>
                  </div>
                </div>
              </div>
              <div className="row gx-card mx-0 align-items-center border-bottom border-200">
                <div className="col-8 py-3">
                  <div className="d-flex align-items-center"><a href="/product-details"><img className="img-fluid rounded-1 me-3 d-none d-md-block" src="../../assets/img/products/2.jpg" alt="" width="60" /></a>
                    <div className="flex-1">
                      <h5 className="fs-0"><a className="text-900" href="/product-details">Apple iMac Pro (27-inch with Retina 5K Display, 3.0GHz 10-core Intel Xeon W, 1TB SSD)</a></h5>
                      <div className="fs--2 fs-md--1"><a className="text-danger" href="#!">Remove</a></div>
                    </div>
                  </div>
                </div>
                <div className="col-4 py-3">
                  <div className="row align-items-center">
                    <div className="col-md-8 d-flex justify-content-end justify-content-md-center order-1 order-md-0">
                      <div>
                        <div className="input-group input-group-sm flex-nowrap" data-quantity="data-quantity"><button className="btn btn-sm btn-outline-secondary border-300 px-2" data-type="minus">-</button><input className="form-control text-center px-2 input-spin-none" type="number" min="1" value="1" aria-label="Amount (to the nearest dollar)" style={{width: '50px'}} /><button className="btn btn-sm btn-outline-secondary border-300 px-2" data-type="plus">+</button></div>
                      </div>
                    </div>
                    <div className="col-md-4 text-end ps-0 order-0 order-md-1 mb-2 mb-md-0 text-600">$2012</div>
                  </div>
                </div>
              </div>
              <div className="row gx-card mx-0 align-items-center border-bottom border-200">
                <div className="col-8 py-3">
                  <div className="d-flex align-items-center"><a href="/product-details"><img className="img-fluid rounded-1 me-3 d-none d-md-block" src="../../assets/img/products/4.jpg" alt="" width="60" /></a>
                    <div className="flex-1">
                      <h5 className="fs-0"><a className="text-900" href="/product-details">Apple iPad Air 2019 (3GB RAM, 128GB ROM, 8MP Main Camera)</a></h5>
                      <div className="fs--2 fs-md--1"><a className="text-danger" href="#!">Remove</a></div>
                    </div>
                  </div>
                </div>
                <div className="col-4 py-3">
                  <div className="row align-items-center">
                    <div className="col-md-8 d-flex justify-content-end justify-content-md-center order-1 order-md-0">
                      <div>
                        <div className="input-group input-group-sm flex-nowrap" data-quantity="data-quantity"><button className="btn btn-sm btn-outline-secondary border-300 px-2" data-type="minus">-</button><input className="form-control text-center px-2 input-spin-none" type="number" min="1" value="1" aria-label="Amount (to the nearest dollar)" style={{width: '50px'}} /><button className="btn btn-sm btn-outline-secondary border-300 px-2" data-type="plus">+</button></div>
                      </div>
                    </div>
                    <div className="col-md-4 text-end ps-0 order-0 order-md-1 mb-2 mb-md-0 text-600">$1024</div>
                  </div>
                </div>
              </div>
              <div className="row gx-card mx-0 align-items-center border-bottom border-200">
                <div className="col-8 py-3">
                  <div className="d-flex align-items-center"><a href="/product-details"><img className="img-fluid rounded-1 me-3 d-none d-md-block" src="../../assets/img/products/3.jpg" alt="" width="60" /></a>
                    <div className="flex-1">
                      <h5 className="fs-0"><a className="text-900" href="/product-details">Apple iPhone XS Max (4GB RAM, 512GB ROM, 12MP Main Camera)</a></h5>
                      <div className="fs--2 fs-md--1"><a className="text-danger" href="#!">Remove</a></div>
                    </div>
                  </div>
                </div>
                <div className="col-4 py-3">
                  <div className="row align-items-center">
                    <div className="col-md-8 d-flex justify-content-end justify-content-md-center order-1 order-md-0">
                      <div>
                        <div className="input-group input-group-sm flex-nowrap" data-quantity="data-quantity"><button className="btn btn-sm btn-outline-secondary border-300 px-2" data-type="minus">-</button><input className="form-control text-center px-2 input-spin-none" type="number" min="1" value="1" aria-label="Amount (to the nearest dollar)" style={{width: '50px'}} /><button className="btn btn-sm btn-outline-secondary border-300 px-2" data-type="plus">+</button></div>
                      </div>
                    </div>
                    <div className="col-md-4 text-end ps-0 order-0 order-md-1 mb-2 mb-md-0 text-600">$990</div>
                  </div>
                </div>
              </div>
              <div className="row gx-card mx-0 align-items-center border-bottom border-200">
                <div className="col-8 py-3">
                  <div className="d-flex align-items-center"><a href="/product-details"><img className="img-fluid rounded-1 me-3 d-none d-md-block" src="../../assets/img/products/5.jpg" alt="" width="60" /></a>
                    <div className="flex-1">
                      <h5 className="fs-0"><a className="text-900" href="/product-details">Apple Watch Series 4 44mm GPS Only</a></h5>
                      <div className="fs--2 fs-md--1"><a className="text-danger" href="#!">Remove</a></div>
                    </div>
                  </div>
                </div>
                <div className="col-4 py-3">
                  <div className="row align-items-center">
                    <div className="col-md-8 d-flex justify-content-end justify-content-md-center order-1 order-md-0">
                      <div>
                        <div className="input-group input-group-sm flex-nowrap" data-quantity="data-quantity"><button className="btn btn-sm btn-outline-secondary border-300 px-2" data-type="minus">-</button><input className="form-control text-center px-2 input-spin-none" type="number" min="1" value="1" aria-label="Amount (to the nearest dollar)" style={{width: '50px'}} /><button className="btn btn-sm btn-outline-secondary border-300 px-2" data-type="plus">+</button></div>
                      </div>
                    </div>
                    <div className="col-md-4 text-end ps-0 order-0 order-md-1 mb-2 mb-md-0 text-600">$400</div>
                  </div>
                </div>
              </div>
              <div className="row gx-card mx-0 align-items-center border-bottom border-200">
                <div className="col-8 py-3">
                  <div className="d-flex align-items-center"><a href="/product-details"><img className="img-fluid rounded-1 me-3 d-none d-md-block" src="../../assets/img/products/7.jpg" alt="" width="60" /></a>
                    <div className="flex-1">
                      <h5 className="fs-0"><a className="text-900" href="/product-details">Nikon D3200 Digital DSLR Camera</a></h5>
                      <div className="fs--2 fs-md--1"><a className="text-danger" href="#!">Remove</a></div>
                    </div>
                  </div>
                </div>
                <div className="col-4 py-3">
                  <div className="row align-items-center">
                    <div className="col-md-8 d-flex justify-content-end justify-content-md-center order-1 order-md-0">
                      <div>
                        <div className="input-group input-group-sm flex-nowrap" data-quantity="data-quantity"><button className="btn btn-sm btn-outline-secondary border-300 px-2" data-type="minus">-</button><input className="form-control text-center px-2 input-spin-none" type="number" min="1" value="1" aria-label="Amount (to the nearest dollar)" style={{width: '50px'}} /><button className="btn btn-sm btn-outline-secondary border-300 px-2" data-type="plus">+</button></div>
                      </div>
                    </div>
                    <div className="col-md-4 text-end ps-0 order-0 order-md-1 mb-2 mb-md-0 text-600">$2398</div>
                  </div>
                </div>
              </div>
              <div className="row gx-card mx-0 align-items-center border-bottom border-200">
                <div className="col-8 py-3">
                  <div className="d-flex align-items-center"><a href="/product-details"><img className="img-fluid rounded-1 me-3 d-none d-md-block" src="../../assets/img/products/8.jpg" alt="" width="60" /></a>
                    <div className="flex-1">
                      <h5 className="fs-0"><a className="text-900" href="/product-details">Canon Standard Zoom Lens</a></h5>
                      <div className="fs--2 fs-md--1"><a className="text-danger" href="#!">Remove</a></div>
                    </div>
                  </div>
                </div>
                <div className="col-4 py-3">
                  <div className="row align-items-center">
                    <div className="col-md-8 d-flex justify-content-end justify-content-md-center order-1 order-md-0">
                      <div>
                        <div className="input-group input-group-sm flex-nowrap" data-quantity="data-quantity"><button className="btn btn-sm btn-outline-secondary border-300 px-2" data-type="minus">-</button><input className="form-control text-center px-2 input-spin-none" type="number" min="1" value="1" aria-label="Amount (to the nearest dollar)" style={{width: '50px'}} /><button className="btn btn-sm btn-outline-secondary border-300 px-2" data-type="plus">+</button></div>
                      </div>
                    </div>
                    <div className="col-md-4 text-end ps-0 order-0 order-md-1 mb-2 mb-md-0 text-600">$400</div>
                  </div>
                </div>
              </div>
              <div className="row fw-bold gx-card mx-0">
                <div className="col-9 col-md-8 py-2 text-end text-900">Total</div>
                <div className="col px-0">
                  <div className="row gx-card mx-0">
                    <div className="col-md-8 py-2 d-none d-md-block text-center">7 (items)</div>
                    <div className="col-12 col-md-4 text-end py-2">$8516</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-footer bg-light d-flex justify-content-end">
              <form className="me-3">
                <div className="input-group input-group-sm"><input className="form-control" type="text" placeholder="Promocode" /><button className="btn btn-outline-secondary border-300 btn-sm" type="submit">Apply</button></div>
              </form><a className="btn btn-sm btn-primary" href="/checkout">Checkout</a>
            </div>
          </div>
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Cart)