import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom';
import { BACKEND_DOMAIN } from '../../env';
import axios from 'axios';
import { useState } from 'react';
import { PropagateLoader,MoonLoader} from "react-spinners";

const ResetForgotPassword = (props) => {
  const search = useLocation().search;
  const jwt_secret=new URLSearchParams(search).get("jwt_secret");
  const [decoded, setDecoded] = useState({})
  const [loading, setLoading] = useState(false);
  const [message, setmessage] = useState('')
  useEffect(() => {
    const fetchData = async ()=>{  
      setLoading(true)
        const response = await axios.post(
          `${BACKEND_DOMAIN}/user/reset-forgot-password?jwt_secret=${jwt_secret}`,
          new URLSearchParams({
              'jwt_secret': jwt_secret
          })
      );
        const {data} = await response;
        console.log('content res',data);  
        return {content: data.data, _message: data.message}
    }
    fetchData().then(res=>{
      console.log('res', res)
      const {content, _message} = res
      if (!content) {
        setmessage(_message)
        setDecoded(_message)
      }else {
        setmessage(_message)
        setDecoded(content)
      }          
    }).finally(()=> 
    setLoading(false))
  },[])

    
  return (
    <>
    {/* <div>ResetForgotPassword : {jwt_secret}<br/> <h6>{decoded && JSON.stringify(decoded)}</h6></div> */}
    <main className="main" id="top">
  <div className="container" data-layout="container">
     <div className="row flex-center min-vh-100 py-6 text-center">
  <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4"><a className="d-flex flex-center mb-4" href="#"><img className="me-2" src="/assets/img/equerre-logo.png" width={268} /><span className="font-sans-serif fw-bolder fs-5 d-inline-block"></span></a>
    
    <div className="card">
  <div className="card-body p-4 p-sm-5">
    <div className="text-center"><img className="d-block mx-auto mb-4" src="./assets/img/reset-sucessfull-password.jpg" alt="reset-sucessfull-password" width={200} />
    <PropagateLoader style={{ marginTop: '3em',marginBottom: '0em' ,display: 'flex', alignItems: 'center', justifyContent: 'center'}} color={"#009ade"} loading={loading} size={25} />
    <br/>
      <h4 className="mb-2">{message}</h4>
      <p>In case your token has expired, retry the process, <a href='/forgot-password'>retry</a></p>
      <a disable={loading ? 'none' : 'block'} className="btn btn-primary btn-sm mt-3" href="/signing"><svg className="svg-inline--fa fa-chevron-left fa-w-10 me-1" data-fa-transform="shrink-4 down-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg style={{transformOrigin: '0.3125em 0.5625em'}}><g transform="translate(160 256)"><g transform="translate(0, 32)  scale(0.75, 0.75)  rotate(0 0 0)"><path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z" transform="translate(-160 -256)" /></g></g></svg>{/* <span class="fas fa-chevron-left me-1" data-fa-transform="shrink-4 down-1"></span> Font Awesome fontawesome.com */}Return to login</a>
    </div>
  </div>
</div>


  </div>
</div>
      </div>
    </main>
    
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ResetForgotPassword)