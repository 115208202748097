import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { sendSuccessCheckout } from '../../redux/actions/pricingActions'

const SuccessCheckout = (props) => {
  
  const queryParams = new URLSearchParams(props.location.search.toString())
  const payment_intent = queryParams.get("payment_intent")
  const payment_intent_client_secret = queryParams.get("payment_intent_client_secret")
  const redirect_status = queryParams.get("redirect_status")
  // console.log('--- redirect_status', redirect_status)

  useEffect(() => {
    // let _redirect_status = queryParams.get("redirect_status")
    // if (_redirect_status === "succeeded"){
    //   let value = JSON.parse(localStorage.getItem("body"))
    //   value.body?.user?.payment_intent = payment_intent
    //   value.body?.user?.payment_intent_client_secret = payment_intent_client_secret
    //   props.sendSuccessCheckout(value.body)
    // }
    return () => {
      localStorage.removeItem("body")
    }
  }, [])
  
  return (
    
    <div className="container">
      {redirect_status && redirect_status === "succeeded" ? (
        <div className="row mb-5">
            <div className="col-md-6 mx-auto mt-5 p-0 single-feature">
                <div className="payment" >
                    <div className="payment_header mb-3 p-5" style={{backgroundColor: "#2aa270"}}>
                    <div className="d-flex justify-content-center align-items-center "><i className="fa fa-check" aria-hidden="true"></i></div>
                    </div>
                    <div className="content p-4 pb-5">
                    <h1>Payment Success !</h1>
                    <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. </p>
                    <a className='mt-4' href="/">Go to Home</a>
                    </div>
                    
                </div>
            </div>
        </div>
      ):null}
    </div>
  )
}

const mapStateToProps = (state) => {
  console.log('state :>>', state)
  return {
    userProductData : state.user_product.selected_modules,
  }
}

const mapDispatchToProps = dispatch => {
    return {
      sendSuccessCheckout:(data)=> dispatch(sendSuccessCheckout(data)),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(SuccessCheckout)

// https://www.youtube.com/watch?v=IhvtIbfDZJI