import React from 'react'
import { connect } from 'react-redux'

const ButtonLoader = (props) => {

  return (
    <>
        <div style={{
          fontSize: '.5rem',
          marginLeft: '1em',
          height: '2em',
          width: '2em',  
      }} className={`spinner-border ${props.show ? '' : 'd-none'}`}></div>
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonLoader)