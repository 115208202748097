import * as actionType from '../constants/actionTypes';

const pricingReducer = (state = { pricingList: []  }, action) => {
  // console.log('action', action)
  switch (action.type) {
    case actionType.FETCH_PRICING_CRUD:
      // console.log('-- FETCH_PRICING_CRUD:>>', action.payloa)
      return {...state, pricingList: action.payload}
    case actionType.CREATE_PRICING:
      return {
        ...state,
        loading: false,
        pricingList: [...state.pricingList, action.payload],
        error: '',
      };    
      case actionType.UPDATE_PRICING:
        // console.log('-- reducer UPDATE_PRICING:>>', action.payload)
        return {
          ...state,
          pricingList: [...state.pricingList.map((pricing) => {
            if (pricing.number.value !== action.payload.number_of_user) {
              return pricing
            }
            // console.log('in condition true', pricing)
            return action.payload.updated_pricing
          })]
        }
        case actionType.DELETE_PRICING:
          return {
            ...state,
            pricingList: [...state.pricingList.filter((pricing) => pricing.number.value != action.payload)]
          }
      default:
        return state;
  }
};

export default pricingReducer;