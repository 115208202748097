
import { Comp404 } from "../components";
import { Profile, Settings, Account, CompanyDetails, Marketplace, Cart, Checkout, SuccessCheckout, PaymentHistory, Modules, ModuleDetails, Pricing, UserRoleAdmin, UserAdmin, UserRoleAdminDetails, SubscribedUser, SubscribedUserCompany, SubscribedUserCompanyModule, ForgotPassword, ConfirmMail, ResetForgotPassword, UserProduct, UserProductDetails, } from "../containers";
const mainRoutes = [
  {
    exact: true,
    path: '/user_product/:user_token',
    name: 'user_product_details',
    component: UserProductDetails,
    key: 'user_product_details',
  }, 
  {
    exact: true,
    path: '/profile',
    name: 'Profile',
    component: Profile,
    key: 'Profile',
  }, 
  {
    exact: true,
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword, 
    key: 'forgot-password',
  }, 
  {
    exact: true,
    path: '/reset-forgot-password',
    name: 'reset-forgot-password',
    component: ResetForgotPassword, 
    key: 'reset-forgot-password',
  },
  {
    exact: true,
    path: '/confirm-mail',
    name: 'confirm-mail',
    component: ConfirmMail,
    key: 'confirm-mail',
  },{
    exact: true,
    path: '/account',
    name: 'Account',
    component: Account,
    key: 'Account',
  },{
    exact: true,
    path: '/company/:company_id',
    name: 'CompanyDetails',
    component: CompanyDetails,
    key: 'CompanyDetails',
  }, 
  {
    exact: true,
    path: '/settings',
    name: 'Settings',
    component: Settings,
    key: 'Settings',
  },{
    exact: true,
    path: '/marketplace',
    name: 'Marketplace',
    component: Marketplace,
    key: 'Marketplace'
  },{
    exact: true,
    path: '/cart',
    name: 'Cart',
    component: Cart,
    key: 'Cart'
  },{
    exact: true,
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    key: 'Checkout'
  },{
    exact: true,
    path: '/success_payment',
    component: SuccessCheckout,
    key: 'success_payment'
  },{
    exact: true,
    path: '/payment_history',
    component: PaymentHistory,
    key: 'payment_history'
  },{
    exact: true,
    path: '/module/:module_id',
    component: ModuleDetails,
    key: 'module_details'
  },
  {
    exact: true,
    path: '/modules',
    component: Modules,
    key: 'modules'
  },
  {
    exact: true,
    path: '/pricing',
    component: Pricing,
    key: 'pricing'
  },
  {
    exact: true,
    path: '/users_admin',
    component: UserAdmin,
    key: 'users_admin'
  },
  {
    exact: true,
    path: '/user_product',
    component: UserProduct,
    key: 'user_product'
  },
  {
    exact: true,
    path: '/user_roles',
    component: UserRoleAdmin,
    key: 'user_roles'
  },
  {
    exact: true,
    path: '/user_roles/:role_id',
    component: UserRoleAdminDetails,
    key: 'permission'
  },{
    exact: true,
    path: '/subscribed_user',
    component: SubscribedUser
  },{
    exact: true,
    path: '/subscribed_user/:user_id',
    component: SubscribedUserCompany
  },{
    exact: true,
    path: '/subscribed_user_modulelines/:company_id',
    component: SubscribedUserCompanyModule
  },
  {
    exact: true,
    path: '',
    name: 'Comp404',
    component: Comp404,
    key: 'comp404',
  }
]

export default mainRoutes;