import { createPaymentHistoryApi, getPaymentHistoryByIntentApi, getPaymentHistoryByUserIdApi } from "../../api";
import { CREATE_PAYMENT_HISTORY, GET_PAYMENT_HISTORY_BY_PAYMENT_INTENT, GET_PAYMENT_HISTORY_BY_USER_ID } from "../constants/actionTypes";

export const getPaymentHistoryByIntent = (payment_intent) => async (dispatch) => {
    console.log('payment_intent', payment_intent)
    try {
      const { data } = await getPaymentHistoryByIntentApi(payment_intent);  
      dispatch({ type: GET_PAYMENT_HISTORY_BY_PAYMENT_INTENT, payload: data });
      return data
    } catch (error) {
      console.log('In getPaymentHistoryByIntent',error);
    }
};


export const createPaymentHistory = (payment_history)=> async (dispatch)=>{
    try {
      const { data } = await createPaymentHistoryApi(payment_history);  
      dispatch({ type: CREATE_PAYMENT_HISTORY, payload: payment_history });
      return data
    } catch (error) {
      console.log('In createPaymentHistory',error);
    }
  }

  
export const getPaymentHistoryByUserId = (user_id) => async (dispatch) => {
  console.log('user_id', user_id)
  try {
    const { data } = await getPaymentHistoryByUserIdApi(user_id);  
    dispatch({ type: GET_PAYMENT_HISTORY_BY_USER_ID, payload: data });
    return data
  } catch (error) {
    console.log('In getPaymentHistoryByUserId',error);
  }
};