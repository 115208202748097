import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { CompanyForm } from '../../components'
import { API } from '../../env'

const Account = (props) => {  
  const [companies, setCompanies] = useState([])
  let userDetails = JSON.parse(localStorage.getItem('profile'))
  // console.log('-- In userDetails:>>', userDetails)
  useEffect(() => {
    
    const fetchData = async ()=>{
      API.get(`/company?user_id=${userDetails?.result?._id}`)
        .then((res) => {
          const {data} = res
          console.log('-- In Account data:>>', data)
          setCompanies(data)        }).catch((err)=>{
            console.error('error log companies:>>',err)
        })
    }
    fetchData();
  }, [])
  
  return (
    <>
        {/* <h6>{JSON.stringify(companies)}</h6> */}
        <div className="card mb-3">
            <div className="bg-h
            older d-none d-lg-block bg-card" style={{backgroundImage:'url(/assets/img/icons/spot-illustrations/corner-4.png)'}}></div>
            {/* <!--/.bg-holder--> */}
            <div className="card-body position-relative">
              <div className="row">
                <div className="col-lg-8">
                  <h3>My Companies</h3>
                  <p className="mb-0">List of companies, you will find in the details about the choosen plan and your payment due date and many more features.</p>
                  {/* <a className="btn btn-link btn-sm ps-0 mt-2" href="https://getbootstrap.com/docs/5.1/utilities/float/" >Float on Bootstrap<span className="fas fa-chevron-right ms-1 fs--2"></span></a> */}
                </div>
              </div>
            </div>
          </div>

          
          <div className="row g-3 mb-3">

            {companies.map((company, index)=>(
              <div key={index} className="col-sm-6 col-md-4">
                <div className="card overflow-hidden h-100" style={{minWidth: '12rem', maxHeight: '7.5rem'}}>
                  <div className="bg-holder bg-card" style={{backgroundImage:`url(assets/img/icons/spot-illustrations/corner-${Math.floor(Math.random() * 5)+1}.png)`}}></div>
                  {/* <!--/.bg-holder--> */}
                  <div className="card-body position-relative">
                    <h6>{company.name}</h6>
                    <div className="display-4 fs-4 mb-2 fw-normal font-sans-serif text-warning" data-countup='{"endValue":58.386,"decimalPlaces":2,"suffix":"k"}'>
                      {{...company.modules} && Object.keys({...company.modules}).reduce((prev, curr)=>{
                        let tmp = {...company.modules}[curr];
                        console.log('--- tmp', tmp)
                        prev = prev + Object.keys(tmp).length;
                        return prev
                    },0) || 0}
                    </div><a className="fw-semi-bold fs--1 text-nowrap" href={`/company/${company._id}`}>Details<span className="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a>
                  </div>
                </div>
              </div>

            ))}

            <div className='col-sm-6 col-md-4'>
              <div data-bs-toggle="modal" data-bs-target="#error-modal" className="d-flex" style={{ minHeight:'7.5rem',cursor: 'pointer',borderStyle: 'dashed', borderRadius: '8px', color: 'var(--falcon-navbar-vertical-link-active-color)', justifyContent: 'center', alignItems: 'center'}}>

                  <div >
                    <span className="fa fa-plus-circle fs-3 me-2"></span>
                  </div>
                
              </div>
            </div>
            
          </div>
          
          <div className="modal fade" id="error-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth: '500px'}}>
              <div className="modal-content position-relative">
                <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                  <button className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body p-0">
                  <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h4 className="mb-1" id="modalExampleDemoLabel">Create Company</h4>
                  </div>
                  <div className="p-4 pb-0">
                    <CompanyForm setCompanies={setCompanies} />
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-secondary" id="close-create-company-modal"  type="button" data-bs-dismiss="modal">Close</button>
                  <button className="btn btn-primary"  form="login-form" type="submit">Submit </button>
                </div>
              </div>
            </div>
          </div>

    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Account)