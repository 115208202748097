import { fetchRolesApi, getPermissionByRoleIdApi } from "../../api";
import { CREATE_PERMISSION_CRUD, DELETE_PERMISSION_CRUD, GET_PERMISSION_BY_ROLE_ID, UPDATE_PERMISSION_CRUD } from "../constants/actionTypes";

export const getPermissionByRoleId = (role_id) => async (dispatch) => {
    // console.log('In getPermissionByRoleId role_id:>>', role_id)
    try {
      const { data } = await getPermissionByRoleIdApi(role_id);
  
      dispatch({ type: GET_PERMISSION_BY_ROLE_ID, payload: data });
      return data
    } catch (error) {
      console.log('In getPermissionByRoleId',error);
      // setMessage({code: error.response?.status || 500, message: error.response ? error.response.data : error})
    }
  };
  // export const computePermission = (module_name, action) => async (dispatch) => {

  // }
// createPermisionSuccess
export const createPermisionSuccess = permission => {
  return {
    type: CREATE_PERMISSION_CRUD,
    payload: permission,
  };
};
// updatePermisionSuccess
export const updatePermisionSuccess = permission_id => {
  return {
    type: UPDATE_PERMISSION_CRUD,
    payload: permission_id,
  };
};

// deleteRoleCrudSuccess
export const deletePermisionSuccess = permission_id => {
  return {
    type: DELETE_PERMISSION_CRUD,
    payload: permission_id,
  };
};