import React from 'react'
import { connect } from 'react-redux'

const SignUp = (props) => {
  return (
    <div>
        SignUp
    </div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SignUp)