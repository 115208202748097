
import { deletePricingsCrudApi, fetchPricingsCrudApi} from '../../api'
import { CREATE_PRICING, DELETE_PRICING, FETCH_PRICING_CRUD, UPDATE_PRICING } from '../constants/actionTypes';


// fetchPricingsSuccess
export const fetchPricingsSuccess = pricings => {
  return {
    type: FETCH_PRICING_CRUD,
    payload: pricings,
  };
};

export const fetchPricingsCrud = (formData, router) => async (dispatch) => {
  try {
    const { data } = await fetchPricingsCrudApi();
    console.log('-- fetchPricingsCruddate', data)
    dispatch(fetchPricingsSuccess(data));
    return data
    // router.push('/');
  } catch (error) {
    console.log('In fetchPricings',error);
  }
};


// createPricingSuccess
export const createPricingSuccess = pricing => {
  // message.success({ content: 'Pricing Created!', key, duration: 2 });
  return {
    type: CREATE_PRICING,
    payload: pricing,
  };
};
// updatePricingSuccess
export const updatePricingSuccess = number_of_user => {
  // message.success({ content: 'Pricing Created!', key, duration: 2 });
  return {
    type: UPDATE_PRICING,
    payload: number_of_user,
  };
};
export const deletePricingSuccess = number_of_user => {
  // message.success({ content: 'Pricing Created!', key, duration: 2 });
  return {
    type: DELETE_PRICING,
    payload: number_of_user,
  };
};


export const deletePricingCrud = (number_of_user) => async (dispatch) => {
  try {
    const { data } = await deletePricingsCrudApi(number_of_user);
    console.log('-- deletePricingCrud', data)
    dispatch(deletePricingSuccess(number_of_user));
    return number_of_user
    // router.push('/');
  } catch (error) {
    console.log('In fetchPricings',error);
  }
};