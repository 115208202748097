import * as actionType from '../constants/actionTypes';

const initialState = {
    permissionList: []
}
const user = JSON.parse(localStorage.getItem('profile'))?.result
const permissionReducers = (state = initialState, { type, payload }) => {
  switch (type) {

  case actionType.GET_PERMISSION_BY_ROLE_ID:
    // compute permission : create an object with key module name e.g: pricing and value e.g : edit
    console.log('permissionReducers payload', payload)
    let computedPermissions = {}
    if (payload instanceof Array ){
      computedPermissions = payload.reduce((acc, curr)=>{
        // console.log('acc, curr', acc, curr)
        acc[`${curr.module_name}`] = {}
        acc[`${curr.module_name}`] = {
          can_view:  curr.has_read || false,
          can_edit : curr.has_update || false,
          can_create : curr.has_create || false,
          can_delete : curr.has_delete || false,
        }
        return acc
      },{})
    }
    // console.log('in permissionReducers computedPermissions:>>', computedPermissions)
    return { 
        ...state, 
        permissionList: payload,
        user_permissions : user.role_id === payload[0]?.role_id || '' ? computedPermissions : {...state.user_permissions}
     }
     case actionType.CREATE_PERMISSION_CRUD:
       return {
         ...state,
         loading: false,
         permissionList: [...state.permissionList, payload],
         error: '',
       };    

     case actionType.UPDATE_PERMISSION_CRUD:
      // console.log('-- reducer UPDATE_PERMISSION_CRUD:>>', payload)
      return {
        ...state,
        permissionList: [...state.permissionList.map((permission) => {
          if (permission._id !== payload._id) {
            return permission
          }
          // console.log('in condition true', payload)
          return payload
        })]
      }
      case actionType.DELETE_PERMISSION_CRUD:
        return {
          ...state,
          permissionList: [...state.permissionList.filter((permission) => permission._id != payload)]
        }
  default:
    return state
  }
}

export default permissionReducers