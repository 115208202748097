import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js'
import { API_BASE, BACKEND_DOMAIN, STRIPE_PUBLIC_KEY } from '../../env';
import { fetchClientKey, fetchPricings, fetchStripePublicKey } from '../../redux/actions/pricingActions';
import { addSubscribedModules, getCompanyById } from '../../redux/actions/companyAction'
import CheckoutForm from './CheckoutForm';
import { fetchModuleLines } from '../../redux/actions/modulelines';
import { fetchModules } from '../../redux/actions/modules';
import {Helmet} from "react-helmet"; 
import { ClipLoader } from "react-spinners";
import { computeModulePrice, computePackageTotalAmount } from '../../utils/checkOutUtils';
// import { Paypal } from '../../components';

const Checkout = (props) => {
  const [computeOrderSummary, setComputeOrderSummary] = useState({})
  const [paymentCycle, setPaymentCycle] = useState(localStorage.getItem('paymentCycle'))
  const [numberOfusers, setNumberOfusers] = useState(localStorage.getItem('numberOfusers'))
  const [payable_total, setPayable_total] = useState(1)
  const [paypalClientId, setpaypayClientId] = useState('')
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('stripe')
  const [secretKey, setSecretKey] = useState(null)
  const [disableSubmitButton, setDisableSubmitButton] = useState(false)
  // const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);  
  const [stripePromise, setStripePromise] = useState(() => loadStripe(STRIPE_PUBLIC_KEY))
  const { pricing } = props.company
  useEffect(() => {
    const fetchData = async () => {
      console.log('props', props)
      let stripe_key =  STRIPE_PUBLIC_KEY;//await props.fetchStripePublicKey()
      const fetched_modules = await props.fetchModules()
      const fetched_modulelines = await props.fetchModuleLines()  
      const fetched_pricing = await props.fetchPricings()
      let company_id = localStorage.getItem('company_id')
      let fetched_company = await props.getCompanyById(company_id)
      // const fetched_paypal_client_id = axios.get(`${BACKEND_DOMAIN}/paypal_payment_method/client_id`).then(res=>{
      //   const {data} =res
      //   console.log('client_id', data)
      // })
      const loadedCheckedState = JSON.parse(localStorage.getItem('checkedState'))
      // in case there aint product in local storage go back to market page
      if (loadedCheckedState === null){
        window.location.href = "./marketplace"
      }
      let _paymentCycle = props.history.location.state.paymentCycle || localStorage.getItem('paymentCycle')
      let _numberOfusers = props.history.location.state.numberOfusers  || localStorage.getItem('numberOfusers')
      setPaymentCycle(_paymentCycle)
    setNumberOfusers(_numberOfusers)
      let _total = funcyionComputeOrderSummary(loadedCheckedState, true, fetched_modulelines, _paymentCycle, fetched_pricing.price_ranges, _numberOfusers)
      let _client_secret_key = await props.fetchClientKey({
        amount: Math.round(_total * 100),
        currency: 'usd',
        payment_method_types: ['card'],
        loadedCheckedState,
        company_id,
      })
      setSecretKey(_client_secret_key)
      setPayable_total(_total)
      let _computeOrderSummary = funcyionComputeOrderSummary(loadedCheckedState, false, fetched_modulelines, _paymentCycle, fetched_pricing.price_ranges, _numberOfusers)
      setComputeOrderSummary({computeOrderSummary:{..._computeOrderSummary}})
      // get company
      return {stripe_key, fetched_modules, fetched_modulelines,fetched_pricing, fetched_company};//fetched_paypal_client_id};
    }
    fetchData().then(result=>{
      // setpaypayClientId(result.paypal_client_id)
      });
  }, [])  
  // console.log('computeOrderSummary[oneKey]', computeOrderSummary)
  
  const handleSelectPaymentmethod = (e) =>{
    console.log('In handleSelectPaymentmethod:>>', e.target.value)
    setSelectedPaymentMethod(e.target.value)
  }

  return (
    <>
    {paypalClientId || secretKey && 
        <Helmet>
          {/* <script src={`https://localhost:3000/assets/js/paypal_button_script.js`} type="text/javascript" /> */}
          {/* <script src={`https://www.paypal.com/sdk/js?client-id=${paypalClientId}`}></script> */}
      </Helmet>
    }
        <div className="row g-3">
            <div className="col-xl-4 order-xl-1">
              
            <div className="card">
                <div className="card-header bg-light btn-reveal-trigger d-flex flex-between-center">
                  <h5 className="mb-0">Order Summary</h5><a className="btn btn-link btn-sm btn-reveal text-600" href="/cart"><span className="fas fa-pencil-alt"></span></a>
                </div>
                <div className="card-body">
                  <table className="table table-borderless fs--1 mb-0">
                    <tbody>
                        {
                          computeOrderSummary.computeOrderSummary && Object.keys(computeOrderSummary.computeOrderSummary).map((oneKey,i)=>{
                            return Object.keys(computeOrderSummary.computeOrderSummary[oneKey]).map((subKey,_i)=>{
                              return (
                                <tr className="border-bottom" key={_i}>
                                  <th className="ps-0 pt-0 p-1">{computeOrderSummary.computeOrderSummary[oneKey][subKey].name}<div className="text-400 fs-xx-small fs--2">{paymentCycle}</div>
                                  </th>
                                  <th className="pe-0 text-end pt-0">${computeOrderSummary.computeOrderSummary[oneKey][subKey].price && computeModulePrice(computeOrderSummary.computeOrderSummary[oneKey][subKey].price[paymentCycle], paymentCycle, numberOfusers)}</th>
                                </tr>  
                              )
                            })
                          })      
                        }   
                        
                        <tr className="border-bottom">
                          <th className="ps-0 pt-0 p-1">User redution<div className="text-400 fs-xx-small fs--2">Number of user selected: {numberOfusers}/Users - {paymentCycle}</div>
                          </th>
                          <th className="pe-0 text-end pt-0">- ${props.price_ranges[numberOfusers]?.number?.promotion}</th>
                        </tr>    
                    </tbody>
                  </table>
                </div>
                <div className="card-footer d-flex justify-content-between bg-light">
                  <div className="fw-semi-bold">Payable Amount</div>
                  <div className="fw-bold">${parseFloat(payable_total).toFixed(2)}</div>
                </div>
                <div className="card-footer d-flex justify-content-between bg-light">
                  <div className="fw-semi-bold">Package Amount</div>
                  <div className="fw-bold">${computePackageTotalAmount(payable_total, paymentCycle, numberOfusers, pricing)}</div>
                </div>
              </div>

            </div>
            <div className="col-xl-8" >
              <div className="card mb-3" style={{display: 'none'}}>
                <div className="card-header bg-light">
                  <div className="row flex-between-center">
                    <div className="col-sm-auto">
                      <h5 className="mb-2 mb-sm-0">Your Shipping Address</h5>
                    </div>
                    <div className="col-sm-auto"><a className="btn btn-falcon-default btn-sm" href="#!"><span className="fas fa-plus me-2" data-fa-transform="shrink-2"></span>Add New Address </a></div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6 mb-3 mb-md-0">
                      <div className="form-check mb-0 custom-radio radio-select"><input defaultChecked={true} className="form-check-input" id="address-1" type="radio" name="clientName"  /><label className="form-check-label mb-0 fw-bold d-block" htmlFor="address-1">Antony Hopkins<span className="radio-select-content"><span> 2392 Main Avenue,<br/>Pensaukee,<br/>New Jersey 02139<span className="d-block mb-0 pt-2">+(856) 929-229</span></span></span></label><a className="fs--1" href="#!">Edit</a></div>
                    </div>
                    <div className="col-md-6">
                      <div className="position-relative">
                        <div className="form-check mb-0 custom-radio radio-select"><input className="form-check-input" id="address-2" type="radio" name="clientName" /><label className="form-check-label mb-0 fw-bold d-block" htmlFor="address-2">Robert Bruce<span className="radio-select-content"><span>3448 Ile De France St #242<br/>Fort Wainwright, <br/>Alaska, 99703<span className="d-block mb-0 pt-2">+(901) 637-734</span></span></span></label><a className="fs--1" href="#!">Edit</a></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header bg-light">
                  <h5 className="mb-0">Payment Method</h5>
                </div>
                <div className="card-body">
                  <div>
                    <div className="form-check mb-0">                      
                      <input className="form-check-input" onChange={handleSelectPaymentmethod} type="radio" value="stripe" checked={selectedPaymentMethod === 'stripe'} id="credit-card" name="payment-method" />
                      <label className="form-check-label mb-2 fs-1" htmlFor="credit-card">Credit Card</label>
                    </div>
                    {selectedPaymentMethod === 'stripe'? (
                      
                    <div className="row gx-0 ps-2 mb-4">
                    {/* Stripe Elements*/}
                    <div className="col-sm-8 px-3" style={{transition: 'all 2s'}}>
                    {(STRIPE_PUBLIC_KEY !== null && stripePromise !== null && props.client_secret_key !== undefined ) ? (

                    <Elements apiKey={STRIPE_PUBLIC_KEY} stripe={stripePromise}
                    options= {{
                      clientSecret: props.client_secret_key
                    }}>

                        {Object.keys(computeOrderSummary).length >= 1 && <CheckoutForm setDisableSubmitButton={setDisableSubmitButton} history={props.history} computeOrderSummary={computeOrderSummary.computeOrderSummary} payable_total={payable_total} numberOfusers={numberOfusers} paymentCycle={paymentCycle} addSubscribedModules={props.addSubscribedModules} company={props.company}/>}
                    </Elements> 

                    ):null}
                    </div>

                    <div className="col-4 ps-3 text-center pt-2 d-none d-sm-block">
                      <div className="rounded-1 p-2 mt-3 bg-100">
                        <div className="text-uppercase fs--2 fw-bold">We Accept</div><img src="../../assets/img/icons/icon-payment-methods-grid.png" alt="" width="120" />
                      </div>
                    </div>
                  </div>
                    ):null}
                    <div className="form-check d-flex align-items-center">
                        <input className="form-check-input" value="paypal" checked={selectedPaymentMethod === 'paypal'} onChange={handleSelectPaymentmethod} type="radio" id="paypal" name="payment-method" />
                        <label className="form-check-label mb-0 ms-2" htmlFor="paypal">
                          <img src="/assets/img/icons/icon-paypal-full.png" height="20" alt="" />
                        </label>
                    </div>
                    {selectedPaymentMethod === 'paypal' ? (
                      <>
                    {/* <div className="row gx-0 ps-2 mb-4 pb-3"> */}
                        {/* <Paypal paymentCycle={paymentCycle} /> */}
                    {/* </div> */}
                    </>
                    ):null}
                    
                    <div className="border-dashed-bottom my-5"></div>
                    <div className="row">
                      <div className="col-md-7 col-xl-12 col-xxl-7 px-md-3 mb-xxl-0 position-relative">
                        <div className="d-flex"><img className="me-3" src="../../assets/img/icons/shield.png" alt="" width="60" height="60" />
                          <div className="flex-1">
                            <h5 className="mb-2">Buyer Protection</h5>
                            <div className="form-check mb-0"><input className="form-check-input" id="protection-option-1" type="checkbox" defaultChecked={true} /><label className="form-check-label mb-0" htmlFor="protection-option-1"> <strong>Full Refund </strong>If you don't <br className="d-none d-md-block d-lg-none" />receive your order</label></div>
                            <div className="form-check"><input className="form-check-input" id="protection-option-2" type="checkbox" defaultChecked={true} /><label className="form-check-label mb-0" htmlFor="protection-option-2"> <strong>Full or Partial Refund, </strong>If the product is not as described in details</label></div><a className="fs--1 ms-3 ps-2" href="#!">Learn More<span className="fas fa-caret-right ms-1" data-fa-transform="down-2">    </span></a>
                          </div>
                        </div>
                        <div className="vertical-line d-none d-md-block d-xl-none d-xxl-block"> </div>
                      </div>
                      <div className="col-md-5 col-xl-12 col-xxl-5 ps-lg-4 ps-xl-2 ps-xxl-5 text-center text-md-start text-xl-center text-xxl-start">
                        <div className="border-dashed-bottom d-block d-md-none d-xl-block d-xxl-none my-4"></div>
                        <div className="fs-2 fw-semi-bold">Package Price: <span className="text-primary">${computePackageTotalAmount(payable_total, paymentCycle, numberOfusers, pricing)}</span></div><button disabled={disableSubmitButton} form="strip_form_overxls" className="btn btn-success mt-3 px-5" type="submit" ><span style={{marginRight: '.4em'}}>Confirm &amp; Pay</span><ClipLoader color={"#fff"} loading={disableSubmitButton} size={20} /></button>
                        <p className="fs--1 mt-3 mb-0">By clicking <strong>Confirm & Pay </strong>button you agree to the <a href="#!">Terms &amp; Conditions</a></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </>
  )
}

const mapStateToProps = (state) => {
  // console.log('state :>>', state)
  return {
    moduleLineList: state.moduleline.moduleLineList,
    moduleList: state.module.moduleList,
    price_ranges: state.pricing.pricingData.price_ranges,
    stripe_public_key : state.pricing.stripe_public_key,
    client_secret_key : state.pricing.client_secret_key,
    company: state.companyReducer.company,
  }
}

const mapDispatchToProps = dispatch => {
    return {
      fetchStripePublicKey: ()=> dispatch(fetchStripePublicKey()),
      fetchClientKey: (...data)=> dispatch(fetchClientKey(...data)),
      fetchPricings: ()=> dispatch(fetchPricings()),
      fetchModuleLines: () => dispatch(fetchModuleLines()),
      fetchModules: () => dispatch(fetchModules()),
      addSubscribedModules:(data)=> dispatch(addSubscribedModules(data)),
      getCompanyById:(_id)=> dispatch(getCompanyById(_id)),
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)

export const funcyionComputeOrderSummary = (result=false, total=false,moduleLineList, paymentCycle, price_ranges, numberOfusers) => {
  // console.log('result, moduleLineList, paymenCycle, price_range, numberOfusers', result, moduleLineList, paymentCycle, price_ranges, numberOfusers)
  if (!result || !moduleLineList || !paymentCycle || !price_ranges || !numberOfusers) return {}
  let computedSubmodule = {}
  let _totatPriceSubmodule = 0
  for (const key in result) {
      // console.log('key', key)
      if (key){
        for (const sub_key in result[key]) {
          // console.log('   - sub_key: ', sub_key)
          if (key && key.length > 10 && sub_key.length > 10) {

            // console.log('   - sub_key: ', key,sub_key)
            if (computedSubmodule[key] === undefined) computedSubmodule[key] = {}
            computedSubmodule[key][sub_key] = {...moduleLineList.filter(moduleline=> moduleline._id === sub_key )[0]}
            if(computedSubmodule[key][sub_key]?.price) 
            _totatPriceSubmodule = parseFloat(_totatPriceSubmodule) + parseFloat(computedSubmodule[key][sub_key]?.price[paymentCycle])
          }
        }
      }
      // console.log(`In computeOrderSummary - ${key}: ${JSON.stringify(result[key])}`);
  }
  if (total){
    let tmp_payment_cycle = 1
    switch (paymentCycle) {
      case 'quarterly':
        tmp_payment_cycle = 3
        break;      
      case 'yearly':
        tmp_payment_cycle = 12
        break;     
      default: 
        break;
    }
    return (_totatPriceSubmodule - parseFloat(price_ranges[numberOfusers]?.number?.promotion))*numberOfusers*tmp_payment_cycle
  }
  // setTotatPriceSubmodule(_totatPriceSubmodule)
  return computedSubmodule
}