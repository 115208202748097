import React, { useEffect } from 'react'
import { connect } from 'react-redux'

const Settings = (props) => {
  useEffect(() => {
    console.log('Settings',)
  }, [])
  
  return (
    <div>Settings</div>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)