import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {Modal, Button} from 'react-bootstrap'
import { ModuleForm } from '../../components'
import { fetchModuleLines } from '../../redux/actions/modulelines'
import { createModuleSuccess, deleteModuleSuccess, fetchModules, updateModuleSuccess } from '../../redux/actions/modules'
import { Link } from 'react-router-dom'
import { env_config, API } from '../../env'
import { getPermissionByRoleId } from '../../redux/actions/permissionActions'

const Modules = (props) => {
  const [message, setMessage] = useState(false)
  const [moduleLines, setModuleLines] = useState([])
  const [dataFetched, setDataFetched ] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [updateModule, setUpdateModule] = useState({})  
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {    
    const fetchData = async () => {
      const fetched_modules = await props.fetchModules()
      const fetched_modulelines = await props.fetchModuleLines()
      const _user = JSON.parse(localStorage.getItem('profile')).result
      await props.getPermissionByRoleId(_user.role_id)
      return {fetched_modules, fetched_modulelines};
    }
    fetchData().then(res=>{
      console.log('-- res', res)
      setModuleLines(res.fetched_modulelines)
      setDataFetched(true)

    })
    return () => { 
      setDataFetched(false)
      setModuleLines([])
    }
  }, [])
  const computeNrbAndTotalPriceOfModuleLine = (module) =>{
    console.log('module', module)
    let _module_lines = moduleLines.filter(_module_line=> _module_line.parent === module._id)
    let result = _module_lines.reduce((acc, curr)=>{
      acc.number_of_submodules = acc.number_of_submodules + 1
      acc.total = acc.total + curr.price.monthly
      return acc
    },{total:0, number_of_submodules:0})
    return 0
  }
  const handleEdit = (module) =>{
    console.log('-- handleEdit module', module)
    localStorage.removeItem('module_item')
    // return
    localStorage.setItem('module_item', JSON.stringify(module));
    setIsUpdate(true)
    setUpdateModule(module)
    handleShow()
    // document.querySelector('#table-modules-replace-element').click();
  }
  const handleAdd = ()=>{
    setUpdateModule({})
    setIsUpdate(false);
    handleShow()
    localStorage.removeItem('module_item');
    // document.querySelector('#table-modules-replace-element')?.click();
  }
  const handleFormSubmit = (event)=>{
    event.preventDefault();
    document.querySelector('#module-form').submit()
  }
  const handleDelete = async (id)=>{
      
    const config = env_config;       
  try {
    await API
      .delete(`/modules/${id}`, {}, config)
        .then(res=>{
          const { data } = res
          console.log('in deleteForm responser, res', res)
          props.deleteModuleSuccess(data)
          setMessage({code: res.status, message: "Module successfully deleted"});                
          // props.setCompanies((prev)=>[...prev, data])
          
          setTimeout(() => {
            setMessage(false)
            }, 4000);
        })
  } catch (error) {
    
  }

  }
  return (
    <>
        {message && message.code ? 
            message.code.toString().substr(0,2)  === "20" ? (   
            <div className="alert alert-success" role="alert">
                {message.message}
            </div>
            ) : (
                <div className="alert alert-danger" role="alert">
                {JSON.stringify(message?.message?.message)}
                </div>
            )          
        :null}
        
          <div className="d-flex mb-4 mt-6"><span className="fa-stack me-2 ms-n1"><i className="fa-inverse fa-stack-1x text-primary fas fa-list" data-fa-transform="shrink-2"></i></span>
            <div className="col">
              <h5 className="mb-0 text-primary position-relative"><span className="bg-200 dark__bg-1100 pe-3">Modules List</span><span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span></h5>
              <p className="mb-0">We offer specialized services to large companies that need to sell their products online, with powerful tools and analytics for informed decisions, secure payment gateways, and 24/7 customer support.ts.</p>
            </div>
          </div>
          
          <div className="card z-index-1 mb-3" id="recentPurchaseTable" data-list='{"valueNames":["name","createdBy","product","payment","amount"],"page":8,"pagination":true}'>
            <div className="card-header">
              <div className="row flex-between-center">
                <div className="col-6 col-sm-auto d-flex align-items-center pe-0">
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Modules </h5>
                </div>
                <div className="col-6 col-sm-auto ms-auto text-end ps-0">
                  <div className="d-none" id="table-modules-actions">
                    <div className="d-flex"><select defaultValue="bulk" className="form-select form-select-sm" aria-label="Bulk actions">
                        <option value="bulk">Bulk actions</option>
                        <option value="Refund">Refund</option>
                        <option value="Delete">Delete</option>
                        <option value="Archive">Archive</option>
                      </select><button className="btn btn-falcon-default btn-sm ms-2" type="button">Apply</button></div>
                  </div>
                  {/* data-bs-toggle="modal" data-bs-target="#module-modal" */}
                  <div id="table-modules-replace-element">
                    
                  {props.user_permissions?.module?.can_create ? (
                    <button onClick={()=>handleAdd()} className="btn btn-falcon-default btn-sm" type="button"><span className="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span className="d-none d-sm-inline-block ms-1">New</span></button>
                  ):null}
                    <button className="btn btn-falcon-default btn-sm mx-2" type="button"><span className="fas fa-filter" data-fa-transform="shrink-3 down-2"></span><span className="d-none d-sm-inline-block ms-1">Filter</span></button><button className="btn btn-falcon-default btn-sm" type="button"><span className="fas fa-external-link-alt" data-fa-transform="shrink-3 down-2"></span><span className="d-none d-sm-inline-block ms-1">Export</span></button></div>
                </div>
              </div>
            </div>
            <div className="card-body px-0 py-0">
              <div className="table-responsive scrollbar">
                    {props.user_permissions?.module?.can_view ? (
              <table className="table table-sm fs--1 mb-0 overflow-hidden">
                  <thead className="bg-200 text-900">
                    <tr>
                      <th className="white-space-nowrap">
                        <div className="form-check mb-0 d-flex align-items-center"><input className="form-check-input" id="checkbox-bulk-modules-select" type="checkbox" data-bulk-select='{"body":"table-purchase-body","actions":"table-modules-actions","replacedElement":"table-modules-replace-element"}' /></div>
                      </th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="name">Name</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="product">Description</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="createdBy">Created By</th>
                      <th className="sort pe-1 align-middle white-space-nowrap text-center" data-sort="payment">Is Actif</th>
                      <th className="sort pe-1 align-middle white-space-nowrap text-end" data-sort="amount">Monthly Price</th>
                      <th className="no-sort pe-1 align-middle data-table-row-action"></th>
                    </tr>
                  </thead>
                   <tbody className="list" id="table-purchase-body">
                    {props.moduleList.map((module, index)=>(
                      <tr key={index} className="btn-reveal-trigger">
                        <td className="align-middle" style={{width: "28px"}}>
                          <div className="form-check mb-0"><input className="form-check-input" type="checkbox" id="recent-purchase-0" data-bulk-select-row="data-bulk-select-row" /></div>
                        </td>
                        <th className="align-middle white-space-nowrap name">
                          
                        {props.user_permissions?.moduleline?.can_view ? (
                          <Link to={`/module/${module._id}`}>{module.name}</Link>
                        ):<span>{module.name}</span>}
                          </th>
                        <td className="align-middle white-space-nowrap product" style={{cursor:'pointer'}} data-bs-toggle="tooltip" data-bs-placement="top" title={`${module.description}`}>{module.description && module.description.substring(1, 50)} {module.description && module.description.length >= 50 && "..."}</td>
                        <td className="align-middle white-space-nowrap createdBy">{module.createdBy?.last_name || 'n/a'}</td>
                        {module.is_active ? (
                          <td className="align-middle text-center fs-0 white-space-nowrap payment"><span className="badge badge rounded-pill badge-soft-success">Success<span className="ms-1 fas fa-check" data-fa-transform="shrink-2"></span></span></td>
                        ): (
                          <td className="align-middle text-center fs-0 white-space-nowrap payment"><span className="badge badge rounded-pill badge-soft-secondary">Disabled<span className="ms-1 fas fa-ban" data-fa-transform="shrink-2"></span></span></td>
                        )}
                        {moduleLines && <td className="align-middle text-end amount">{(function(){
                          // sum the monthly price of submodules per current module
                            let _module_lines = moduleLines.filter(_module_line=> _module_line.parent === module._id)
                            let result = _module_lines.reduce((acc, curr)=>{
                              acc.number_of_submodules = acc.number_of_submodules + 1
                              acc.total = acc.total + parseFloat(curr.price?.monthly || 0)
                              return acc
                            },{total:0, number_of_submodules:0})
                            return `$ ${parseFloat(result.total, 2).toFixed(2)}`
                        })()}</td>}
                        <td className="align-middle white-space-nowrap text-end">
                          <div className="dropstart font-sans-serif position-static d-inline-block"><button className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal float-end" type="button" id="dropdown0" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span className="fas fa-ellipsis-h fs--1"></span></button>
                            <div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="dropdown0"><a className="dropdown-item" href="#!">View</a>
                            
                            {props.user_permissions?.module?.can_edit ? (
                            <a className="dropdown-item" href="#edit" onClick={()=>handleEdit(module)}>Edit</a>
                            ) :null}
                            
                            <a className="dropdown-item" href="#!">Refund</a>
                              <div className="dropdown-divider"></div><a className="dropdown-item text-warning" href="#!">Archive</a>
                              
                            {props.user_permissions?.module?.can_delete ? (
                              <a className="dropdown-item text-danger" href="#delete" onClick={()=>handleDelete(module._id)}>Delete</a>
                            ) :null}

                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}      
                    <tr></tr>              
                  </tbody>
                </table>
                ): <h6 className='ml-5' style={{marginLeft: '1.7em'}}>{"Your are not allowed to view this"}</h6>}

              </div>
            </div>
            <div className="card-footer">
              <div className="row align-items-center">
                <div className="pagination d-none"></div>
                <div className="col">
                  <p className="mb-0 fs--1"><span className="d-none d-sm-inline-block me-2" data-list-info="data-list-info"> </span><span className="d-none d-sm-inline-block me-2">&mdash;  </span><a className="fw-semi-bold" href="#!" data-list-view="*">View all<span className="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a><a className="fw-semi-bold d-none" href="#!" data-list-view="less">View less<span className="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a></p>
                </div>
                <div className="col-auto d-flex"><button className="btn btn-sm btn-primary" type="button" data-list-pagination="prev"><span>Previous</span></button><button className="btn btn-sm btn-primary px-4 ms-2" type="button" data-list-pagination="next"><span>Next</span></button></div>
              </div>
            </div>
          </div>
          
      <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{`${isUpdate ? 'Update':'Create'}`} Module</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
            {isUpdate ? 
              ((Object.keys(updateModule).length > 0 && <ModuleForm handleClose={handleClose} updateModuleSuccess={props.updateModuleSuccess} updateModule={updateModule} setIsUpdate={setIsUpdate} isUpdate={isUpdate} createModuleSuccess={props.createModuleSuccess}/>))
              :
                (<ModuleForm updateModuleSuccess={props.updateModuleSuccess} handleClose={handleClose} updateModule={updateModule} setIsUpdate={setIsUpdate} isUpdate={isUpdate} createModuleSuccess={props.createModuleSuccess}/>)
            }  
        </Modal.Body>
          <div className="modal-footer">
            <button className="btn btn-secondary" id="close-create-module-modal"  type="button" data-bs-dismiss="modal">Close</button>
            <button className="btn btn-primary"  form="module-form" type="submit">Submit </button>
          </div>
      </Modal>
    </>
          
    </>
  )
}

const mapStateToProps = (state) => {
  // console.log('state', state)
  return {
    moduleLineList: state.moduleline.moduleLineList,
    moduleList: state.module.moduleList,
    user_permissions: state.permission.user_permissions,
  }
}

const mapDispatchToProps = dispatch =>{
  return {
	  fetchModules: () => dispatch(fetchModules()),
	  fetchModuleLines: () => dispatch(fetchModuleLines()),
    createModuleSuccess:(module) => dispatch(createModuleSuccess(module)),
    updateModuleSuccess:(module)=>dispatch(updateModuleSuccess(module)),
    deleteModuleSuccess:(module_id)=>dispatch(deleteModuleSuccess(module_id)),
    getPermissionByRoleId: (role_id) => dispatch(getPermissionByRoleId(role_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modules)