import { moment } from 'moment'
import React, {useEffect, useState} from 'react'
import { connect } from 'react-redux'

import { Link, useParams } from 'react-router-dom'
import { fetchModuleLines, updateModuleLineSuccess, createModuleLineSuccess, deleteModuleLineSuccess } from '../../redux/actions/modulelines'
import { getModuleById } from '../../redux/actions/modules'
// import {Ratio} from 'react-bootstrap'
import axios from 'axios'
import { Button, Modal } from 'react-bootstrap'
import ModuleLineForm from '../../components/ModuleLineForm/ModuleLineForm'
import { env_config, API } from '../../env'
import { getPermissionByRoleId } from '../../redux/actions/permissionActions'
        
const config = env_config;  

const ModuleDetails = (props) => {
    const { module_id } = useParams()
    const [module, setModule] = useState({})
    const [numberOfSubmodule, setNumberOfSubmodule] = useState(0)
    const [moduleLines, setModuleLines] = useState([])
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState({})
    const [isUpdate, setIsUpdate] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true); 
    const [updateModuleLine, setUpdateModuleLine] = useState({})  
    useEffect(() => {
        localStorage.setItem('module_id',module_id)
        const fetchData = async ()=>{          
          const _user = JSON.parse(localStorage.getItem('profile')).result
          await props.getPermissionByRoleId(_user.role_id)
            let fetched_module = await props.getModuleById(module_id)
            const fetched_modulelines = await props.fetchModuleLines()
            return {fetched_module, fetched_modulelines: fetched_modulelines.filter(module => module.parent === module_id)}
          }
          fetchData().then(res=>{
            console.log('res', res)
            setModule(res.fetched_module)
            setNumberOfSubmodule(res.fetched_modulelines.length)
            setModuleLines(res.fetched_modulelines)
            // res.fetched_module.modules && setNumberOfModules(Object.keys(res.fetched_module.modules).length)
          })
    
    }, [])
    const handleAdd = () =>{
      localStorage.removeItem('moduleline_item')
      setUpdateModuleLine({})
      handleShow()
      setIsUpdate(false)
    }
    const handleDelete = async(id) =>{      
  try {
    await API
      .delete(`/modulelines/${id}`, {}, config)
        .then(res=>{
          const { data } = res
          console.log('in deleteForm responser, res', res)
          props.deleteModuleLineSuccess(id)
          setMessage({code: res.status, message: "Module successfully deleted"});                
          // props.setCompanies((prev)=>[...prev, data])
          
          setTimeout(() => {
            setMessage(false)
            }, 4000);
        })
  } catch (error) {
    
  }
    }
    const handleEdit = (moduleline) =>{
      console.log('-- handleEdit moduleline', moduleline)
      localStorage.removeItem('moduleline_item')
      localStorage.setItem('moduleline_item', JSON.stringify(moduleline));
      setIsUpdate(true)
      setUpdateModuleLine(moduleline)
      handleShow()
    }
    const handleChangeActive = (e, moduleline)=> {
      e.preventDefault()
      let _checked = e.target.checked;
      console.log('-- e, moduleline', e, moduleline, 'checked:>>', _checked);
      moduleline.is_active = _checked;
      let _user = JSON.parse(localStorage.getItem('profile'));
      console.log('_user:>>', _user.result);
      moduleline.updatedBy = _user.result;
      (async ()=> {
        // console.log('-- update values', values)
        await API
          .put(`/modulelines/${moduleline._id}`, {
            ...moduleline
          }, config)
            .then(res=>{
              const { data } = res
              console.log('in handleChangeActive responser, res', res)
              props.updateModuleLineSuccess(data)
              setMessage({code: res.status, message: "Module successfully updated"});
              setTimeout(() => {
                  // document.querySelector('#close-create-module-modal').click();
                  localStorage.removeItem('module_item')
                }, 2000);
            })
      })()
      
    }
  return (
    <>        
    
    {message && message.code ? 
                message.code.toString().substr(0,2)  === "20" ? (   
                <div className="alert alert-success" role="alert">
                    {message.message}
                </div>
                ) : (
                    <div className="alert alert-danger" role="alert">
                    {JSON.stringify(message?.message?.message)}
                    </div>
                )          
            :null}
        {module && 
        <div className="card mb-3">
            <div className="bg-holder d-none d-lg-block bg-card" style={{backgroundImage:'url(/assets/img/icons/spot-illustrations/corner-4.png)',opacity: '0.7'}}></div>
            
            <div className="card-body position-relative">
              <div className='d-flex'>
                <div className=''>
                <Link className='f--1' to='/modules'><span className="fas fa-arrow-left me-2"></span></Link>
                
                </div>
                 <div className=''>
                  
                  <h5>{module.name}</h5>
                  <p className="mb-0">{module.description}</p>
                  {module.created_at && <p className="fs--1">{module.createdAt && moment(Date('06-06-2022')).format('MMMM Do YYYY, h:mm:ss a')}</p>}
                  <a className="btn btn-link btn-sm ps-0 mt-2" href="#" >Select on Bootstrap<span className="fas fa-chevron-right ms-1 fs--2"></span></a>
                </div>
              </div>
              {module.status && <div><strong className="me-2">Status: </strong>
                <div className={`badge rounded-pill badge-soft-${module.status === 'disabled' ? 'warning' : 'success'} fs--2`}>{module.status}<span className="fas fa-check ms-1" data-fa-transform="shrink-2"></span></div>
              </div>}
            </div>
          </div>
        }
          
          <div className="card mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <h5 className="mb-3 fs-0">Module Info</h5>
                  <p className="mb-1 fs--1">{module.location}</p>
                  <p className="mb-0 fs--1"> <strong>Created By: </strong><a href={`mailto:${module.createdBy?.email}`}>{module.createdBy?.first_name || 'N/A'}</a></p>
                  <p className="mb-0 fs--1"> <strong>created At: </strong><a href="#">{module.createdAt || 'N/A'}</a></p>
                </div>
                <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <h5 className="mb-3 fs-0">Total Price</h5>
                  <div className="d-flex justify-content-space-between">                    
                    <div className="flex-1">
                    <h1 className="display-6"><i className='display-6 fas fa-money-bill-wave'></i>{" "}{parseFloat(moduleLines.reduce((acc, curr)=>{
                      let temp_price = curr.price?.monthly || 0
                        acc= acc + parseFloat(temp_price)
                        return parseFloat(acc);
                      },0)).toFixed(2)}<small className="text-muted fw-light">/monthly</small></h1>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <h5 className="mb-3 fs-0">Active ModuleLines</h5>
                  <div className="d-flex justify-content-space-between">
                    
                    <div className="flex-1">
                    <h1 className="display-5">0<small className="text-muted fw-light">/{numberOfSubmodule}</small>{"  "}<i className='display-6 fas fa-boxes'></i></h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="card-footer d-flex justify-content-between align-items-center"><small>{numberOfSubmodule} <span className="d-none d-sm-inline-block"> </span> Modules</small>

            
              <div>
                {/* <Link to='/marketplace' className="btn btn-falcon-default btn-sm ms-1 ms-sm-2" type="button"><span className="fas fa-shopping-cart me-2"></span>
                  Market Place
                </Link> */}
              </div>
            </div>          

          </div>


          {/* table */}
          <div className="card z-index-1 mb-3" id="recentPurchaseTable" data-list='{"valueNames":["name","createdBy","product","is_staff","amount"],"page":8,"pagination":true}'>
            <div className="card-header">
              <div className="row flex-between-center">
                <div className="col-6 col-sm-auto d-flex align-items-center pe-0">
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">ModuleLines </h5>
                </div>
                <div className="col-6 col-sm-auto ms-auto text-end ps-0">
                  <div className="d-none" id="table-modules-actions">
                    <div className="d-flex"><select defaultValue="bulk" className="form-select form-select-sm" aria-label="Bulk actions">
                        <option value="bulk">Bulk actions</option>
                        <option value="Refund">Refund</option>
                        <option value="Delete">Delete</option>
                        <option value="Archive">Archive</option>
                      </select><button className="btn btn-falcon-default btn-sm ms-2" type="button">Apply</button></div>
                  </div>
                  {/* data-bs-toggle="modal" data-bs-target="#module-modal" */}
                  <div id="table-modules-replace-element">
                    
              {props.user_permissions?.moduleline?.can_create ? (
                    <Button onClick={()=>handleAdd()} className="btn btn-falcon-default btn-sm" type="button"><span className="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span className="d-none d-sm-inline-block ms-1">New</span></Button>
              ):null}
                    <button className="btn btn-falcon-default btn-sm mx-2" type="button"><span className="fas fa-filter" data-fa-transform="shrink-3 down-2"></span><span className="d-none d-sm-inline-block ms-1">Filter</span></button><button className="btn btn-falcon-default btn-sm" type="button"><span className="fas fa-external-link-alt" data-fa-transform="shrink-3 down-2"></span><span className="d-none d-sm-inline-block ms-1">Export</span></button></div>
                </div>
              </div>
            </div>
            <div className="card-body px-0 py-0">
              <div className="table-responsive scrollbar">
                
              {props.user_permissions?.moduleline?.can_view ? (
                <table className="table table-sm fs--1 mb-0 overflow-hidden">
                  <thead className="bg-200 text-900">
                    <tr>
                      <th className="white-space-nowrap">
                        <div className="form-check mb-0 d-flex align-items-center"><input className="form-check-input" id="checkbox-bulk-modules-select" type="checkbox" data-bulk-select='{"body":"table-purchase-body","actions":"table-modules-actions","replacedElement":"table-modules-replace-element"}' /></div>
                      </th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="name">Name</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="product">Description</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="modifiedBy">Updated By</th>
                      <th className="sort pe-1 align-middle white-space-nowrap text-center" data-sort="is_staff">Is Actif</th>
                      <th className="sort pe-1 align-middle white-space-nowrap text-end" data-sort="amount">Monthly Price</th>
                      <th className="no-sort pe-1 align-middle data-table-row-action"></th>
                    </tr>
                  </thead>
                   <tbody className="list" id="table-purchase-body">
                    {module_id && props.moduleLines.filter(item=>item.parent === module_id).map((moduleline, index)=>(
                      <tr key={index} className="btn-reveal-trigger">
                        <td className="align-middle" style={{width: "28px"}}>
                          <div className="form-check mb-0"><input className="form-check-input" type="checkbox" id="recent-purchase-0" data-bulk-select-row="data-bulk-select-row" /></div>
                        </td>
                        <th className="align-middle white-space-nowrap name"><h6>{moduleline.name}</h6></th>
                        <td className="align-middle white-space-nowrap product" style={{cursor:'pointer'}} data-bs-toggle="tooltip" data-bs-placement="top" title={`${moduleline.description}`}>{moduleline.description?.substring(1, 50)}</td>
                        <td className="align-middle white-space-nowrap modifiedBy">{moduleline.updatedBy?.last_name || 'n/a'}</td>
                        
                        {/* <td className="align-middle text-center fs-0 white-space-nowrap is_staff"><span className={`badge badge rounded-pill badge-soft-${moduleline.is_active ? 'success' : 'secondary'}`}>{moduleline.is_active ?'enabled':'disabled'}<span className={`ms-1 fas fa-${moduleline.is_active ?'check':'ban'}`} data-fa-transform="shrink-2"></span></span></td> */}
                        <td>
                        <div className={`${moduleline.is_active ? 'form-check form-switch':''}`} style={{display: 'flex', justifyContent:'center'}}>
                          {props.user_permissions && props.user_permissions.moduleline.can_edit ? (
                            <input onChange={(e)=>handleChangeActive(e, moduleline)} value={moduleline.is_active ? 'on' : 'off'} checked={moduleline.is_active} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" style={{fontSize: '1.5em', cursor: 'pointer'}}/>
                            ): <span>{JSON.stringify(moduleline.is_active || false)}</span>}
                        </div>
                        </td>
                        {props.moduleLines && <td className="align-middle text-end amount">$ {moduleline.price?.monthly}</td>}
                        <td className="align-middle white-space-nowrap text-end">
                          <div className="dropstart font-sans-serif position-static d-inline-block"><button className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal float-end" type="button" id="dropdown0" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span className="fas fa-ellipsis-h fs--1"></span></button>
                            <div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="dropdown0"><a className="dropdown-item" href="#!">View</a>
                            
                          {props.user_permissions && props.user_permissions.moduleline.can_edit ? (
                            <a className="dropdown-item" href="#edit" onClick={()=>handleEdit(moduleline)}>Edit</a>
                            ): null}
                            
                            <a className="dropdown-item" href="#!">Refund</a>
                              <div className="dropdown-divider"></div><a className="dropdown-item text-warning" href="#!">Archive</a>
                              
                          {props.user_permissions && props.user_permissions.moduleline.can_delete ? (
                              <a className="dropdown-item text-danger" href="#delete" onClick={()=>handleDelete(moduleline._id)}>Delete</a>
                              ): null}

                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}      
                    <tr></tr>              
                  </tbody>
                </table>
              ): <h6 className='ml-5' style={{marginLeft: '1.7em'}}>{"Your are not allowed to view this"}</h6>}
              </div>
            </div>
            <div className="card-footer">
              <div className="row align-items-center">
                <div className="pagination d-none"></div>
                <div className="col">
                  <p className="mb-0 fs--1"><span className="d-none d-sm-inline-block me-2" data-list-info="data-list-info"> </span><span className="d-none d-sm-inline-block me-2">&mdash;  </span><a className="fw-semi-bold" href="#!" data-list-view="*">View all<span className="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a><a className="fw-semi-bold d-none" href="#!" data-list-view="less">View less<span className="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a></p>
                </div>
                <div className="col-auto d-flex"><button className="btn btn-sm btn-primary" type="button" data-list-pagination="prev"><span>Previous</span></button><button className="btn btn-sm btn-primary px-4 ms-2" type="button" data-list-pagination="next"><span>Next</span></button></div>
              </div>
            </div>
          </div>
        <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{`${isUpdate ? 'Update':'Create'}`} Module</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
              {isUpdate ? 
                ((Object.keys(updateModuleLine).length > 0 && <ModuleLineForm handleClose={handleClose} updateModuleLineSuccess={props.updateModuleLineSuccess} updateModuleLine={updateModuleLine} setIsUpdate={setIsUpdate} isUpdate={isUpdate} createModuleSuccess={props.createModuleSuccess}/>))
                :
                  (<ModuleLineForm handleClose={handleClose} module={module} createModuleLineSuccess={props.createModuleLineSuccess} updateModuleLine={updateModuleLine} setIsUpdate={setIsUpdate} isUpdate={isUpdate} createModuleSuccess={props.createModuleSuccess}/>)
              }  
          </Modal.Body>
            <div className="modal-footer">
              <button className="btn btn-secondary" id="close-create-module-modal"  type="button" data-bs-dismiss="modal">Close</button>
              <button className="btn btn-primary"  form="module-form" type="submit">Submit </button>
            </div>
        </Modal>
      </>
    </>
  )
}

const mapStateToProps = (state) => ({
  moduleLines: state.moduleline.moduleLineList,
  user_permissions: state.permission.user_permissions,
})

const mapDispatchToProps = dispatch => {
  return{
    getModuleById: (id)=> dispatch(getModuleById(id)),
	  fetchModuleLines: () => dispatch(fetchModuleLines()),
    updateModuleLineSuccess: (moduleline)=> dispatch(updateModuleLineSuccess(moduleline)),
    createModuleLineSuccess: (moduleline)=> dispatch(createModuleLineSuccess(moduleline)),
    deleteModuleLineSuccess:(module_id)=>dispatch(deleteModuleLineSuccess(module_id)),
    getPermissionByRoleId: (role_id) => dispatch(getPermissionByRoleId(role_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModuleDetails)