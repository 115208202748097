import React, { useEffect } from 'react'
import { connect } from 'react-redux'

const _user = JSON.parse(localStorage.getItem('profile'))?.result
const SideMenu = (props) => {
  useEffect(() => {   
    // console.log('props', props)
    const navbarPosition = localStorage.getItem('navbarPosition');
    const navbarVertical = document.querySelector('.navbar-vertical');
    const navbarTopVertical = document.querySelector('.content .navbar-top');
    const navbarTop = document.querySelector('[data-layout] .navbar-top');
    const navbarTopCombo = document.querySelector('.content [data-navbar-top="combo"]');
    if (navbarPosition === 'top') {
      navbarTop.removeAttribute('style');
      navbarTopVertical.remove(navbarTopVertical);
      navbarVertical.remove(navbarVertical);
      navbarTopCombo.remove(navbarTopCombo);
    } else if (navbarPosition === 'combo') {
      navbarVertical.removeAttribute('style');
      navbarTopCombo.removeAttribute('style');
      navbarTop.remove(navbarTop);
      navbarTopVertical.remove(navbarTopVertical);
    } else if (navbarPosition === 'vertical') {      
      navbarTopCombo.remove(navbarTopCombo);
    } else {
      navbarVertical.removeAttribute('style');
      navbarTopVertical.removeAttribute('style');
      navbarTop.remove(navbarTop);
      navbarTopCombo.remove(navbarTopCombo);
    }
  }, [])
  
  return (
    <>    
    <nav className="navbar navbar-light navbar-vertical navbar-expand-xl">
          <div className="d-flex align-items-center">
            <div className="toggle-icon-wrapper">

              <button className="btn navbar-toggler-humburger-icon navbar-vertical-toggle" data-bs-toggle="tooltip" data-bs-placement="left" title="Toggle Navigation"><span className="navbar-toggle-icon"><span className="toggle-line"></span></span></button>

            </div><a className="navbar-brand" href="index.html">
              <div className="d-flex align-items-center py-3"><img className="me-2" src="/assets/img/equerre-logo.png" alt="" height={30} />
              </div>
            </a>
          </div>
          <div className="collapse navbar-collapse" id="navbarVerticalCollapse">
            <div className="navbar-vertical-content scrollbar">

              <ul className="navbar-nav flex-column mb-3" id="navbarVerticalNav">
                <li className="nav-item">
                  
                  <a className="nav-link" href="/dashboard" role="button" aria-expanded="false">
                    <div className="d-flex align-items-center"><span className="nav-link-icon"><span className="fas fa-chart-pie"></span></span><span className="nav-link-text ps-1">Dashboard</span>
                    </div>
                  </a>

                </li>
                
                <li className="nav-item">
                  {/* <!-- label--> */}
                  <div className="row navbar-vertical-label-wrapper mt-3 mb-2">

                    <div className="col-auto navbar-vertical-label">
                    </div>
                    <div className="col ps-0">
                      <hr className="mb-0 navbar-vertical-divider" />
                    </div>
                  </div>
                  {/* <!-- user nav --> */}
                  <a className="nav-link dropdown-indicator" href="#user" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="user">
                    <div className="d-flex align-items-center"><span className="nav-link-icon"><span className="fas fa-user"></span></span><span className="nav-link-text ps-1">User</span>
                    </div>
                  </a>
                  <ul className="nav collapse false" id="user">
                    <li className="nav-item"><a className="nav-link" href="/profile" aria-expanded="false">
                        <div className="d-flex align-items-center"><span className="nav-link-text ps-1">Profile</span>
                        </div>
                      </a>
                      {/* <!-- more inner pages--> */}
                    </li>
                    {/* <li className="nav-item"><a className="nav-link" href="/settings" aria-expanded="false">
                        <div className="d-flex align-items-center"><span className="nav-link-text ps-1">Settings</span>
                        </div>
                      </a> */}
                      {/* <!-- more inner pages--> */}
                    {/* </li> */}
                  </ul>
                  
                {!_user.is_staff ? (
                  <>
                  {/* <!-- parent pages--> */}
                  <a className="nav-link" href="/account" role="button" aria-expanded="false">
                    <div className="d-flex align-items-center"><span className="nav-link-icon"><span className="fas fa-building"></span></span><span className="nav-link-text ps-1">Account Company</span>
                    </div>
                  </a>
                  {/* <!-- parent pages--> */}
                  
                  <a className="nav-link" href="/marketplace" role="button" aria-expanded="false">
                    <div className="d-flex align-items-center"><span className="nav-link-icon"><span className="fas fa-shopping-basket"></span></span><span className="nav-link-text ps-1">Marketplace</span>
                    </div>
                  </a>

                  {/* <!-- parent pages--> */}
                  <a className="nav-link" href="/payment_history" role="button" aria-expanded="false">
                    <div className="d-flex align-items-center"><span className="nav-link-icon"><span className="fas fa-credit-card"></span></span><span className="nav-link-text ps-1">Payment History</span>
                    </div>
                  </a>
                  </>):null
                  }

                </li>
                {/* admin features */}
                {_user.is_staff ? 
                  (<li className="nav-item">
                    {/* <!-- label--> */}
                    <div className="row navbar-vertical-label-wrapper mt-3 mb-2">

                      <div className="col-auto navbar-vertical-label">
                      </div>
                      <div className="col ps-0">
                        <hr className="mb-0 navbar-vertical-divider" />
                      </div>
                    </div>
                      
                      
                    {props.permissions?.subscribed_user?.can_view  ? 
                        (<>                      
                          {/* <!-- parent pages--> */}
                          <a className="nav-link dropdown-indicator" href="#client_side_menu" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="client_side_menu">
                          <div className="d-flex align-items-center"><span className="nav-link-icon"><i className="fas fa-tasks"></i></span><span className="nav-link-text ps-1">Client</span>
                            </div>
                          </a>
                          <ul className="nav collapse false" id="client_side_menu">                          
                              {true ? 
                                (<li className="nav-item"><a className="nav-link" href="/subscribed_user" aria-expanded="false">
                                        <div className="d-flex align-items-center"><span className="nav-link-text ps-1">Client</span>
                                        </div>
                                      </a>
                                      {/* <!-- more inner pages--> */}
                                    </li>
                                )
                                : null }
                                {/* <h7>{JSON.stringify(props.permissions?.user_role)}</h7> */}
                                {true ? 
                                (
                                    <li className="nav-item"><a className="nav-link" href="/user_product" aria-expanded="false">
                                        <div className="d-flex align-items-center"><span className="nav-link-text ps-1">Unconfirmed Client</span>
                                        </div>
                                      </a>
                                      {/* <!-- more inner pages--> */}
                                    </li>
                                )
                              : null }
                          </ul>
                        </>
                        )
                     : null }
                     
                    {props.permissions?.module?.can_view ?  (
                    <a className="nav-link" href="/modules" role="button" aria-expanded="false">
                      <div className="d-flex align-items-center"><span className="nav-link-icon"><span className="fas fa-th-large"></span></span><span className="nav-link-text ps-1">Modules</span>
                      </div>
                    </a>
                    )
                     : null }
                     {props.permissions?.pricing?.can_view ?  (
                    <a className="nav-link" href="/pricing" role="button" aria-expanded="false">
                      <div className="d-flex align-items-center"><span className="nav-link-icon"><i className="fas fa-hand-holding-usd"></i></span><span className="nav-link-text ps-1">Pricing</span>
                      </div>
                    </a>
                    )
                     : null }

                    {props.permissions?.user?.can_view || props.permissions?.user_role?.can_view ? 
                        (<>                      
                          {/* <!-- parent pages--> */}
                          <a className="nav-link dropdown-indicator" href="#user_admin" role="button" data-bs-toggle="collapse" aria-expanded="false" aria-controls="user_admin">
                            <div className="d-flex align-items-center"><span className="nav-link-icon"><span className="fas fa-user"></span></span><span className="nav-link-text ps-1">Staffs</span>
                            </div>
                          </a>
                          <ul className="nav collapse false" id="user_admin">                          
                              {props.permissions?.user?.can_view ? 
                                (<li className="nav-item"><a className="nav-link" href="/users_admin" aria-expanded="false">
                                        <div className="d-flex align-items-center"><span className="nav-link-text ps-1">Staff</span>
                                        </div>
                                      </a>
                                      {/* <!-- more inner pages--> */}
                                    </li>
                                )
                                : null }
                                {/* <h7>{JSON.stringify(props.permissions?.user_role)}</h7> */}
                                {props.permissions?.user_role?.can_view ? 
                                (
                                    <li className="nav-item"><a className="nav-link" href="/user_roles" aria-expanded="false">
                                        <div className="d-flex align-items-center"><span className="nav-link-text ps-1">Role</span>
                                        </div>
                                      </a>
                                      {/* <!-- more inner pages--> */}
                                    </li>
                                )
                              : null }
                          </ul>
                        </>
                        )
                     : null }

                  </li>):null
                  }
              </ul>
              {/* <div className="settings mb-3">
                <div className="card alert p-0 shadow-none" role="alert">
                  <div className="btn-close-falcon-container">
                    <div className="btn-close-falcon" aria-label="Close" data-bs-dismiss="alert"></div>
                  </div>
                  <div className="card-body text-center"><img src="/assets/img/icons/spot-illustrations/navbar-vertical.png" alt="" width="80" />
                    <p className="fs--2 mt-2">Loving what you see? <br />Get your copy of <a href="#!">Falcon</a></p>
                    <div className="d-grid"><a className="btn btn-sm btn-purchase" href="https://themes.getbootstrap.com/product/falcon-admin-dashboard-webapp-template/" >Purchase</a></div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </nav>
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu)