import { GET_COMPANY_BY_ID, UNSUBSCRIBE_SUBMODULE, GET_COMPANY_BY_SUBSCRIBE_USER_ID } from '../constants/actionTypes';
import { addSubscribedModulesApi, deleteCompanyApi, getCompanyByIdApi, getSubscribedUserCompanyApi, unsubscribeSubmoduleApi } from '../../api';


export const getSubscribedUserCompanyByUserId = (user_id) => async (dispatch) => {
  console.log('In getSubscribedUserCompany user_id:>>', user_id)
  try {
    const { data } = await getSubscribedUserCompanyApi(user_id);

    dispatch({ type: GET_COMPANY_BY_SUBSCRIBE_USER_ID, payload: data });
    return data
  } catch (error) {
    console.log('In getSubscribedUserCompany',error);
    return error.response
  }
};

export const getCompanyById = (company_id) => async (dispatch) => {
    console.log('company_id', company_id)
    try {
      const { data } = await getCompanyByIdApi(company_id);  
      dispatch({ type: GET_COMPANY_BY_ID, payload: data });
      return data
    } catch (error) {
      console.log('In getCompanyById',error);
      return error.response
    }
};
  
export const addSubscribedModules = (body_data)=> async (dispatch)=>{
  try {
    const { data } = await addSubscribedModulesApi(body_data);  
    dispatch({ type: "ADD_SUBSCRIBED_MODULES", payload: body_data });
    return data
  } catch (error) {
    console.log('In addSubscribedModules',error);
    return error.response
  }
}
export const deleteCompany = (company_id, setLoading) => async (dispatch)=>{
  try {
    setLoading(true)
    const { data } = await deleteCompanyApi(company_id);  
    dispatch({ type: "UNSUBSCRIBE_COMPANY", payload: company_id });
    return data
  } catch (error) {
    console.log('In deleteCompany',error );
    return error
  } finally {
    setLoading(false)
  }
}
export const unsubscribeSubmodule = (submodule, setLoading) => async (dispatch)=>{
  try {
    setLoading(true)
    const { data } = await unsubscribeSubmoduleApi(submodule);  
    
    console.log('data', data)
    dispatch({ type: UNSUBSCRIBE_SUBMODULE, payload: data });
    return data
  } catch (error) {
    console.error('In unsubscribeSubmodule',error.response.data);    
    setLoading(false)
    return error.response
  } finally {
    setLoading(false)
  }
}