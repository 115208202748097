import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {Modal} from 'react-bootstrap'
import axios from 'axios'
import { fetchUsers, deleteUserCrudSuccess } from '../../redux/actions/userCrudAction';
import UserCrudForm from '../../components/UserCrudForm/UserCrudForm';
import { AssignRoleForm } from '../../components';
import { fetchRoles } from '../../redux/actions/roleActions';
import { env_config, API } from '../../env';
import { getPermissionByRoleId } from '../../redux/actions/permissionActions';
        
const config = env_config;  

export const UserAdmin = (props) => {
    const [message, setMessage] = useState(false)
    const [filterStaff, setFilterStaff] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isUpdate, setIsUpdate] = useState(false)
    const [updateUser, setUpdateUser] = useState({})  
    // update role modal
    const [showRoleModal, setShowRoleModal] = useState(false);
    const handleCloseRoleModal = () => setShowRoleModal(false);
    const handleShowRoleModal = () => setShowRoleModal(true);
    
    useEffect(() => {
        (async ()=>{
          console.log('props', props)
          const _user = JSON.parse(localStorage.getItem('profile')).result
          await props.getPermissionByRoleId(_user.role_id)
          let fetchedUsers = await props.fetchUsers();
          let fetchedRoles = await props.fetchRoles();
          console.log('fetchedUsers', fetchedUsers, '\nfetchedRoles',fetchedRoles)
        })()
      }, [])
      
      const handleAdd = () =>{
          handleShow()
          setIsUpdate(false)
      }
      const handleEdit = (user) =>{
          handleShow()
          setUpdateUser(user)
          setIsUpdate(true)
      }
      
    
    const handleChangeActive = (e, user)=> {
      e.preventDefault()
      let _checked = e.target.checked;
      user.is_active = _checked;
      let _user = JSON.parse(localStorage.getItem('profile'));
      console.log('_user:>>', _user.result);
      user.updatedBy = _user.result;
      (async ()=> {
        // console.log('-- update values', values)
        await API
          .put(`/users/${user._id}`, {
            ...user
          }, config)
            .then(res=>{
              const { data } = res
              console.log('in handleChangeActive responser, res', res)
              props.updateUser(data)
              setMessage({code: res.status, message: "user successfully updated"});
              setTimeout(() => {
                  // document.querySelector('#close-create-user-modal').click();
                  localStorage.removeItem('user_item')
                }, 2000);
            })
      })()      
    }
    
    const handleDelete = async(id) =>{    
      console.log('id', id)  
      try {
        await API
          .delete(`/users/${id}`, {}, config)
            .then(res=>{
              const { data } = res
              console.log('in deleteForm responser, res', res)
              props.deleteUserCrudSuccess(id)
              setMessage({code: res.status, message: "user successfully deleted"});
              setTimeout(() => {
                setMessage(false)
                }, 4000);
            })
      } catch (error) {
        
      }
    }

    const handleEditRole = async (user) => {
      setUpdateUser(user)
      handleShowRoleModal()
    }

  return (
    <>
        {/* <p>{JSON.stringify(props.userList)}</p> */}
        {message && message.code ? 
            message.code.toString().substr(0,2)  === "20" ? (   
            <div className="alert alert-success" role="alert">
                {message.message}
            </div>
            ) : (
                <div className="alert alert-danger" role="alert">
                {JSON.stringify(message?.message?.message)}
                </div>
            )          
        :null}
        <div className="d-flex mb-4 mt-4"><span className="fa-stack me-2 ms-n1"><i className="fa-inverse fa-stack-1x text-primary fas fa-list" data-fa-transform="shrink-2"></i></span>
            <div className="col">
              <h5 className="mb-0 text-primary position-relative"><span className="bg-200 dark__bg-1100 pe-3">User List</span><span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span></h5>
              <p className="mb-0">We offer specialized services to large companies that need to sell their products online, with powerful tools and analytics for informed decisions, secure payment gateways, and 24/7 customer support.ts.</p>
            </div>
          </div>

          
          <div className="card z-index-1 mb-3" id="recentPurchaseTable" data-list='{"valueNames":["name","createdBy","product","payment","amount"],"page":8,"pagination":true}'>
            <div className="card-header">
              <div className="row flex-between-center">
                <div className="col-6 col-sm-auto d-flex align-items-center pe-0">
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Users </h5>
                </div>
                <div className="col-6 col-sm-auto ms-auto text-end ps-0">
                  <div className="d-none" id="table-users-actions">
                    <div className="d-flex"><select defaultValue="bulk" className="form-select form-select-sm" aria-label="Bulk actions">
                        <option value="bulk">Bulk actions</option>
                        <option value="Refund">Refund</option>
                        <option value="Delete">Delete</option>
                        <option value="Archive">Archive</option>
                      </select><button className="btn btn-falcon-default btn-sm ms-2" type="button">Apply</button></div>
                  </div>
                  {/* data-bs-toggle="modal" data-bs-target="#user-modal" */}
                  <div id="table-users-replace-element">
                    {props.user_permissions?.user?.can_create ? ( 
                    <button onClick={()=>handleAdd()} className="btn btn-falcon-default btn-sm" type="button"><span className="fas fa-plus" data-fa-transform="shrink-3 down-2"></span><span className="d-none d-sm-inline-block ms-1">New</span></button>
                    ):null}
                    <button className="btn btn-falcon-default btn-sm mx-2" type="button"><span className="fas fa-filter" data-fa-transform="shrink-3 down-2"></span><span className="d-none d-sm-inline-block ms-1">Filter</span></button><button className="btn btn-falcon-default btn-sm" type="button"><span className="fas fa-external-link-alt" data-fa-transform="shrink-3 down-2"></span><span className="d-none d-sm-inline-block ms-1">Export</span></button></div>
                </div>
              </div>
            </div>
            <div className="card-body px-0 py-0">
              <div className="table-responsive scrollbar">
              {props.user_permissions?.user?.can_view ? (
              <table className="table table-sm fs--1 mb-0 overflow-hidden">
                  <thead className="bg-200 text-900">
                    <tr>
                      <th className="white-space-nowrap">
                        <div className="form-check mb-0 d-flex align-items-center"><input className="form-check-input" id="checkbox-bulk-users-select" type="checkbox" data-bulk-select='{"body":"table-purchase-body","actions":"table-users-actions","replacedElement":"table-users-replace-element"}' /></div>
                      </th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="first_name">First Name</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="last_name">Last Name</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="email">Email</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="roles">Role</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="staff">Staff</th>
                      {filterStaff ? <th className="sort pe-1 align-middle white-space-nowrap" data-sort="department">Department</th> : null}
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="created_by">created By</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="modified_by">Modify By</th>
                      <th className="no-sort pe-1 align-middle data-table-row-action"></th>
                    </tr>
                  </thead>
                   <tbody className="list" id="table-purchase-body">        
                    {props.userList.filter(item=> item.is_staff || false === true).map((user, i)=>(                                           
                      <tr key={i} className="btn-reveal-trigger">
                        <td className="align-middle" style={{width: "28px"}}>
                          <div className="form-check mb-0"><input className="form-check-input" type="checkbox" id="recent-purchase-0" data-bulk-select-row="data-bulk-select-row" /></div>
                        </td>
                        <td className="white-space-nowrap first_name"><strong>{user.first_name}</strong></td>
                        <td className="white-space-nowrap last_name"><strong>{user.last_name}</strong></td>
                        <td className="white-space-nowrap email"><strong>{user.email}</strong></td>
                        {filterStaff ? <td className="white-space-nowrap department"><strong>{user.department}</strong></td> : null }
                        <td className="white-space-nowrap staff" style={{cursor:'pointer'}} data-bs-toggle="tooltip" data-bs-placement="top" title={user.roles && user.roles.reduce((acc, curr, i)=>{
                          acc += `${i > 0 ? '|' : ""} ${curr}`
                          return acc
                        },[])}>{user.roles?.length > 0 ? user.roles[0].name : user.role_id ? user.role_id : 'n/a'}</td>
                        <td>
                            <div className="form-check text-center"> 
                            {/* form-switch */}
                                <input onChange={(e)=>handleChangeActive(e, user)} disabled value={user.is_staff ? 'on' : 'off'} checked={user.is_staff} className="form-check-input" type="checkbox" id="flexCheckChecked" style={{fontSize: '1.5em', cursor: 'pointer'}}/>
                                <label className="form-check-label" htmlFor="flexCheckChecked">
                                  
                                </label>
                            </div>
                        </td>
                        <td className="white-space-nowrap created_by">{user.createdBy?.last_name || 'n/a'}</td>
                        <td className="white-space-nowrap modified_by">{user.updatedBy?.last_name || 'n/a'}</td>
                        <td className="white-space-nowrap text-end">
                          <div className="dropstart font-sans-serif position-static d-inline-block"><button className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal float-end" type="button" id="dropdown0" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span className="fas fa-ellipsis-h fs--1"></span></button>
                            <div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="dropdown0">
                              {/* <a className="dropdown-item" href="#!">View</a> */}
                              {props.user_permissions?.user?.can_edit ? (
                              <a className="dropdown-item" href="#edit" onClick={()=>handleEdit(user)}>Edit</a>
                              ) : null}
                            {JSON.parse(localStorage.getItem('profile'))?.result?.role_id !== user.role_id && <a className="dropdown-item" href="#update_role" onClick={()=>handleEditRole(user)}>Assign Role</a>}
                              {/* <div className="dropdown-divider"></div><a className="dropdown-item text-warning" href="#!">Archive</a> */}
                              
                              {props.user_permissions?.user?.can_delete ? (
                              <a className="dropdown-item text-danger" href="#delete" onClick={()=>handleDelete(user._id)}>Delete</a>
                              ) : null}
                              
                            </div>
                          </div>
                        </td>
                      </tr>    
                    ))}          
                    {props.userList.length > 0 ? null : <tr></tr>}
                  </tbody>
                </table>
                ):"You can't view this"}
              </div>
            </div>
            <div className="card-footer">
              <div className="row align-items-center">
                <div className="pagination d-none"></div>
                <div className="col">
                  <p className="mb-0 fs--1"><span className="d-none d-sm-inline-block me-2" data-list-info="data-list-info"> </span><span className="d-none d-sm-inline-block me-2">&mdash;  </span><a className="fw-semi-bold" href="#!" data-list-view="*">View all<span className="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a><a className="fw-semi-bold d-none" href="#!" data-list-view="less">View less<span className="fas fa-angle-right ms-1" data-fa-transform="down-1"></span></a></p>
                </div>
                <div className="col-auto d-flex"><button className="btn btn-sm btn-primary" type="button" data-list-pagination="prev"><span>Previous</span></button><button className="btn btn-sm btn-primary px-4 ms-2" type="button" data-list-pagination="next"><span>Next</span></button></div>
              </div>
            </div>
          </div>
        <>            
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{`${isUpdate ? 'Update':'Create'}`} user</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            
              {isUpdate ? 
                ((Object.keys(updateUser).length > 0 && <UserCrudForm handleClose={handleClose} updateUser={updateUser} setIsUpdate={setIsUpdate} isUpdate={isUpdate} />))
                :
                  (<UserCrudForm handleClose={handleClose} setIsUpdate={setIsUpdate} isUpdate={isUpdate} createUserSuccess={props.createUserSuccess}/>)
              }  
          </Modal.Body>
            <div className="modal-footer">
              <button className="btn btn-secondary" id="close-create-user-modal"  type="button" data-bs-dismiss="modal">Close</button>
              <button className="btn btn-primary"  form="users-crud-form" type="submit">Submit </button>
            </div>
        </Modal>
          </>

          
        <Modal show={showRoleModal} onHide={handleCloseRoleModal}>
          <Modal.Header closeButton>
            <Modal.Title>{`Assign Role`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
             
              <AssignRoleForm handleClose={handleCloseRoleModal} roleList={props.roleList} updateUser={updateUser} />
           
          </Modal.Body>
            <div className="modal-footer">
              <button className="btn btn-secondary" id="close-assign-user-role-modal"  type="button" data-bs-dismiss="modal">Close</button>
              <button className="btn btn-primary"  form="assign-user-role-form" type="submit">Assign </button>
            </div>
        </Modal>

    </>
  )
}

const mapStateToProps = (state) => {
    // console.log('state', state)
    return {
      userList: state.user.userList,
      roleList: state.role.roleList,
      user_permissions: state.permission.user_permissions
    }
  }

  const mapDispatchToProps = dispatch =>{
    return {
        fetchUsers: () => dispatch(fetchUsers()),
        fetchRoles: () => dispatch(fetchRoles()),
        deleteUserCrudSuccess:(user_id)=>dispatch(deleteUserCrudSuccess(user_id)),
        getPermissionByRoleId:(role_id)=> dispatch(getPermissionByRoleId(role_id)),

    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(UserAdmin)