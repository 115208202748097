import { fetchRolesApi, fetchUserPermissionsApi, getRoleByIdApi } from "../../api";
import { CREATE_ROLE_CRUD, DELETE_ROLE_CRUD, FETCH_ROLE_CRUD, FETCH_USER_PERMISSIONS, GET_ROLE_BY_ID, UPDATE_ROLE_CRUD } from "../constants/actionTypes";

export const fetchRoles = (formData, router) => async (dispatch) => {

    try {
      const { data } = await fetchRolesApi();
  
      dispatch({ type: FETCH_ROLE_CRUD, payload: data });
      return data
    } catch (error) {
      console.log('In fetchRoles',error);
    }
  };

  // export const fetchUserPermissions = (user_id) => async (dispatch) => {

  //   try {
  //     const { data } = await fetch UserPermissionsApi(user_id);
  
  //     dispatch({ type: FETCH_USER_PERMISSIONS, payload: data });
  //     return data
  //   } catch (error) {
  //     console.log('In fetchUserPermissions',error);
  //   }
  // };
  
  export const getRoleById = (role_id) => async (dispatch) => {
    console.log('getRoleById role_id:>>', role_id)
    try {
      const { data } = await getRoleByIdApi(role_id);
  
      dispatch({ type: GET_ROLE_BY_ID, payload: data });
      return data
    } catch (error) {
      console.log('In fetchRoles',error);
    }
  }
  
// createRoleSuccess
export const createRoleSuccess = role => {
  return {
    type: CREATE_ROLE_CRUD,
    payload: role,
  };
};
// updateRoleSuccess
export const updateRoleSuccess = role_id => {
  return {
    type: UPDATE_ROLE_CRUD,
    payload: role_id,
  };
};

// deleteRoleCrudSuccess
export const deleteRoleSuccess = role_id => {
  return {
    type: DELETE_ROLE_CRUD,
    payload: role_id,
  };
};