import * as actionType from '../constants/actionTypes';

const initialState = {
    roleList: []
}

const roleReducers = (state = initialState, { type, payload }) => {
  switch (type) {

  case actionType.FETCH_ROLE_CRUD:
    return { 
        ...state, 
        roleList: payload
     }
     case actionType.CREATE_ROLE_CRUD:
       return {
         ...state,
         loading: false,
         roleList: [...state.roleList, payload],
         error: '',
       };    
    case actionType.GET_ROLE_BY_ID:
      return {
        ...state,
        loading: false,
        role: payload,
        error: '',
      };
     case actionType.UPDATE_ROLE_CRUD:
      console.log('-- reducer UPDATE_ROLE_CRUD:>>', payload)
      return {
        ...state,
        roleList: [...state.roleList.map((role) => {
          if (role._id !== payload._id) {
            return role
          }
          console.log('in condition true', payload)
          return payload
        })]
      }
      case actionType.DELETE_ROLE_CRUD:
        return {
          ...state,
          roleList: [...state.roleList.filter((role) => role._id != payload)]
        }
  default:
    return state
  }
}

export default roleReducers