import React, {  useState } from 'react'
import { ButtonLoader } from '../../components'
import axios from 'axios'
import { BACKEND_DOMAIN } from '../../env'

export default function BackendProcessModal(props) {
    const [message, setMessage] = useState({message: ''})
    const [loading, setLoading] = useState(false)

    const handleBackEndProcess = async () => {
        setLoading(true);
        axios.post(
        `${BACKEND_DOMAIN}/pricing/backend_processes`,
            {
                'subscription': props.subscription,                
                'email': props.email,
            })
        .then(response => {
            const { data } = response
            console.log('-- handleBackEndProcess then', data)
            setMessage({code: response.status, message: "User successfully updated"}); 
          })
          .catch(error => {
              console.log('--- handleBackEndProcess error :>>',error);
              setMessage({code: 500, message: error.message})
            }).finally(()=> {setTimeout(() => {
                setMessage(false);
                setLoading(false)
                document.querySelector('#close-user-product-details-modal-backend-process').click()
            }, 3000);})
    }
  return (
    <>
        
        <div className="modal fade" id="user-product-details-modal-backend-process" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth: '500px'}}>
              <div className="modal-content position-relative">
                <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                  <button className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body p-0">
                  
                  {message && message.code ? 
                    message.code  == "200" ? (
                    <div className="alert alert-success mb-0 p-1" role="alert">
                      {JSON.stringify(message.message)}
                    </div>
                    ) : (
                      <div className="alert alert-danger mb-0 p-1" role="alert">
                      {JSON.stringify(message.message)}
                      </div>
                    )          
                  :null}
                  <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h4 className="mb-1" id="submodules-details-modal-label">{"Backend Process"}</h4>
                  </div>
                  <div className="p-4 pb-0">
                    
                  <table className="table table-borderless fs--1 mb-0">
                      <tbody>
                        <tr className="border-bottom">
                          <th className="ps-0 pt-0 p-1">{'These are the instructions that will be executed :'}
                          </th>
                          <th className="pe-0 text-end pt-0">{props.submoduleDetails?.name}</th>
                        </tr> 
                        <tr className="">
                          <th className="ps-0 py-2 p-1"><span className="fas fa-check"></span>{" "}{'Create Company'} 
                          </th>
                        </tr> 
                        <tr className="">
                          <th className="ps-0 py-2 p-1"><span className="fas fa-check"></span>{" "}{'Add subscribed module to newly created company'}
                          </th>
                        </tr> 
                        <tr className="">
                          <th className="ps-0 py-2 p-1"><span className="fas fa-check"></span>{" "}{'set default number of user'} 
                          </th>
                        </tr> 
                        <tr className="">
                          <th className="ps-0 py-2 p-1"><span className="fas fa-check"></span>{" "}{'send back credentials and company id'} 
                          </th>
                        </tr> 

                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-secondary" id="close-user-product-details-modal-backend-process"  type="button" data-bs-dismiss="modal">Close</button>
                  <button disabled={loading} className="btn btn-danger" onClick={()=>handleBackEndProcess(props.submoduleDetails)}  form="login-form" type="submit">Submit<ButtonLoader show={loading}/></button>
                </div>
              </div>
            </div>
          </div>
    </>
  )
}
