import { FETCH_MODULES } from '../constants/actionTypes';
import { fetchModulesApi, getModuleByIdApi} from '../../api'

export const fetchModules = (formData, router) => async (dispatch) => {

  try {
    const { data } = await fetchModulesApi(formData);

    dispatch({ type: FETCH_MODULES, payload: data });
    return data
    // router.push('/');
  } catch (error) {
    console.log('In fetchModules',error);
  }
};


// createModuleSuccess
export const createModuleSuccess = module => {
  // message.success({ content: 'Module Created!', key, duration: 2 });
  return {
    type: 'CREATE_MODULE',
    payload: module,
  };
};
// updateModuleSuccess
export const updateModuleSuccess = module => {
  // message.success({ content: 'Module Created!', key, duration: 2 });
  return {
    type: 'UPDATE_MODULE',
    payload: module,
  };
};
export const deleteModuleSuccess = module_id => {
  // message.success({ content: 'Module Created!', key, duration: 2 });
  return {
    type: 'DELETE_MODULE',
    payload: module_id,
  };
};

export const getModuleById = (module_id) => async (dispatch) => {
  console.log('module_id', module_id)
  try {
    const { data } = await getModuleByIdApi(module_id);  
    dispatch({ type: 'GET_MODULE_BY_ID', payload: data });
    return data
  } catch (error) {
    console.log('In getModuleById',error);
  }
};