const { default: Axios } = require("axios");

let BACKEND_DOMAIN = "http://localhost:5000"
let ADMIN_DOMAIN = "http://localhost:3001"
let MASTER_ERP_BACKEND_DOMAIN = "https://erp.equerre.com"

//if (process.env.NODE_ENV === 'production') {
  BACKEND_DOMAIN = "https://server.equerre.com";
  ADMIN_DOMAIN = 'https://admin.equerre.com'
//}   

module.exports = {
  API_BASE_BK: 'https://www.equerre.com',
  API_BASE: ADMIN_DOMAIN,
  BACKEND_SAAS_API_KEY: 'CODEX@123',
  STRIPE_PUBLIC_KEY: 'pk_test_TMIaDVpOozoClT4C9KOSs5Dw00VHiK65gX',
  GOOGLE_RECAPTCHA_KEY: "6LcMEh4iAAAAABeT-OeUSZ3vA286rESK39AOnGMb",
  BACKEND_DOMAIN : BACKEND_DOMAIN,
  env_config: {
    headers:{
      // "Accept": "*/*",
      // "User-Agent": "Thunder Client (https://www.thunderclient.com)",
      // "Access-Control-Allow-Origin": ADMIN_DOMAIN,

    } 
  },
  API: Axios.create({ baseURL: `${BACKEND_DOMAIN}`,headers: {
    'Content-Type': 'application/json',
  } }),
  API_ERP_BACKEND: Axios.create({ baseURL: `${MASTER_ERP_BACKEND_DOMAIN}`,headers: {
    'Content-Type': 'application/json',
  } })
};
