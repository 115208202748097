import { createUserProductApi, fetchUserProductApi, addModulineApi, removeModuleLineApi, addPricingToUserProductApi } from '../../api';
import {  ADD_PRICING_TO_USER_PRODUCT, ADD_USER_PRODUCT, CREATE_OR_UPDATE_USER_PRODUCT, FETCH_USER_PRODUCT, INITIATE_USER_PRODUCT_FAILURE, INITIATE_USER_PRODUCT_REQUEST, REMOVE_USER_PRODUCT } from '../constants/actionTypes';


// module Actions Creator
export const initiateUserProductActionRequest = value => {
    return {
      type: INITIATE_USER_PRODUCT_REQUEST,
      payload: value,
    };
  };
  export const initiateUserProductActionFailure = error => {
    return {
      type: INITIATE_USER_PRODUCT_FAILURE,
      payload: error,
    };
  };
  
// createUserProductSuccess
export const createUserProductSuccess = user_product => {
  return {
    type: CREATE_OR_UPDATE_USER_PRODUCT,
    payload: user_product,
  };
};

// fetchUserProductSuccess
export const fetchUserProductSuccess = user_products => {
  return {
    type: FETCH_USER_PRODUCT,
    payload: user_products,
  };
};


export const createModuleLines = (formData) => async (dispatch) => {
  dispatch(initiateUserProductActionRequest())
  try {
    const { data } = await createUserProductApi();
    dispatch(createUserProductSuccess(data))
    // dispatch({ type: FETCH_USER_PRODUCT, payload: data });
  } catch (error) {
    dispatch(initiateUserProductActionFailure(error))
    console.log('In createModuleLines',error);
  }
};

export const fetchUserProducts = (token) => async (dispatch) => {
    console.log('token', token)
  dispatch(initiateUserProductActionRequest())
  try {
    const { data } = await fetchUserProductApi(token);
    dispatch(fetchUserProductSuccess(data))
    // console.log('-- fetchUserProducts:>>', data)
    // dispatch({ type: FETCH_USER_PRODUCT, payload: data });
    return data
  } catch (error) {
    dispatch(initiateUserProductActionFailure(error))
    console.log('In fetchUserProducts',error);
  }
};


// addModulineSuccess
export const addModulineSuccess = incomingData => {
  return {
    type: ADD_USER_PRODUCT,
    payload: incomingData,
  };
};

//addPricingToUserProduct
export const addPricingToUserProductSuccess = (pricing) => {
  return {
    type: ADD_PRICING_TO_USER_PRODUCT,
    payload: pricing,
  }
}

export const addPricingToUserProduct = (token, pricing) => async (dispatch) => {
  console.log('-- in addPricingToUserProduct token :>>', token, '\npricing', pricing)
  
  let incomingData = {
    "token": token,
    "pricing": pricing,
  }
  dispatch(initiateUserProductActionRequest(incomingData))
  try {
    const { data } = await addPricingToUserProductApi(incomingData);
    dispatch(addPricingToUserProductSuccess(data))
  } catch (error) {
    dispatch(initiateUserProductActionFailure(error))
    console.log('In addModuline',error);
  } finally {
    // dispatch(fetchUserProducts())
  }
}

export const addModuleLine = (module, user_profiles, submodule) => async (dispatch) => {
  // console.log('-- in addModuline finalSubmodule :>>', module, '\nuser_profiles', user_profiles, '\nsubmodule',submodule)
  // return;

  let val = {
    // "token": "Stldsnldnsldknld2kn384ubejhbring",
    "module_id":  module._id,
    "token": localStorage.getItem("userToken"),
    "module": {
        "name": module.name,
        "description": module.description,
        "submodules":[...user_profiles || [], {
          "id": submodule._id,
          "name": submodule.name,
          "parent": module._id,
          "price" : submodule.price
        }
      ],
    },
    "name": module.name,
    "description": module.description,
  }
  console.log('--- val :>>', val)
// return
  dispatch(initiateUserProductActionRequest(val))
  try {
    const { data } = await addModulineApi(val);
    dispatch(addModulineSuccess(data))
  } catch (error) {
    dispatch(initiateUserProductActionFailure(error))
    console.log('In addModuline',error);
  } finally {
    // dispatch(fetchUserProducts())
  }
};

// removeModuleLineuccess
export const removeModuleLineuccess = incomingData => {
  return {
    type: REMOVE_USER_PRODUCT,
    payload: incomingData,
  };
};


export const removeModuleLine = (module, submodule, module_index, submodules_index) => async (dispatch) => {
  console.log('-- In removeModuleLine submodule :>>', module, submodule, '\nsubmodules_index', submodules_index)
  console.log([...module.module.submodules.filter(_submodule=> _submodule.name !== submodule.name)])
  // return;
  // let finalSubmodule = module.module.submodules
  // let secondSubmodule = finalSubmodule.filter(_submodule => _submodule.id !== submodule._id); //console.log('_submodule', _submodule, '\nsubmodule', submodule, '\neval',_submodule.id !== submodule._id ));
  // console.log('--- secondSubmodule', secondSubmodule)
  let token = localStorage.getItem('userToken');
  // let incomingData = {
  //   token: token,
  //   submodules : [...module.module.submodules.filter(_submodule=> _submodule.name !== submodule.name)],
  //   module: module.module,
  //   module_id: module._id,
  // }
  // return;
  let val = {
    // "token": "Stldsnldnsldknld2kn384ubejhbring",
    "module_index":module_index,
    "submodules_index": submodules_index,
    "submodule_id":  submodule._id,
    "module_id":  module.module_id,
    "module": {
        "name": module.module.name,
        "description": module.module.description,
        "submodules":[...module.module.submodules.filter(_submodule=> _submodule.name !== submodule.name)],
    },
    "name": module.module.name,
    "description": module.module.description,
  }
  // return;
  dispatch(initiateUserProductActionRequest(val))
  try {
    const { data } = await removeModuleLineApi(val);
    dispatch(removeModuleLineuccess(data))
    // console.log('-- removeModuleLine:>>', data)
    // dispatch({ type: FETCH_USER_PRODUCT, payload: data });
  } catch (error) {
    dispatch(initiateUserProductActionFailure(error))
    console.log('In removeModuleLine',error);
  }
};