import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from 'axios';
// import { signin } from '../../redux/actions/auth';
import { fetchUserProducts } from '../../redux/actions/userProductActions'
import {UpdatePasswordWidget} from '../../components';
import { env_config, API, BACKEND_DOMAIN } from '../../env';
import ProfileUploadUserImageModal from './ProfileUploadUserImageModal';
// import { getPermissionByRoleId } from '../../redux/actions/permissionActions';

const _user = JSON.parse(localStorage.getItem('profile'))?.result

const Profile = (props) => {
  const [userDetails, setUserDetails] = useState(JSON.parse(localStorage.getItem("profile")))
  useEffect(() => {    
    // console.log('user_details', userDetails.result.user_token)
    props.fetchUserProducts(userDetails.result.user_token)
    // setUserDetails(user_details)
    // await props.getPermissionByRoleId(_user.role_id)
  }, [])
  const handleChange = () =>{
    
  }
  
  const [message, setMessage] = useState(false)
  const formik = useFormik({
    initialValues: {
      last_name: userDetails?.result?.last_name,
      first_name: userDetails?.result?.first_name,
      company: userDetails?.result?.company,
      phoneNumber: userDetails?.result?.phoneNumber,
      email: userDetails?.result?.email,
      company_size: userDetails?.result?.company_size,
      position: userDetails?.result?.position,
      profile_img: userDetails?.result?.profile_img,
    },
    validationSchema: Yup.object({

      first_name: Yup.string()
  .max(30, "Must be 30 characters or less")
  .required("Required"),
      last_name: Yup.string()
        .max(30, "Must be 30 characters or less")
        .required("Required"),
      company: Yup.string()
        .max(30, "Must be 30 characters or less")
        .required("Required"),
      email: Yup.string().email("Invalid email address").required("Required"),
      phoneNumber: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required("A phone number is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
    console.log('-- values', values)
    // return
    
    const config = env_config;
    
      try {
        await API
          .put("/user", {
      email: userDetails?.result?.email,
      user: values
    }, config)
          .then((res) => {
            console.log('res.status', res.status)
            let new_value = {
              ...userDetails,
              result:{
                ...JSON.parse(localStorage.getItem('profile')).result,
                ...values
              }
            }
            setUserDetails(new_value)
            localStorage.setItem('profile', JSON.stringify(new_value))
            setMessage({code: res.status, message: "User successfully updated"});    
            setTimeout(() => {
              // Most recent value
              setMessage(false);    
            }, 8000);

            resetForm({ values: "" });
          }).catch((err)=>{
            console.error("-- err", err.response);
            setMessage({code: err.response?.status || 500, message: err.response ? err.response.data : err})
          });
      } catch (error) {
        console.error("-- error", error);
        setMessage({code: 404, message: error})
      }
    },
  });
  const {userProductDetails} = props
  return (
    <>      
  
      <ProfileUploadUserImageModal setUserDetails={setUserDetails} userDetails={userDetails} />
   



      <div className="card mb-3">
            <div className="card-header position-relative min-vh-25 mb-7">
              <div className="bg-holder rounded-3 rounded-bottom-0" style={{backgroundImage:'url(/assets/img/generic/4.jpg)'}}></div>
              {/* <!--/.bg-holder--> */}
              <div className="avatar avatar-5xl avatar-profile"><img className="rounded-circle img-thumbnail shadow-sm" src={`${userDetails?.result?.profile_img || '../../assets/img/team/2.jpg'}`} width="200"  /></div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-8">
                  <h4 className="mb-1"> {`${userDetails?.result?.last_name} ${userDetails?.result?.first_name}`}<span data-bs-toggle="tooltip" data-bs-placement="right" title="Actif"><small className="fa fa-check-circle text-primary" data-fa-transform="shrink-4 down-2"></small></span></h4>
                  <h5 className="fs-0 fw-normal">{`${userDetails?.result?.position} at ${userDetails?.result?.company}`}</h5>
                  <p className="text-500">{userDetails?.result?.email}</p><a href='#profile_settings' className="btn btn-falcon-primary btn-sm px-3" type="button">Edit Profile</a>

                  <button style={{marginLeft:'20px'}} className="btn btn-primary btn-sm px-3" data-bs-toggle="modal" data-bs-target="#image-modal">Edit Image</button>

                </div>

                <div className="col ps-2 ps-lg-3">
                  {/* <a className="d-flex align-items-center mb-2" href="#"><span className="fas fa-user-circle fs-3 me-2 text-700" data-fa-transform="grow-2"></span>
                    <div className="flex-1">
                      <h6 className="mb-0">{`Number of users (${userProductDetails?.pricing?.users})`}</h6>
                    </div>
                  </a>
                  <a className="d-flex align-items-center mb-2" href="#"><span className="fas fa-recycle fs-3 me-2 text-700"></span>
                    <div className="flex-1">
                      <h6 className="mb-0">{`Payment Cycle: ${userProductDetails?.pricing?.paiement_cycle}`}</h6>
                    </div>
                  </a><a className="d-flex align-items-center mb-2" href="#"><i className="far fa-calendar fs-3 me-2 text-700"></i>
                    <div className="flex-1">
                      <h6 className="mb-0">{`Monthly Deduction: $${userProductDetails?.pricing?.price}/month`}</h6>
                    </div>
                  </a> */}
                  {/* <a className="d-flex align-items-center mb-2" href="#"><img className="align-self-center me-2" src="../../assets/img/logos/hp.png" alt="Generic placeholder image" width="30" />
                    <div className="flex-1">
                      <h6 className="mb-0">Hewlett Packard</h6>
                    </div>
                  </a> */}
                  </div>
              </div>
            </div>                        
          </div>
          
          <div className="row g-0">
            <div className="col-lg-8 pe-lg-2">
              <div className="card mb-3">
                

                <div className="card-header">
							{message && message.code ? 
								message.code.toString().substr(0,2)  === "20" ? (
								<div className="alert alert-success" role="alert">
									{message.message}
								</div>
								) : (
									<div className="alert alert-danger" role="alert">
									{JSON.stringify(message?.message?.message)}
									</div>
								)          
							:null}

                  <h5 className="mb-0" id="profile_settings">Profile Settings</h5>
                </div>
                <div className="card-body bg-light">
                  <form 
                    onSubmit={formik.handleSubmit}
                    id="upade-user-form"
                    method="post" action=""
                    className="row g-3">

                    <div className="col-lg-6"> <label className="form-label" htmlFor="first-name">First Name</label>

                      <input
													className="form-control"
													id="first-name"
													name="first_name"
													type="text"
													placeholder="Prénom"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.first_name }
												/>
												{formik.touched.first_name && formik.errors.first_name ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.first_name}</div>
												) : null}

                    </div>
                    <div className="col-lg-6"> <label className="form-label" htmlFor="last-name">Last Name</label>
                    
                      <input							
                          className="form-control"						
													id="last-name"
													name="last_name"
													type="text"
													placeholder="Nom"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.last_name}
												/>
												{formik.touched.last_name && formik.errors.last_name ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.last_name}</div>
												) : null}

                    </div>
                    <div className="col-lg-6"> <label className="form-label" htmlFor="email1">Email</label>
                      
                      <input					disabled={true}
                          className="form-control" 								
													id="email1"
													name="email"
													type="email"
													placeholder="Email"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.email}
												/>
												{formik.touched.email && formik.errors.email ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.email}</div>
												) : null}

                    </div>
                    <div className="col-lg-6"> <label className="form-label" htmlFor="company">Company</label>
                          
											<input			
                          className="form-control"										
													id="company"
													name="company"
													type="text"
													placeholder="Entreprise"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.company}
												/>
												{formik.touched.company && formik.errors.company ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.company}</div>
												) : null}

                    </div>
                    
                    <div className="col-lg-6"> <label className="form-label" htmlFor="company_size">Company Size</label>
                          
                          <input			
                            className="form-control"										
                            id="company_size"
                            name="company_size"
                            type="number"
                            placeholder="Taille de l'entreprise"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.company_size}
                          />
                          {formik.touched.company_size && formik.errors.company_size ? (
                            <div style={{ color: "#dc3545" }}>{formik.errors.company_size}</div>
                          ) : null}
                          
                    </div>

                    <div className="col-lg-6"> <label className="form-label" htmlFor="phoneNumber">Phone</label>
                      
											<input
                        className='form-control'
												id="phoneNumber"
												name="phoneNumber"
												type="tel"
												placeholder="Numéro"
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.phoneNumber}
											/>
											{formik.touched.Name && formik.errors.phoneNumber ? (
												<div style={{ color: "#dc3545" }}>
												{formik.errors.phoneNumber}
												</div>
											) : null}

                    </div>
                    <div className="col-lg-12"><label className="form-label" htmlFor="heading">Heading</label>
                      
                      <input
                          className="form-control"											
													id="heading"
													name="position"
													type="text"
													placeholder="Titre du poste"
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.position}
												/>
												{formik.touched.position && formik.errors.position ? (
													<div style={{ color: "#dc3545" }}>{formik.errors.position}</div>
												) : null}

                    </div>
                    
                    <div className="col-12 d-flex justify-content-end"><button className="btn btn-primary" type="submit">Update </button></div>
                  </form>
                </div>
              </div>

            </div>
            <div className="col-lg-4 ps-lg-2">
              <div className="sticky-sidebar">
                
                <UpdatePasswordWidget userDetails={userDetails}/>

                {/* {!_user.is_staff || false ? (
                <div className="card mb-3">
                  <div className="card-header">
                    <h5 className="mb-0">Billing Setting</h5>
                  </div>
                  <div className="card-body bg-light">
                    <h5>Plan</h5>
                    <p className="fs-0"><strong>Developer</strong>- Unlimited private repositories</p><a className="btn btn-falcon-default btn-sm" href="#!">Update Plan</a>
                  </div>
                  <div className="card-body bg-light border-top">
                    <h5>Payment</h5>
                    <p className="fs-0">You have not added any payment.</p><a className="btn btn-falcon-default btn-sm" href="#!">Add Payment </a>
                  </div>
                </div>):null} */}
                
              </div>
            </div>
          </div>
      
    </>
  )
}

const mapStateToProps = (state) => {
  return{
    // user_permissions: state.permission.user_permissions,
    userProductDetails: state.user_product.selected_modules[0]
}}

const mapDispatchToProps = dispatch=>{
  return{
    fetchUserProducts:(token)=> dispatch(fetchUserProducts(token)),
    // getPermissionByRoleId: (role_id) => dispatch(getPermissionByRoleId(role_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)