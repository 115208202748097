
import { API_ERP_BACKEND, env_config, BACKEND_SAAS_API_KEY, API } from '../../env';
import axios from 'axios'
const config = env_config

API_ERP_BACKEND.interceptors.request.use((req) => {
    req.headers['T-APi-KEY'] = BACKEND_SAAS_API_KEY
    return req;
});

export const getStripeSubscriptionDetailsByIdApi = (subscription_id) => API.get(`/stripe/${subscription_id}?subscription_id=${subscription_id}`, config);

export const getStripeSubscriptionDetailsById = (subscription_id, setLoading) => async (dispatch)=>{
  console.log('-- getStripeSubscriptionDetailsById', subscription_id)
  try {
    setLoading(true)
    const {data} = await getStripeSubscriptionDetailsByIdApi(subscription_id);  
  //   let res = data.json();
    console.log('--- res getStripeSubscriptionDetailsById:>>', data)
    dispatch({ type: "GET_STRIPE_SUBSCRIPTION_BY_ID", payload: data.data });
    return data.data
  } catch (error) {
    console.error('error In getStripeSubscriptionDetailsById',error);    
    setLoading(false)
    return error.response
  } finally {
    setLoading(false)
  }
}


export const getMappedModuleLineListApi = () => API_ERP_BACKEND.get('/api/Moduls/listmodules', config);

export const getMappedModuleLineList = (setLoading) => async (dispatch)=>{
    try {
      setLoading(true)
      const {data} = await getMappedModuleLineListApi();  
    //   let res = data.json();
      console.log('--- res getMappedModuleLineList:>>', data.data)
      dispatch({ type: "GET_MAPPED_MODULELINES", payload: data.data });
      return data.data
    } catch (error) {
      console.error('In getMappedModuleLineList',error.response?.data);    
      setLoading(false)
      return error.response
    } finally {
      setLoading(false)
    }
  }

const erpBackendUserListStatusApi = async(id_company) => {
  let body = {
      id_company
  }
  try {
      
    return await axios.post(
      `${process.env.MASTER_ERP_BACKEND_DOMAIN||"https://erp.equerre.com"}/api/UserListStatus/userList`,
      {
          ...body
      }, { headers: {'T-API-KEY': 'CODEX@123'} }
    );
    // const {data} = await response;
    // console.log('--- erpBackendUserListStatusApi content res',data);  
    //  data

  } catch (error) {
    console.error('error in erpBackendUserListStatusApi', error)
    return error
  }

}

export const erpBackendUserListStatus = (id_company, setLoading) => async (dispatch)=>{
  try {
    setLoading(true)
    const {data} = await erpBackendUserListStatusApi(id_company);  
  //   let res = data.json();
    console.log('--- res erpBackendUserListStatus:>>', data.data)
    dispatch({ type: "GET_BACKEND_USER_LIST_STATUS", payload: data.data });
    return data.data
  } catch (error) {
    console.error('In erpBackendUserListStatus',error.response?.data);    
    setLoading(false)
    return error.response
  } finally {
    setLoading(false)
  }
}

export const getUserConnectionTimeListApi = (id_company) => API_ERP_BACKEND.get(`/api/Staff_time/staff_time_connection?id_company=${id_company}`, config);

export const getUserConnectionTimeList = (id_company, setLoading) => async (dispatch)=>{
  try {
    setLoading(true)
    const {data} = await getUserConnectionTimeListApi(id_company);  
  //   let res = data.json();
    console.log('--- res getUserConnectionTimeList:>>', data.data)
    dispatch({ type: "GET_USER_CONNECTION_TIME_LIST", payload: data.data });
    return data.data
  } catch (error) {
    console.error('In getUserConnectionTimeList',error.response?.data);    
    setLoading(false)
    return error.response
  } finally {
    setLoading(false)
  }
}