export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const FETCH_POST = 'FETCH_POST';
export const LIKE = 'LIKE';
export const COMMENT = 'COMMENT';
export const END_LOADING = 'END_LOADING';
export const START_LOADING = 'START_LOADING';
export const FETCH_BY_CREATOR = 'FETCH_BY_CREATOR';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';

export const FETCH_MODULES = "FETCH_MODULES"

export const INITIATE_USER_PRODUCT_REQUEST = "INITIATE_USER_PRODUCT_REQUEST";
export const INITIATE_USER_PRODUCT_FAILURE = "INITIATE_USER_PRODUCT_FAILURE";

export const CREATE_OR_UPDATE_USER_PRODUCT = "CREATE_OR_UPDATE_USER_PRODUCT"
export const FETCH_USER_PRODUCT = "FETCH_USER_PRODUCT"

export const ADD_USER_PRODUCT = "ADD_USER_PRODUCT"
export const REMOVE_USER_PRODUCT = "REMOVE_USER_PRODUCT"
export const GET_COMPANY_BY_SUBSCRIBE_USER_ID = "GET_COMPANY_BY_SUBSCRIBE_USER_ID"

// modulelines
// initiators ModuleLine
export const INITIATE_MODULE_LINE_REQUEST = "INITIATE_MODULE_LINE_REQUEST";
export const INITIATE_MODULE_LINE_FAILURE = "INITIATE_MODULE_LINE_FAILURE";


export const CREATE_MODULELINES = "CREATE_MODULELINES"

export const FETCH_MODULELINES = "FETCH_MODULELINES"

// Pricing
export const FECTH_PRICING = "FETCH_PRICING"
export const ADD_PRICING_TO_USER_PRODUCT = "ADD_PRICING_TO_USER_PRODUCT"

export const FETCH_STRIPE_PUBLIC_KEY = "FETCH_STRIPE_PUBLIC_KEY"

export const FETCH_CLIENT_KEY = "FETCH_CLIENT_KEY"

export const SEND_SUCCESS_PAYMENT_MAIL = "SEND_SUCCESS_PAYMENT_MAIL"

export const GET_COMPANY_BY_ID = "GET_COMPANY_BY_ID"

// companyAction
export const UNSUBSCRIBE_SUBMODULE = "UNSUBSCRIBE_SUBMODULE"

// Payment History
export const GET_PAYMENT_HISTORY_BY_USER_ID = "GET_PAYMENT_HISTORY_BY_USER_ID" 
export const GET_PAYMENT_HISTORY_BY_PAYMENT_INTENT = "GET_PAYMENT_HISTORY_BY_PAYMENT_INTENT"
export const CREATE_PAYMENT_HISTORY = "CREATE_PAYMENT_HISTORY"


// Moduleline
export const UPDATE_MODULELINE = "UPDATE_MODULELINE"
export const DELETE_MODULELINE = "DELETE_MODULELINE"
export const CREATE_MODULELINE = "CREATE_MODULELINE"

export const GET_MODULELINE_BY_COMPANY_ID = "GET_MODULELINE_BY_COMPANY_ID";

// Pricing
export const FETCH_PRICING_CRUD = "FETCH_PRICING_CRUD"
export const UPDATE_PRICING = "UPDATE_PRICING"
export const DELETE_PRICING = "DELETE_PRICING"
export const CREATE_PRICING = "CREATE_PRICING"

// User
export const FETCH_USER_CRUD = "FETCH_USER_CRUD"
export const UPDATE_USER_CRUD = "UPDATE_USER_CRUD"
export const DELETE_USER_CRUD = "DELETE_USER_CRUD"
export const CREATE_USER_CRUD = "CREATE_USER_CRUD"
export const RESET_PASSWORD = "RESET_PASSWORD"

// Role
export const FETCH_ROLE_CRUD = "FETCH_ROLE_CRUD"
export const UPDATE_ROLE_CRUD = "UPDATE_ROLE_CRUD"
export const DELETE_ROLE_CRUD = "DELETE_ROLE_CRUD"
export const CREATE_ROLE_CRUD = "CREATE_ROLE_CRUD"
export const GET_ROLE_BY_ID = "GET_ROLE_BY_ID"

// Permission
export const GET_PERMISSION_BY_ROLE_ID = "GET_PERMISSION_BY_ROLE_ID"
export const UPDATE_PERMISSION_CRUD = "UPDATE_PERMISSION_CRUD"
export const DELETE_PERMISSION_CRUD = "DELETE_PERMISSION_CRUD"
export const CREATE_PERMISSION_CRUD = "CREATE_PERMISSION_CRUD"

// EDIT_CONNECTED_USER
export const EDIT_CONNECTED_USER = "EDIT_CONNECTED_USER"

// FETCH_USER_PERMISSIONS
export const FETCH_USER_PERMISSIONS = "FETCH_USER_PERMISSIONS"

// ACTIVATE_MODULEINE_IN_COMPANY
export const ACTIVATE_MODULEINE_IN_COMPANY = "ACTIVATE_MODULEINE_IN_COMPANY"