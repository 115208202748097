import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import './marketplace.css'
import { fetchModuleLines } from '../../redux/actions/modulelines'
import { fetchModules } from '../../redux/actions/modules'
import { fetchPricings } from '../../redux/actions/pricingActions'
import { useHistory } from 'react-router-dom'
import { setCheckedState, setNumberOfusers, setPaymentCycle } from '../../redux/actions/marketPlaceActions'
import SelectCompanyForm from '../../components/SelectCompanyForm/SelectCompanyForm'
import { getCompanyById } from '../../redux/actions/companyAction'
import { computeModulePrice, computePackageTotalAmount } from '../../utils/checkOutUtils'
import { result } from 'lodash'

const Marketplace = (props) => {
  let history = useHistory();
  const {setCheckedState, setPaymentCycle, setNumberOfusers} = props
  const {checkedState, } =props.marketPlaceData
  const {paymentCycle, numberOfusers, pricing } = props.company || {}
  useEffect(() => {
    console.log('props.company', props.company)
    if (localStorage.getItem('company_id')===null || localStorage.getItem('company_id')== undefined){
      document.querySelector('#open-select-company-modal').click();
    }
    const fetchData = async () => {
      const fetched_modules = await props.fetchModules()
      const fetched_modulelines = await props.fetchModuleLines()  
      const fetched_pricing = await props.fetchPricings()
      let company_id = localStorage.getItem('company_id')
      let fetched_company = await props.getCompanyById(company_id)
      
      return {fetched_modules, fetched_modulelines,fetched_pricing,fetched_company};
    }
    fetchData().then(result=>{
      // setCompany(result.fetched_company)
      let _initValue = {}
      const loadedCheckedState = localStorage.getItem('checkedState')
      if (loadedCheckedState !== null){
        _initValue = loadedCheckedState
      } else {
        new Array(...result.fetched_modules).forEach(_module => {
            _initValue[_module._id] = new Array([...result.fetched_modulelines.filter(moduleline=> moduleline.parent === _module._id )].length).fill(false)
        });
      }

      // console.log('_initValue', _initValue)
      // computeOrderSummary(_initValue) //[I don't know what this does here] 
      try {
        _initValue = JSON.parse(_initValue)
      } catch (error) {
        console.log('error', error)
      }
      

      props.setCheckedState(_initValue)
      // console.log('-- In then:>>',Object.values(result.fetched_pricing.price_ranges)[0]?.number?.value)
      setNumberOfusers(Object.values(result.fetched_pricing.price_ranges)[0]?.number?.value)
    })
    return () => {
      
      let stringifyCheckedState = JSON.stringify(computeOrderSummary(checkedState))
      localStorage.setItem('checkout_data', JSON.stringify({
        paymentCycle,
        numberOfusers,
        computeOrderSummary: stringifyCheckedState,
        promotion: props.price_ranges[numberOfusers]?.number?.promotion,
        payable_total: parseFloat(computeOrderSummary(checkedState, true)).toFixed(2),
      }))      
    }    
    }, [])

  // console.log('checkedState', checkedState)
  const selectAllSubModules = (e,_module_id, ) => {
    const isChecked = e.target.checked
    // console.log('In selectAllSubModules isChecked', isChecked)
    if (isChecked){
      setCheckedState({
        ...checkedState,
        [_module_id]: [...props.moduleLineList.filter(moduleline=> moduleline.parent === _module_id )].reduce((acc,curr)=>{
          acc[curr._id] = curr
          return acc
        },{})
      })
      return;
    } else {
      console.log('in false', )
      const _updatedCheckedState = new Array([...props.moduleLineList.filter(moduleline=> moduleline.parent === _module_id )].length).fill(false)
        
      setCheckedState({
        ...checkedState,
        [_module_id]: _updatedCheckedState
      })
      return;
    }
  }

  const computeOrderSummary = (result=false, total=false) => {
    result = result === false ? checkedState : result;
    let computedSubmodule = {}
    let _totatPriceSubmodule = 0
    for (const key in result) {
        // console.log('key', key)
        if (key){
          for (const sub_key in result[key]) {
            // console.log('   - sub_key: ', sub_key)
            if (key && key.length > 10 && sub_key.length > 10) {

              // console.log('   - sub_key: ', key,sub_key)
              if (computedSubmodule[key] === undefined) computedSubmodule[key] = {}
              computedSubmodule[key][sub_key] = {...props.moduleLineList.filter(moduleline=> moduleline._id === sub_key )[0]}
              if(computedSubmodule[key][sub_key]?.price) 
              _totatPriceSubmodule = parseFloat(_totatPriceSubmodule) + parseFloat(computedSubmodule[key][sub_key]?.price[paymentCycle])
            }
          }
        }
        // console.log(`In computeOrderSummary - ${key}: ${JSON.stringify(result[key])}`);
    }
    // console.log('end computedSubmodule', computedSubmodule, _totatPriceSubmodule)
    if (total){
      let tmp_payment_cycle = 1
      switch (paymentCycle) {
        case 'quarterly':
          tmp_payment_cycle = 3
          break;      
        case 'yearly':
          tmp_payment_cycle = 12
          break;     
        default:
          break;
      }
      return parseFloat((_totatPriceSubmodule - parseFloat(props.price_ranges[numberOfusers]?.number?.promotion))*numberOfusers*tmp_payment_cycle).toFixed(2)
    }
    // setTotatPriceSubmodule(_totatPriceSubmodule)
    return computedSubmodule
  }
  
  const removeSubmodule = (module, submodule_index,submodule) => {
    // console.log('removeSubmodule', module, submodule)
    let newCheckedState = {...checkedState}
    delete newCheckedState[`${module._id}`][submodule._id]

    if (Object.keys(newCheckedState[`${module._id}`]).length === 0){
      delete newCheckedState[`${module._id}`]
    }
    localStorage.setItem('checkedState', JSON.stringify(newCheckedState))
    setCheckedState({
      ...newCheckedState
    })

  }
  const addSubmodule = (module, submodule_index, submodule) => {
    // console.log('addSubmodule', module, submodule)    
    localStorage.setItem('checkedState',JSON.stringify({
      ...checkedState, 
      [module._id]: {
        ...checkedState[module._id] || {},
        [submodule._id]: submodule
      }
    }))
    setCheckedState({
      ...checkedState, 
      [module._id]: {
        ...checkedState[module._id] || {},
        [submodule._id]: submodule
      }
    })
  }
  // console.log('selectedSubModules', selectedSubModules)
  const handleOnChangePaymentCycle =(e)=> {   
    console.log('e.target.value', e.target.value)
    setPaymentCycle(e.target.value)
  }
  const handleOnChangeNumberOfUsers =(e)=> {
    setNumberOfusers(e.target.value)
  }
  const handleOnClickCheckout = (e)=>{
    e.preventDefault()
    let stringifyCheckedState = JSON.stringify(computeOrderSummary(checkedState))
    // console.log('handleOnClickCheckout', stringifyCheckedState)
    // return
    localStorage.setItem('checkout_data', JSON.stringify({
      paymentCycle,
      numberOfusers,
      computeOrderSummary: stringifyCheckedState,
      promotion: props.price_ranges[numberOfusers]?.number?.promotion,
      payable_total: parseFloat(computeOrderSummary(checkedState, true)).toFixed(2),
    }))
    history.push({pathname:'/checkout', state: {paymentCycle, numberOfusers}});
  }
  const validatePermissions = (permission) => {
    if (props.company?.permissions && props.company?.permissions instanceof Array) {        
        let result = props.company.permissions.every(w => permission.includes(w))
        // console.log('result:', result)
        return result
    }
    return false
  }
  return (
    <>
        {/* <p>{company && JSON.stringify(props.company)}</p> */}
        {/* <br/>
        <p>{JSON.stringify(props.moduleList)}</p> */}
        <div className="d-flex mb-4 mt-3"><span className="fa-stack me-2 ms-n1"><i className="fas fa-circle fa-stack-1x text-200"></i><i className="fa-inverse fa-stack-1x text-primary fas fa-list" data-fa-transform="shrink-2"></i></span>
            <div className="col">
              <h5 className="mb-0 text-primary position-relative"><span className="bg-200 dark__bg-1100 pe-3">Marketplace</span><span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span></h5>
              <p className="mb-0">We offer specialized services to large companies that need to sell their products online, with powerful tools and analytics for informed decisions, secure payment gateways, and 24/7 customer support.ts.</p>
            </div>
          </div>

                  
          <div className="row g-0">
            <div className="col-lg-8 pe-lg-2">
            {/* left part */}
              {props.moduleList.map((module, module_index)=>(
                <div key={module_index}>
                  <div  className="d-flex mb-2"><span className="fa-stack me-2 ms-n1"><i className="fas fa-circle fa-stack-1x text-200"></i><i className="fa-inverse fa-stack-1x text-primary fas fa-folder-plus" data-fa-transform="shrink-2"></i></span>
                  <div className="col">
                    <h5 className="mb-0 text-primary position-relative"><span className="bg-200 dark__bg-1100 pe-3">{module.name}</span><span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span></h5>
                      <div className="form-check form-switch">
                          <input onClick={(e)=>selectAllSubModules(e,module._id)} className="form-check-input" type="checkbox" id={`flexSwitchCheckDefault_${module_index}`}/>
                          {/* (e)=>toggleCheckBox(e, `flexSwitchCheckDefault_${module_index}`) */}
                          <label className="form-check-label" htmlFor={`flexSwitchCheckDefault_${module_index}`}>select all</label>
                      </div>
                    
                  </div>
                </div>
                
                  <div className='row mb-4'>
                  {props.moduleLineList.filter(moduleline=> moduleline.parent === module._id ).map((moduleline, submodules_index)=>(
                    <div className="col-4" key={submodules_index} style={{
                      display: props.company?.modules ? props.company?.modules[module._id] && (()=>{
                        // check if module already purchased
                        let BreakException = {};
                        let is_moduleline_id_in_company_modulelines = false;
                        try {
                          Object.keys(props.company?.modules[module._id]).forEach(function(_moduleline_key) {
                            if (props.company?.modules[module._id][_moduleline_key].id == moduleline._id) {
                              is_moduleline_id_in_company_modulelines = true;
                              throw BreakException;
                            };
                          });
                        } catch (e) {
                          if (e !== BreakException) throw e;
                        };
                        return is_moduleline_id_in_company_modulelines ? 'none': 'inherit';
                      })() : null
                    }}>
                      
                      {/* <p>{JSON.stringify(checkedState[module._id] && checkedState[module._id][submodules_index])}</p> */}
                      <input style={{display:'none'}} defaultChecked={checkedState[module._id] && checkedState[module._id].hasOwnProperty(`${moduleline._id}`)}
                           
                          type="checkbox" className={`submodule_checkbox flexSwitchCheckDefault_${module_index}`} 
                            id={`btn-check-outlined-  ${submodules_index}`} autoComplete="off"/>

                      {checkedState[module._id] && checkedState[module._id].hasOwnProperty(`${moduleline._id}`) ?(<label onClick={() => removeSubmodule(module, submodules_index, moduleline)} htmlFor={`btn-check-outlined-${submodules_index}`} className='submodule_checkbox_label w-100'>                
                        <div className="card" style={{height: '100%'}}>
                        <div className="bg-holder bg-card" style={{backgroundImage:`url(assets/img/icons/spot-illustrations/corner-${Math.floor(Math.random() * 2)+1}.png)`}}></div>
                        {/* <!--/.bg-holder--> */}
                        <div className="card-body position-relative" title={moduleline.description}>
                            <h5 className="text-warning" style={{fontSize:'large'}}>{moduleline.name}</h5>
                            <p className="fs--1 mb-0" style={{fontSize: 'xx-small'}}>{moduleline.description.toString().substr(0, 80)}</p><a className="btn btn-link fs--1 text-warning  ps-0" href="#!"></a>
                        </div>
                        </div>
                    </label>):(
                      <label onClick={() => addSubmodule(module, submodules_index, moduleline)} htmlFor={`btn-check-outlined-${submodules_index}`} className='submodule_checkbox_label  w-100'>                
                      <div className="card" style={{height: '100%'}}>
                      <div className="bg-holder bg-card" style={{backgroundImage:`url(assets/img/icons/spot-illustrations/corner-${Math.floor(Math.random() * 2)+1}.png)`}}></div>
                      {/* <!--/.bg-holder--> */}
                      <div className="card-body position-relative" title={moduleline.description}>
                          <h5 className="text-warning" style={{fontSize:'large'}}>{moduleline.name}</h5>
                          <p className="fs--1 mb-0" style={{fontSize: 'xx-small'}}>{moduleline.description.toString().substr(0, 80)}</p><a className="btn btn-link fs--1 text-warning  ps-0" href="#!"></a>
                      </div>
                      </div>
                  </label>
                    )}

                    </div>  
                  ))}
                  </div>
                </div>

              ))}
                              
            </div>
            <div className="col-lg-4 ps-lg-2">
              <div className="sticky-sidebar">
                
              <div className="card mb-3">
                    {/* Order Summary */}
                    
              <div className="card">
                <div className="card-header bg-light btn-reveal-trigger d-flex flex-between-center">
                  <h5 className="mb-0">Order Summary</h5><a className="btn btn-link btn-sm btn-reveal text-600" href="/cart"><span className="fas fa-pencil-alt"></span></a>
                </div>
                <div className="card-body" style={{maxHeight: '280px',overflowY: 'auto'}}>
                  <table className="table table-borderless fs--1 mb-0">
                    <tbody>
                        {
                          Object.keys(computeOrderSummary(checkedState)).map((oneKey,i)=>{
                            return Object.keys(computeOrderSummary(checkedState)[oneKey]).map((subKey,_i)=>{
                              return (
                                <tr className="border-bottom" key={_i}>
                                  <th className="ps-0 pt-0 p-1">{computeOrderSummary(checkedState)[oneKey][subKey].name}<div className="text-400 fs-xx-small fs--2">{paymentCycle}</div>
                                  </th>
                                  <th className="pe-0 text-end pt-0">${computeOrderSummary(checkedState)[oneKey][subKey].price && computeModulePrice(computeOrderSummary(checkedState)[oneKey][subKey].price[paymentCycle], paymentCycle, numberOfusers)}</th>
                                </tr>  
                              )
                            })
                          })      
                        }   
                        
                        <tr className="border-bottom">
                          <th className="ps-0 pt-0 p-1">User redution<div className="text-400 fs-xx-small fs--2">Nmber of user selected: {numberOfusers}/Users - {paymentCycle}</div>
                          </th>
                          <th className="pe-0 text-end pt-0">- ${props.price_ranges[numberOfusers]?.number?.promotion}</th>
                        </tr>    
                    </tbody>
                  </table>
                </div>
                <div className="card-footer d-flex justify-content-between bg-light">
                  <div className="fw-semi-bold" style={{textTransform: 'capitalize'}}>{paymentCycle} Total <small style={{textTransform: 'lowercase'}}>for</small> {numberOfusers} - Users</div>
                  <div className="fw-bold">${computeOrderSummary(checkedState, true) }</div>
                </div>
                <div className="card-footer d-flex justify-content-between bg-light">
                  <div className="fw-semi-bold" style={{textTransform: 'capitalize'}}>Package Amount</div>
                  <div className="fw-bold">${ computePackageTotalAmount(parseFloat(computeOrderSummary(checkedState, true)), paymentCycle, numberOfusers, pricing)}</div>
                </div>
              </div>

                </div>

                <div className='card mb-3'>
                    {/* Billing */}
                    <div className="card h-100">
                        <div className="card-header">
                        <h5 className="mb-0">Billing</h5>
                        </div>
                        <div className="card-body bg-light">
                        {props.company?.paymentCycle && <select disabled={!validatePermissions('can_edit')} defaultValue={props.company.paymentCycle} onChange={handleOnChangePaymentCycle} className="form-select mb-3" aria-label="Default select example">
                              <option value='monthly'>Monthly Plan</option>
                              <option value='quarterly'>Quarterly Plan</option>
                              <option value="yearly">Annual Plan</option>
                          </select>}
                        {props.company?.numberOfusers && <select disabled={!validatePermissions('can_edit')} defaultValue={props.company.numberOfusers} onChange={handleOnChangeNumberOfUsers}  className="form-select mb-3" aria-label="Default select example">
                              {
                                Object.keys(props.price_ranges).map((pricingKey,price_index)=>{
                                  return (
                                    <option key={price_index} value={props.price_ranges[pricingKey]?.number?.value}>{ parseInt(props.price_ranges[pricingKey]?.number?.value) }</option>
                                  )
                                })
                              }
                              
                          </select>}
                        {/* <div className="d-flex justify-content-between fs--1 mb-1 text-success">
                            <p className="mb-0">Annual charge</p><span>${parseFloat(computeOrderSummary(checkedState, true)).toFixed(1)}/{paymentCycle}</span>
                        </div> */}
                        <hr />
                        <h5 className="d-flex justify-content-between"><span>Due today</span><span>${computePackageTotalAmount(parseFloat(computeOrderSummary(checkedState, true)), paymentCycle, numberOfusers, pricing)}</span></h5>
                        <p className="fs--1 text-600">You’ll be charged based on your selected plan. changing this plan will also affect already subscribed modules in this company</p>
                        
                        <button disabled={computeOrderSummary(checkedState, true) <= 0} onClick={handleOnClickCheckout} className="btn btn-primary d-block w-100" type="submit"><span className="fa fa-lock me-2"></span>Checkout</button>
                        <div className="text-center mt-2"><small className="d-inline-block">By continuing, you are agreeing to our subscriber <a href="#!">terms</a> and will be charged at the end of the trial. </small></div>
                        </div>
                    </div>
                </div>

                
              </div>
            </div>
          </div>
          {/* Company */}
          
          <div data-bs-toggle="modal" id='open-select-company-modal'  data-bs-target="#select-company-modal">
          </div>
          <div className="modal fade" id="select-company-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth: '500px'}}>
              <div className="modal-content position-relative">
                <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                  <button className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body p-0">
                  <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h4 className="mb-1" id="modalExampleDemoLabel">Select Company</h4>
                  </div>
                  <div className="p-4 pb-0">
                    <SelectCompanyForm  getCompanyById={props.getCompanyById} history={history}/>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-secondary" id="close-select-company-modal" disabled type="button"  data-bs-dismiss="modal">Close</button>
                  <button className="btn btn-primary" form="select-company-form" type="submit">Submit </button>
                  <a href="/account" className="btn btn-primary" type="submit">Back to Account </a>
                </div>
              </div>
            </div>
          </div>

    </>
  )
}

const mapStateToProps = (state) => {
  // console.log('state', state)
  return {
    moduleLineList: state.moduleline.moduleLineList,
    moduleList: state.module.moduleList,
    price_ranges: state.pricing.pricingData.price_ranges,
    marketPlaceData: state.market_place,
    company: state.companyReducer.company,
  }
}

const mapDispatchToProps = dispatch =>{
  return {
    fetchPricings: ()=> dispatch(fetchPricings()),
	  fetchModules: () => dispatch(fetchModules()),
	  fetchModuleLines: () => dispatch(fetchModuleLines()),
    setCheckedState: (val) => dispatch(setCheckedState(val)),
    setPaymentCycle: (val) => dispatch(setPaymentCycle(val)),
    setNumberOfusers: (val) => dispatch(setNumberOfusers(val)),
    getCompanyById:(_id)=> dispatch(getCompanyById(_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Marketplace)