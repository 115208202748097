export {default as SignUp} from "./SignUp/SignUp"
export {default as SignIn} from "./SignIn/SignIn"
export {default as Dashboard} from "./Dashboard/Dashboard"
export {default as Profile} from "./Profile/Profile"
export {default as Settings} from "./Settings/Settings"
export {default as Account} from "./Account/Account"
export {default as CompanyDetails} from "./CompanyDetails/CompanyDetails"
export {default as Marketplace} from "./Marketplace/Marketplace"
export {default as Cart} from "./Cart/Cart"
export {default as Checkout} from "./Checkout/Checkout"
export {default as SuccessCheckout} from "./SuccessCheckout/SuccessCheckout"
export {default as PaymentHistory} from "./PaymentHistory/PaymentHistory"
export {default as Modules} from "./Modules/Modules"
export {default as ModuleDetails} from './ModuleDetails/ModuleDetails'
export {default as Pricing} from './Pricing/Pricing'
export {default as UserAdmin} from './UserAdmin/UserAdmin'
export {default as UserRoleAdmin} from './UserRoleAdmin/UserRoleAdmin'
export {default as UserRoleAdminDetails} from './UserRoleAdminDetails/UserRoleAdminDetails'
export {default as SubscribedUser} from './SubscribedUser/SubscribedUser'
export {default as SubscribedUserCompany} from './SubscribedUserCompany/SubscribedUserCompany'
export {default as SubscribedUserCompanyModule} from './SubscribedUserCompanyModule/SubscribedUserCompanyModule'
export {default as ForgotPassword} from './ForgotPassword/ForgotPassword'
export {default as ConfirmMail} from './ConfirmMail/ConfirmMail'
export {default as ResetForgotPassword} from './ResetForgotPassword/ResetForgotPassword'
export {default as UserProduct} from './UserProduct/UserProduct'
export {default as UserProductDetails} from './UserProductDetails/UserProductDetails'