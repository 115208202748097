import { combineReducers } from 'redux';

import auth from './auth';
import userProductReducers from './userProductReducer';
import moduleReducer from './module';
import moduleLineReducer from './moduleline';
import pricingReducer from './pricingReducers'
import companyReducer from './companyReducer'
import marketPlaceReducer from './marketPlaceReducer';
import paymentHistoryReducer from './paymentHistoryReducer';
import pricingCurdReducer from './pricingCrudReducer';
import userReducers from './userReducer';
import roleReducers from './roleReducers';
import permissionReducers from './permissionReducers'
import erpBackendReducer from './ErpSaaSBackendReducer';

export const reducers = combineReducers({erpBackend:erpBackendReducer,permission:permissionReducers, role: roleReducers, user: userReducers, pricing_crud:pricingCurdReducer, payment_history:paymentHistoryReducer,market_place:marketPlaceReducer,companyReducer,pricing:pricingReducer, auth, module:moduleReducer, moduleline: moduleLineReducer, user_product:userProductReducers });
