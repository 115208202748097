import { fetchUsersApi, handleResetPasswordApi } from "../../api";
import { CREATE_USER_CRUD, DELETE_USER_CRUD, EDIT_CONNECTED_USER, FETCH_USER_CRUD, RESET_PASSWORD, UPDATE_USER_CRUD } from "../constants/actionTypes";

export const fetchUsers = (formData, router) => async (dispatch) => {

    try {
      const { data } = await fetchUsersApi();
  
      dispatch({ type: FETCH_USER_CRUD, payload: data });
      return data
    } catch (error) {
      console.log('In fetchUsers',error);
    }
};

export const editConnectedUser = connected_user => {
  return {
    type: EDIT_CONNECTED_USER,
    payload: connected_user,
  };
};
  
// createUserSuccess
export const createUserSuccess = user => {
  return {
    type: CREATE_USER_CRUD,
    payload: user,
  };
};
// updateUserSuccess
export const updateUserSuccess = user_id => {
  return {
    type: UPDATE_USER_CRUD,
    payload: user_id,
  };
};

// deleteUserCrudSuccess
export const deleteUserCrudSuccess = user_id => {
  return {
    type: DELETE_USER_CRUD,
    payload: user_id,
  };
};

export const handleResetPassword = (user_id, connected_user, setMessage) => async (dispatch) => {
  try {
    const { data } = await handleResetPasswordApi(user_id, connected_user);

    setMessage({code: 201, message: "User Password reset "})

    setTimeout(() => {
      // Most recent value
      setMessage(false);                  
  }, 3000);

    // console.log('data', data)
    dispatch({ type: RESET_PASSWORD, payload: data.user });
    return data
  } catch (error) {
    setMessage({code: 503, message: error})
    
    setTimeout(() => {
      // Most recent value
      setMessage(false);                  
  }, 3000);

    console.log('In handleResetPassword error::>>',error);
  } 
  
};
