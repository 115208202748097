import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {Modal,} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { env_config, API, BACKEND_DOMAIN } from '../../env'
import { getPermissionByRoleId } from '../../redux/actions/permissionActions'
import moment from 'moment'
import { ButtonLoader } from '../../components'

const UserProduct = (props) => {
  const [message, setMessage] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [show, setShow] = useState(false);
  const [unconfirmedClients, setUnconfirmedClients] = useState([])
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false)
  const [tmpUserProduct, setTmpUserProduct] = useState({})
  const user_product_source = axios.CancelToken.source()
  useEffect(() => {
    (async ()=>{
      console.log('In SubscribedUser:>>',props)
      let _user = JSON.parse(localStorage.getItem('profile'));
      await props.getPermissionByRoleId(_user.result.role_id);
    })()
    axios.get(`${BACKEND_DOMAIN}/user_product/list`, {cancelToken: user_product_source.token})
        .then(response => {
          console.log('-- unconfirmedClients', response.data)
          setUnconfirmedClients(response.data);
        })
        .catch(error => {
            console.log('--- unconfirmedClients error :>>',error);
          })
        return () => {
          user_product_source.cancel()
        }
  }, [])
  
  const handleEdit = (module) =>{
    console.log('-- handleEdit module', module)
    localStorage.removeItem('module_item')
    // return
    localStorage.setItem('module_item', JSON.stringify(module));
    setIsUpdate(true)
    handleShow()
    // document.querySelector('#table-modules-replace-element').click();
  }
  const handleAdd = ()=>{
    setIsUpdate(false);
    handleShow()
    localStorage.removeItem('module_item');
    // document.querySelector('#table-modules-replace-element')?.click();
  }
  const handleDelete = async() => {
      console.log('tmpUserProduct', tmpUserProduct);
      setLoading(true)
    axios.delete(`${BACKEND_DOMAIN}/pricing/delete_user_product/${tmpUserProduct.token}`)
    .then(response => {
      setMessage({code:200, message: `Prospect data removed`})
    })
    .catch(error => {
        console.log('--- handleDelete error :>>',error);
      }).finally(()=>{
        setLoading(false)
      })
    }
  return (
    <>
        {message && message.code ? 
            message.code.toString().substr(0,2)  === "20" ? (   
            <div className="alert alert-success" role="alert">
                {message.message}
            </div>
            ) : (
                <div className="alert alert-danger" role="alert">
                {JSON.stringify(message?.message?.message)}
                </div>
            )          
        :null}

        <div className="d-flex mb-4 mt-6"><span className="fa-stack me-2 ms-n1"><i className="fa-inverse fa-stack-1x text-primary fas fa-list" data-fa-transform="shrink-2"></i></span>
          <div className="col">
            <h5 className="mb-0 text-primary position-relative"><span className="bg-200 dark__bg-1100 pe-3">Unconfirmed Client List</span><span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-index--1"></span></h5>
            <p className="mb-0">List all user that are register but having finalized their invoice.</p>
          </div>
        </div>
    {/* table start */}
    
    <div className="card z-index-1 mb-3" id="recentPurchaseTable" data-list='{"valueNames":["company","email","first_name","last_name","phoneNumber"],"page":8,"pagination":true}'>
            <div className="card-header">
              <div className="row flex-between-center">
                <div className="col-6 col-sm-auto d-flex align-items-center pe-0">
                  <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">Unconfirmed Client </h5>
                </div>
                <div className="col-6 col-sm-auto ms-auto text-end ps-0">
                  <div className="d-none" id="table-users-actions">
                    <div className="d-flex"><select defaultValue="bulk" className="form-select form-select-sm" aria-label="Bulk actions">
                        <option value="bulk">Bulk actions</option>
                        <option value="Refund">Refund</option>
                        <option value="Delete">Delete</option>
                        <option value="Archive">Archive</option>
                      </select><button className="btn btn-falcon-default btn-sm ms-2" type="button">Apply</button></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body px-0 py-0">
              <div className="table-responsive scrollbar">
              {props.user_permissions?.user?.can_view ? (
              <table className="table table-sm fs--1 mb-0 overflow-hidden">
                  <thead className="bg-200 text-900">
                    <tr>
                      <th className="white-space-nowrap">
                        <div className="form-check mb-0 d-flex align-items-center"><input className="form-check-input" id="checkbox-bulk-users-select" type="checkbox" data-bulk-select='{"body":"table-purchase-body","actions":"table-users-actions","replacedElement":"table-users-replace-element"}' /></div>
                      </th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="first_name">Company</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="first_name">First Name</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="last_name">Last Name</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="email">Email</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="payment_cycle">Last Payment</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="payment_status">Payment Status</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="current_period_start">Start</th>
                      <th className="sort pe-1 align-middle white-space-nowrap" data-sort="current_period_end">End</th>
                      <th className="no-sort pe-1 align-middle data-table-row-action"></th>
                    </tr>
                  </thead>
                   <tbody className="list" id="table-purchase-body">        
                    {unconfirmedClients?.map((user, i)=>(                                           
                      <tr key={i} className="btn-reveal-trigger">
                        <td className="align-middle" style={{width: "28px"}}>
                          <div className="form-check mb-0"><input className="form-check-input" type="checkbox" id="recent-purchase-0" data-bulk-select-row="data-bulk-select-row" /></div>
                        </td>
                        <td className="white-space-nowrap first_name"><strong><Link to={`/user_product/${user.token}`}>{user.company}</Link></strong></td>
                        <td className="white-space-nowrap first_name"><strong>{user.first_name}</strong></td>
                        <td className="white-space-nowrap last_name"><strong>{user.last_name}</strong></td>
                        <td className="white-space-nowrap email"><strong>{user.email}</strong></td>
                        
                        <td className="white-space-nowrap payment_cycle"><strong>{user.pricing.paiement_cycle}</strong></td>

                        <td className="white-space-nowrap payment_status"><strong>{user.stripe?.subscription?.status || 'incomplete'}</strong></td>
                        <td className="white-space-nowrap current_period_start">{user.stripe?.subscription?.current_period_start && moment(Date(user.stripe?.subscription?.current_period_start)).format('DD-MM-YY, h:mm:ss a') || 'n/a'}</td>
                        <td className="white-space-nowrap current_period_end">{user.stripe?.subscription?.current_period_end && moment(Date(user.stripe?.subscription?.current_period_end)).format('DD-MM-YY, h:mm:ss a') || 'n/a'}</td>
                        <td className="white-space-nowrap text-end">
                          <div className="dropstart font-sans-serif position-static d-inline-block"><button className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal float-end" type="button" id="dropdown0" data-bs-toggle="dropdown" data-boundary="window" aria-haspopup="true" aria-expanded="false" data-bs-reference="parent"><span className="fas fa-ellipsis-h fs--1"></span></button>
                            <div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="dropdown0">
                              <a className="dropdown-item" href={`/user_product/${user.token}`}>View</a>
                            {/* <a className="dropdown-item" href="#update_role" onClick={()=>handleEditRole(user)}>Assign Role</a> */}
                              <div className="dropdown-divider"></div>
                              
                              {props.user_permissions?.user?.can_delete ? (
                              <a className="dropdown-item text-danger" href="#delete" onClick={()=>{setTmpUserProduct(user); return handleShow()}}>Delete</a>
                              ) : null}
                              
                            </div>
                          </div>
                        </td>
                      </tr>    
                    ))}          
                    {unconfirmedClients?.length > 0 ? null : <tr></tr>}
                  </tbody>
                </table>
                ):"You can't view this"}
              </div>
            </div>
            <div className="card-footer">
              <div className="row align-items-center">
                <div className="pagination d-none"></div>
                <div className="col">
                  <p className="mb-0 fs--1"><span className="d-none d-sm-inline-block me-2" data-list-info="data-list-info"> </span><span className="d-none d-sm-inline-block me-2">&mdash;  </span></p>
                </div>
                <div className="col-auto d-flex"><button className="btn btn-sm btn-primary" type="button" data-list-pagination="prev"><span>Previous</span></button><button className="btn btn-sm btn-primary px-4 ms-2" type="button" data-list-pagination="next"><span>Next</span></button></div>
              </div>
            </div>
          </div>
    {/* table end */}
        
    <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>{`Delete Prospects `} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p>Are you sure you want to delete this prospect ?</p>
          </Modal.Body>
            <div className="modal-footer">
              <button className="btn btn-secondary" id="close-delete-user-product-modal"  type="button" data-bs-dismiss="modal">Close</button>
              <button className="btn btn-danger" type="submit" onClick={handleDelete}>Delete <ButtonLoader show={loading}/></button>
            </div>
        </Modal>
    </>
  )
}


const mapStateToProps = (state) => {
  // console.log('state', state)
  return {
    userList: state.user.userList,
    roleList: state.role.roleList,
    user_permissions: state.permission.user_permissions
  }
}
const mapDispatchToProps = dispatch =>{
  return {
      getPermissionByRoleId:(role_id)=> dispatch(getPermissionByRoleId(role_id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProduct)