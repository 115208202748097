import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { BACKEND_DOMAIN } from '../../env'

const SelectCompanyForm = (props) => {
    const [companies, setCompanies] = useState([])
    const [message, setMessage] = useState('')
    const [selectedCompany, setSelectedCompany] = useState({})
    useEffect(() => {
        const user_id = JSON.parse(localStorage.getItem('profile'))?.result._id
        axios.get(`${BACKEND_DOMAIN}/company?user_id=${user_id}`)
            .then(res=>{
                const {data: companies} = res
                console.log('companies', companies)
                setCompanies(companies)
                setSelectedCompany(JSON.stringify(companies[0]))
            })
    }, [])
const handleOnChangeCompany = (e) =>{
    let index = e.target.value
    let _company = companies[index]
    setSelectedCompany(JSON.stringify(_company))
}
const handleSubmit=(e)=>{
    let tmp_company_id = selectedCompany && JSON.parse(selectedCompany)._id
    localStorage.setItem('company', selectedCompany)
    localStorage.setItem('company_id', tmp_company_id)
    

    document.querySelector('#close-select-company-modal').disabled = false
    document.querySelector('#close-select-company-modal').click();
    e.preventDefault()
    props.history.push('/marketplace')
}
  return (
         
    <form 
        id="select-company-form" 
        onSubmit={handleSubmit}
        method="post" action=""
        className="row g-3">
        <div className='row'>
            {message && message.code ? 
                message.code.toString().substr(0,2)  === "20" ? (   
                <div className="alert alert-success" role="alert">
                    {message.message}
                </div>
                ) : (
                    <div className="alert alert-danger" role="alert">
                    {JSON.stringify(message?.message?.message)}
                    </div>
                )          
            :null}
            <div className="my-1 col-12">
                <label className="col-form-label" htmlFor="company-name">Company:</label>
                <select name='company-name' id='company-name' defaultValue={0} onChange={handleOnChangeCompany} className="form-select mb-3" aria-label="Default select example">
                    {companies && companies.map((company, i)=>(
                        <option key={i} value={i}>{company.name}</option>
                    )                    
                )}
                </select>

            </div>
            
        </div>
        
  </form>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SelectCompanyForm)