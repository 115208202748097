import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useFormik } from "formik";
import * as Yup from "yup";
import { signin } from '../../redux/actions/auth';
import { useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { ClipLoader } from "react-spinners";
import { GOOGLE_RECAPTCHA_KEY } from '../../env';

const validate = values => {
    const errors = {};
    const passwordRegex = /(?=.*[0-9])/;
    
    if (!values.password) {
      errors.password = 'Required';
    } else if (values.password.length < 8) {
    errors.password = "*Password must be 8 characters long.";
    } else if (values.password.length > 15) {
    errors.password = 'Must be 15 characters or less';
    } else if (!values.password) {
    errors.password = "*Required";
    }  else if (!passwordRegex.test(values.password)) {
    errors.password = "*Invalid password. Must contain one number.";
    } 
  
    if (values.password && values.confirm_password ) {
        if (values.password !== values.confirm_password) {
            errors.confirm_password = "Password not matched";
        }
    }
    return errors;
  };

const SignIn = (props) => {
  let history = useHistory();
  const [message, setMessage] = useState(false)
  const [disableSubmitButton, setDisableSubmitButton] = useState(false)
  useEffect(() => {   
  
    return () => {
      setMessage(false)
      setDisableSubmitButton(false)
    }
  }, [])
  
  const formik = useFormik({
    initialValues: {
      password: "",
      email: "",
      recaptcha: "",
    },
    validate,
  //   password: Yup.password(),
  //   confirm_password: Yup.password(),
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address"),//.required("Required"),
      recaptcha: Yup.string().required(),
    }),
    onSubmit: async (values, { resetForm }) => {
      
      setDisableSubmitButton(true)
      console.log('-- values', values)
      // return
    // let body =
    props.signin({
      email: values.email,
      password:  values.password
     },history)
          .then((res) => {
            if (res && res.name === "Error" ) {
              setMessage({code: 404, message: "Please make sure your credential are ok"});   
              // console.log('res', JSON.parse(JSON.stringify(res)))
              return;
            } 

            resetForm({ values: "" });
            // history.push('/dashboard');
            
          window.location.href = '/dashboard'
          }).catch ((error)=> {
            console.error("-- error", error);
            setMessage({code: 404, message: error})
              
          }).finally(()=> {
            setTimeout(() => {
              // Most recent value
              setMessage(false);    
            }, 8000);setDisableSubmitButton(false)})
    },
  });

  return (
        <main className="main" id="top">
      <div className="container" data-layout="container">
          
        <div className="row flex-center min-vh-100 py-6">
          <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4"><a className="d-flex flex-center mb-4" href="#">
            
            <img className="me-2" src="/assets/img/equerre-logo.png" alt="" height={'100rem'} />
            {/* <span className="font-sans-serif fw-bolder fs-5 d-inline-block">falcon</span> */}
          </a>
            <div className="card">
              <div className="card-body p-4 p-sm-5">
                  
                {message && message.code ? 
                    message.code  === "200" ? (
                    <div className="alert alert-success mb-5" role="alert">
                        {message.message}
                    </div>
                    ) : (
                        <div className="alert alert-danger mb-5" role="alert">
                        {JSON.stringify(message?.message?.message || message?.message)}
                        </div>
                    )          
                :null}

                <div className="row flex-between-center mb-2">
                  <div className="col-auto">
                    <h5>Log in</h5>
                  </div>
                  {/* <div className="col-auto fs--1 text-600"><span className="mb-0 undefined">or</span> <span><a href="../../../pages/authentication/simple/register.html">Create an account</a></span></div> */}
                </div>
                <form 
                  onSubmit={formik.handleSubmit}
                    id="signin-form"
                  className="form" method="post" action="">

                  <div className="mb-3">
                      
                        <input	
                            className="form-control"
                            value={formik.values.email}                          									
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email address"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div style={{ color: "#dc3545" }}>{formik.errors.email}</div>
                            ) : null}

                    </div>
                  <div className="mb-3">
                      
                        <input
                            className="form-control"												
                            id="Password"
                            name="password"
                            type="password"
                            placeholder="Password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            // validate={validatePassword}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div style={{ color: "#0096DE" }}>{formik.errors.password}</div>
                        ) : null}
                    </div>
                    
									<div className="mb-3">
                    <div className="form-group">
                      <label>Recaptcha Validation</label>
                      <ReCAPTCHA
                      sitekey={GOOGLE_RECAPTCHA_KEY}
                      render="explicit"
                      onChange={(response) => {formik.setFieldValue("recaptcha", response); }}
                      onExpired={() => { formik.setFieldValue("recaptcha", ""); }}
                      />
                      
                      {formik.touched.recaptcha && formik.errors.recaptcha ? (
                        <div style={{ color: "#dc3545" }}>{formik.errors.recaptcha}</div>
                      ) : null}
                    </div>                                       
									</div>

                  <div className="row flex-between-center">
                    <div className="col-auto">
                      <div className="form-check mb-0"><input className="form-check-input" type="checkbox" id="basic-checkbox" defaultChecked={"checked"} /><label className="form-check-label mb-0" htmlFor="basic-checkbox">Remember me</label></div>
                    </div>
                    <div className="col-auto"><a className="fs--1" href="/forgot-password">Forgot Password?</a></div>
                  </div>
                  <div className="mb-3">
                  <button disabled={disableSubmitButton} className="btn btn-primary d-block w-100 mt-3" type="submit" name="submit">
                      
													<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
													  <div style={{marginRight: '.4em'}}>Envoyer</div> <ClipLoader color={"#fff"} loading={disableSubmitButton} size={20} />
													</div>

                  </button></div>
                </form>
                {/* <div className="position-relative mt-4">
                  <hr className="bg-300" />
                  <div className="divider-content-center">or log in with</div>
                </div>
                <div className="row g-2 mt-2">
                  <div className="col-sm-6"><a className="btn btn-outline-google-plus btn-sm d-block w-100" href="#"><span className="fab fa-google-plus-g me-2" data-fa-transform="grow-8"></span> google</a></div>
                  <div className="col-sm-6"><a className="btn btn-outline-facebook btn-sm d-block w-100" href="#"><span className="fab fa-facebook-square me-2" data-fa-transform="grow-8"></span> facebook</a></div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch => {
    return {
        signin: (formData, history)=> dispatch(signin(formData, history)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)