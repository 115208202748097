import React, { useEffect, useRef } from 'react'
import { BACKEND_DOMAIN } from '../../env'

const Paypal =(props)=> {
    const paypal = useRef()
    useEffect(() => {
        window.paypal.Buttons({
            createOrder: function () {
              return fetch(`${BACKEND_DOMAIN}/paypal_payment_method/create-order`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  items: [
                    {
                      id: 1,
                      quantity: 2,
                    },
                    { id: 2, quantity: 3 },
                  ],
                }),
              })
                .then(res => {
                  if (res.ok) return res.json()
                  // logique to add a company or can combine company and history here
                  return res.json().then(json => Promise.reject(json))
                })
                .then(({ id }) => {
                    //logique to add history
                  return id
                })
                .catch(e => {
                  console.error(e.error)
                })
            },
            onApprove: function (data, actions) {
              return actions.order.capture()
            },
          }).render(paypal.current)
    }, [])
    
  return (
    <div>
        <div ref={paypal}></div>
    </div>
  )
}

export default Paypal;