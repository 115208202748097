import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom';


const PrivateRoute = ({ children, auth, path, ...rest }) => {
  const user = JSON.parse(localStorage.getItem('profile'));
  return (
    <Route
      // path={path}
      {...rest}
      render={() => {
        return user ? children : <Redirect to='/signin' />;
    }}
    />
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)