const computePackageTotalAmount = (new_total, paymentCycle, numberOfusers, pricing) => {
    let package_total = parseFloat(new_total) + parseFloat( pricing?.price *(paymentCycle === 'quarterly' ? 3 : paymentCycle === 'yearly' ? 12 : 1) * numberOfusers )
    return parseFloat(package_total).toFixed(2);
}
const computeModulePrice = (moduleline_price, paymentCycle,numberOfusers) => {
    return parseFloat((moduleline_price *(paymentCycle === 'quarterly' ? 3 : paymentCycle === 'yearly' ? 12 : 1)* numberOfusers).toFixed(2))
}
module.exports = {
    computePackageTotalAmount,
    computeModulePrice
}