import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import moment from 'moment'
import { deleteCompany, getCompanyById, unsubscribeSubmodule } from '../../redux/actions/companyAction'
import { ButtonLoader } from '../../components'
import { getStripeSubscriptionDetailsById } from '../../redux/actions/ErpSaaSBackendActions'

const CompanyDetails = (props) => {
const { company_id } = useParams()
const [company, setCompany] = useState({})
const [numberOfModules, setNumberOfModules] = useState(0)
const [message, setMessage] = useState({message: ''})
const [submoduleDetails, setSubmoduleDetails] = useState({})
const [loading, setLoading] = useState(false)
const [stripeSubscriptionDetailsData, setStripeSubscriptionDetailsData] = useState({})
    useEffect(() => {
        const fetchData = async ()=>{
          
        localStorage.setItem('company_id',company_id)
          let fetched_company = await props.getCompanyById(company_id)
          if (fetched_company) {
            let fetched_subscription_details = await props.getStripeSubscriptionDetailsById(fetched_company?.confirmData?.id, setLoading)
            return {fetched_company, fetched_subscription_details}
          }
          return {fetched_company}
        }
        fetchData().then( async res=>{
          setCompany(res.fetched_company)
          res.fetched_company?.modules && setNumberOfModules(Object.keys(res.fetched_company?.modules).length)
          res.fetched_subscription_details && setStripeSubscriptionDetailsData(res.fetched_subscription_details)
          // console.log('res', res)
        })
    }, [company_id])
const handleOnClickModal =(submodules)=>{
  // e.preventDefault()
  setSubmoduleDetails(submodules)
  console.log('first', )

}
// deleteCompany
const handleUnsubscribe = async(submodules) =>{
  submodules.company_id = company_id
  console.log('submodules', submodules)
  // return
  try {
      
    let _res = await props.unsubscribeSubmodule(submodules, setLoading);
    console.log('unsubscribeSubmodule_res', _res)
    let {data, status} = _res

    if (_res.status == 200) {
      setCompany(_res.updated_company)
      document.querySelector('#close-create-company-modal').click()
    } else {
      setMessage({code: status,message: data.message})
    }
    setTimeout(() => {
      setMessage({})  
      document.querySelector('#close-create-company-modal').click()
    }, 4000);

  } catch (error) {
    console.error('-- In handleUnsubscribe catch:',error)
    setMessage({code: 500,message: error.message})
    
    setTimeout(() => {
      setMessage({})  
      document.querySelector('#close-create-company-modal').click()
    }, 4000);
  } 


}
const HandleDeleteCompany = async() => {
  let company_id = localStorage.getItem('company_id');
  let _res = await props.deleteCompany(company_id, setLoading);
  
  console.log('HandleDeleteCompany', await _res.data)
  let {data, status} = _res

  if (_res.status == 200) {
    document.querySelector('#close-company-unsubscribe-modal').click()
  } else {
    if (!data) {
      
      setMessage({code: 403,message: 'An unexpected error occured. Please refresh the page'})
      return; 
    }
    setMessage({code: status,message: data?.message})
  }
  setTimeout(() => {
    setMessage({})  
    document.querySelector('#close-company-unsubscribe-modal').click()
    // props.history.push(`/account`)
    window.location.href = `/account`
  }, 4000);
}
  return (
    <>    
          <div className="card mb-3">
            <div className="bg-holder d-none d-lg-block bg-card" style={{backgroundImage:'url(/assets/img/icons/spot-illustrations/corner-4.png)',opacity: '0.7'}}></div>
            
            <div className="card-body position-relative">
              <div className='d-flex'>
                <div className=''>
                <a className='f--1' href='/account'><span className="fas fa-arrow-left me-2"></span></a>
                
                </div>
                {company?.createdAt && <div className=''>
                  
                  <h5>{company?.name}</h5>
                  <p className="fs--1">{stripeSubscriptionDetailsData?.subscription && moment(Date(stripeSubscriptionDetailsData.subscription.created)).format('MMMM Do YYYY, h:mm:ss a')}</p>
                </div>}
              </div>
              {company?.status && <div><strong className="me-2">Status: </strong>
                <div className={`badge rounded-pill badge-soft-${company?.status === 'pending' ? 'warning' : 'success'} fs--2`}>{stripeSubscriptionDetailsData?.subscription && stripeSubscriptionDetailsData?.subscription.status}<span className="fas fa-check ms-1" data-fa-transform="shrink-2"></span></div>
              </div>}
            </div>
          </div>

          <div className="card mb-3">
          <div className="card-header">
            <div className="row align-items-center">
              <div className="col">
                <h5 className="mb-0">Company</h5>
              </div>
              <div className="col-auto"><button data-bs-toggle="modal" data-bs-target="#company-unsubscribe-modal" className="btn btn-falcon-danger btn-sm" href="#!"><span className="fas fa-trash fs--2 me-1"></span>Unsubscribe</button></div>
            </div>
          </div>

            {stripeSubscriptionDetailsData.payment_method && company?.user && (<div className="card-body">
              <div className="row">
                <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <h5 className="mb-3 fs-0">{company?.user.last_name}</h5>
                  <h6 className="mb-2">{company?.user.last_name}</h6>
                  <p className="mb-1 fs--1">plan : {stripeSubscriptionDetailsData.subscription && `${stripeSubscriptionDetailsData.subscription.plan.amount/100} ${stripeSubscriptionDetailsData.subscription.currency} / ${stripeSubscriptionDetailsData.subscription.plan.interval === 'month' && stripeSubscriptionDetailsData.subscription.plan.interval >= 1 ? 'Quarterly': stripeSubscriptionDetailsData.subscription.plan.interval}`}</p>
                  <p className="mb-0 fs--1"> <strong>Email: </strong><a href={`mailto:${company?.email}`}>{company?.email}</a></p>
                  <p className="mb-0 fs--1"> <strong>Phone: </strong><a href="tel:7897987987">{company?.phoneNumber}</a></p>
                </div>
                <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
                  <h5 className="mb-3 fs-0">Current period</h5>
                  <h6 className="mb-0 fs--1 mb-2">Start : {moment(Date(stripeSubscriptionDetailsData.subscription.current_period_start)).format('MMMM Do YYYY, h:mm:ss a') }</h6>
                  <h6 className="mb-0 fs--1  mb-2">End : {moment(Date(stripeSubscriptionDetailsData.subscription.current_period_end)).format('MMMM Do YYYY, h:mm:ss a') }</h6>
                  
                </div>
                <div className="col-md-6 col-lg-4">
                  <h5 className="mb-3 fs-0">Payment Method</h5>
                  <div className="d-flex"><img className="me-3" src={`/assets/img/icons/${stripeSubscriptionDetailsData.payment_method.card.brand}.png`} width="40" height="30" alt="" />
                    <div className="flex-1">
                      <h6 className="mb-0">{company?.user.last_name}</h6>
                      <p className="mb-0 fs--1">**** **** **** {stripeSubscriptionDetailsData.payment_method && stripeSubscriptionDetailsData.payment_method.card.last4}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>)}
            
            <div className="card-footer d-flex justify-content-between align-items-center"><small>
              {company && company?.modules && Object.keys(company?.modules).reduce((prev, curr)=>{
                        let tmp = company?.modules[curr];
                        prev = prev + Object.keys(tmp).length;
                        return prev
                    },0)}
                    <span className="d-none d-sm-inline-block"> </span> ModuleLines</small>

            
              <div>
                <a href='/marketplace' className="btn btn-falcon-default btn-sm ms-1 ms-sm-2" type="button"><span className="fas fa-shopping-cart me-2"></span>
                  Market Place
                </a>
              </div>
            </div>          

          </div>
          
           <div className='row g-3 mb-3'>
           {/* style={{justifyContent: 'center', alignItems: 'cener'}}> */}
              
              {company?.modules && Object.keys(company?.modules).map((module_key)=>{
                return Object.keys(company?.modules[module_key]).map((submodules_key, i)=>{
                  return (
                    company?.modules[module_key][submodules_key].is_active ? (
                      
                      <div data-bs-toggle="modal" data-bs-target="#submodules-details-modal" onClick={()=>handleOnClickModal(company?.modules[module_key][submodules_key])} key={i} className="col-sm-6 col-md-4">
                        <div className="btn card" style={{backgroundImage:'url(/assets/img/icons/spot-illustrations/corner-4.png)'}}>
                          {/* <!--/.bg-holder--> */}
                          <div className="card-body position-relative">
                            <h5>{company?.modules[module_key][submodules_key].name}</h5>
                            <p className="fs--1">{moment(Date(company?.modules[module_key][submodules_key].createdAt)).format('MMMM Do YYYY, h:mm:ss a')}</p>
                              {/* <div><strong className="me-2">Status: </strong>
                                
                              </div> */}
                          </div>
                        </div>
                      </div>

                    ) : null
                  )
                })
              })}
          </div>

          {submoduleDetails && 
          <>
          <div className="modal fade" id="submodules-details-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth: '500px'}}>
              <div className="modal-content position-relative">
                <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                  <button className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body p-0">
                  
                  {message && message.code ? 
                    message.code  == "200" ? (
                    <div className="alert alert-success mb-0 p-1" role="alert">
                      {message.message}
                    </div>
                    ) : (
                      <div className="alert alert-danger mb-0 p-1" role="alert">
                      {message.message}
                      </div>
                    )          
                  :null}
                  <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h4 className="mb-1" id="submodules-details-modal-label">{submoduleDetails.name}</h4>
                  </div>
                  <div className="p-4 pb-0">
                    
                  <table className="table table-borderless fs--1 mb-0">
                      <tbody>
                        <tr className="border-bottom">
                          <th className="ps-0 pt-0 p-1">{'Submodule Name :'}<div className="text-400 fs-xx-small fs--2">{''}</div>
                          </th>
                          <th className="pe-0 text-end pt-0">{submoduleDetails.name}</th>
                        </tr> 
                        <tr className="border-bottom">
                          <th className="ps-0 py-2 p-1">{'Last payment :'}<div className="text-400 fs-xx-small fs--2">{''}</div> 
                          </th>
                          <th className="pe-0 text-end pt-0">{company?.confirmData &&  moment(new Date(company?.confirmData.current_period_end*1000)).format("MMM Do YY")}</th>
                        </tr> 
                        <tr className="border-bottom">
                          <th className="ps-0 py-2 p-1">{'next paymeny :'}<div className="text-400 fs-xx-small fs--2">{''}</div>
                          </th>
                          <th className="pe-0 text-end pt-0">{company?.confirmData && moment(new Date(company?.confirmData.current_period_start*1000)).format("MMM Do YY")  }</th>
                        </tr> 
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-secondary" id="close-create-company-modal"  type="button" data-bs-dismiss="modal">Close</button>
                  <button className="btn btn-danger" disabled={loading} onClick={()=>handleUnsubscribe(submoduleDetails)}  form="login-form" type="submit">Unsubscribe<ButtonLoader show={loading}/></button>
                </div>
              </div>
            </div>
          </div>
          {/* // Compony unSubscribe */}
          <div className="modal fade" id="company-unsubscribe-modal" tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document" style={{maxWidth: '500px'}}>
              <div className="modal-content position-relative">
                <div className="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                  <button className="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body p-0">
                  
                  {message && message.code ? 
                    message.code  === "200" ? (
                    <div className="alert alert-success mb-1" role="alert">
                      {message.message}
                    </div>
                    ) : (
                      <div className="alert alert-danger mb-1" role="alert">
                      {message.message}
                      </div>
                    )          
                  :null}
                  <div className="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                    <h4 className="mb-1" id="submodules-details-modal-label">{company?.name}</h4>
                  </div>
                  <div className="p-4 pb-0">
                    
                  <table className="table table-borderless fs--1 mb-0">
                      <tbody>
                        <tr className="border-bottom">
                          <th className="ps-0 pb-2 p-1">
                            Are you sure you want to delete <strong>{company?.name}</strong> ? <br/><br/>
                            The company  will be removed, and you will no longer access your product.
                          </th>
                          {/* <th className="pe-0 text-end pt-0">{submoduleDetails.name}</th> */}
                        </tr> 
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-secondary" id="close-company-unsubscribe-modal"  type="button" data-bs-dismiss="modal">Close</button>
                  <button disabled={loading} className="btn btn-danger" onClick={()=>HandleDeleteCompany()}  form="login-form" type="submit">Unsubscribe<ButtonLoader show={loading}/></button>
                </div>
              </div>
            </div>
          </div>
          </>
          }
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = dispatch=>{
  return {    
    getCompanyById:(_id)=> dispatch(getCompanyById(_id)),
    unsubscribeSubmodule: (submodule,setLoading)=>dispatch(unsubscribeSubmodule(submodule,setLoading)),
    deleteCompany: (company_id, setLoading) => dispatch(deleteCompany(company_id, setLoading)),
    getStripeSubscriptionDetailsById: (subscription_id, setLoading) => dispatch(getStripeSubscriptionDetailsById(subscription_id, setLoading)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails)
