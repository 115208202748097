import https from 'https'
const  getBase64 =(url)=> {
    console.log('-- getBase64 url', url)
    // return new Promise((resolve, reject) => {
    //   const reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   reader.onload = () => resolve(reader.result);
    //   reader.onerror = error => reject(error);
    // });

    // return https.get(url, res => {
    // let data = '';
    // res.on('data', chunk => {
    //     data += chunk;
    // });
    // res.on('end', () => {
    //     data = JSON.parse(data);
    //     console.log(data);
    // })
    // }).on('error', err => {
    // console.log(err.message);
    // })

    return fetch(url)
  .then(res => res.blob())
  .then(blob => {
    const file = new File([blob], `${Date.now()}`,{ type: "image/png" })
    console.log('-- getBase64 file', file)
    return file
  })

  }

export default getBase64;
// module.exports = {
//     getBase64,
// }