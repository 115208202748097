import React from 'react'
import { useEffect } from 'react';
import { connect } from 'react-redux'
import { useHistory,useParams,useLocation  } from "react-router-dom";
const ConfirmMail = (props) => {
    const params = useParams()
    const history = useHistory()
    const search = useLocation().search;
    const email=new URLSearchParams(search).get("email");
    // http://localhost:3001/confirm-mail?email=gregory.goufan@gmail.com
    useEffect(() => {
        console.log('history', history)
        console.log('params', params)
      console.log('email', email)
    })
    
  return ( <>
    <main className="main" id="top">
  <div className="container" data-layout="container">
     <div className="row flex-center min-vh-100 py-6 text-center">
  <div className="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4"><a className="d-flex flex-center mb-4" href="#"><img className="me-2" src="/assets/img/equerre-logo.png" width={268} /><span className="font-sans-serif fw-bolder fs-5 d-inline-block"></span></a>
    
    <div className="card">
  <div className="card-body p-4 p-sm-5">
    <div className="text-center"><img className="d-block mx-auto mb-4" src="../../../assets/img/icons/spot-illustrations/16.png" alt="Email" width={100} />
      <h4 className="mb-2">Please check your email!</h4>
      <p>An email has been sent to <strong>{email || 'Oop !! '}</strong>. Please click on the included link to reset your password.
      </p><a className="btn btn-primary btn-sm mt-3" href="/signing"><svg className="svg-inline--fa fa-chevron-left fa-w-10 me-1" data-fa-transform="shrink-4 down-1" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg style={{transformOrigin: '0.3125em 0.5625em'}}><g transform="translate(160 256)"><g transform="translate(0, 32)  scale(0.75, 0.75)  rotate(0 0 0)"><path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z" transform="translate(-160 -256)" /></g></g></svg>{/* <span class="fas fa-chevron-left me-1" data-fa-transform="shrink-4 down-1"></span> Font Awesome fontawesome.com */}Return to login</a>
    </div>
  </div>
</div>


  </div>
</div>
      </div>
    </main>
  
    </>
  )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmMail)